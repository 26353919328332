import Vue from "vue";

import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";
import { helpers } from "vuelidate/lib/validators";
import $ from "jquery";

import flatPickr from "flatpickr";
//import rangePlugin from "flatpickr/dist/plugins/rangePlugin";

import { numberValidation, numberFormatting } from "../common/mobileNumberMethods";
import vSelect from "vue-select";
// import "vue-select/dist/vue-select.css";
Vue.component("v-select", vSelect);
import moment from "vue-moment";
Vue.use(moment);
Vue.moment.suppressDeprecationWarnings = true;
import httpClient from "../services/http-client";
import environment from "../services/environment";
import { getQueryStringValue, getUser } from "../common/util";
import { Cropper } from "vue-advanced-cropper";
import { BIconExclamationSquareFill, BIconTelephoneInbound } from "bootstrap-vue";
// import "vue-advanced-cropper/dist/style.css";
Vue.use(Cropper);

const currentTime = new Date().getTime();
const isValidUrl = url => {
    const urlRegex = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g;
    if (urlRegex.test(url)) {
        return true;
    } else {
        return false;
    }
};
const emailDomainValidator = helpers.regex("emailDomainValidator", /^[A-Za-z0-9._%+-]+@us.aflac.com$/);
const elementId = "site-management";
let vm = "";
let retryPdfUPloadDom = "";
let retryPdfUPloadDomSPN = "";
let retryPdfUPloadDomPLAD = "";
const initializeCustomPdf = function() {
    document.body.onfocus = handleCustomPdf;
};

const initializeCustomPdfSPN = function() {
    document.body.onfocus = handleCustomPdfSpanish;
};
const initializeCustomPdfPLAD = function() {
    document.body.onfocus = handleCustomPdfPLAD;
};

const handleCustomPdf = function() {
    if (retryPdfUPloadDom.value.length) {
        return;
    } else {
        setTimeout(() => {
            if (
                vm.selectedProductSeriesDetail.productSeries.selected &&
                !vm.selectedProductSeriesDetail.productSeries.showCustomBrochureSection
            ) {
                vm.onProductModalCancel("pdf");
            }
        }, 200);
    }
    document.body.onfocus = null;
};

const handleCustomPdfSpanish = function() {
    if (retryPdfUPloadDomSPN.value.length) {
        return;
    } else if (retryPdfUPloadDomSPN.value.length == 0) {
        setTimeout(() => {
            if (
                vm.selectedProductSeriesDetail.productSeriesSpanish.selected &&
                !vm.selectedProductSeriesDetail.productSeriesSpanish.showCustomBrochureSection
            ) {
                vm.removeSpnBrochurePdf(vm.selectedProductSeriesDetail.lineOfBusiness.ItemId);
            }
        }, 200);
    }
    document.body.onfocus = null;
};

const handleCustomPdfPLAD = function() {
    if (retryPdfUPloadDomPLAD.value.length) {
        return;
    } else if (retryPdfUPloadDomPLAD.value.length == 0) {
        setTimeout(() => {
            if (vm.pladLobSelectedCurrent.lob.selected && !vm.pladLobSelectedCurrent.browsePdf) {
                vm.removePladPdf(vm.pladLobSelectedCurrent.lob.Id);
            }
        }, 200);
    }
    // document.body.onfocus = null;
};

const getBase64FromUrl = async url => {
    try {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
            };
            reader.onerror = error => reject(error);
        });
    } catch (e) {
        throw "File or url does not exists";
    }
};

let invalidPhoneNumbersList = [];
const phoneNumberValidatorDynamic = (value, vm) => {
    if (value) {
        const noSpecialCharactersValue = value.replace(/[^a-zA-Z0-9]/g, "");
        if (noSpecialCharactersValue.charAt(0) == "0") {
            return false;
        }
        if (invalidPhoneNumbersList) {
            if (invalidPhoneNumbersList.includes(noSpecialCharactersValue)) {
                return false;
            } else {
                return true;
            }
        }
    } else {
        return true;
    }
};

export const vueElement = {
    el: `#${elementId}`,
    mixins: [validationMixin],
    data: () => ({
        // ------- Custom Spanish Brochure ---------
        cutomSpnPdfSelected: [],
        cutomSpnPdfSelectedBase64: [],
        cutomSpnPdfPdfSize: "500",
        cutomSpnPdfPdfTitle: "",
        cutomIndvSpnPdfLobSelected: [],
        cutomIndvSpnPdfLobSelectedBase64: [],
        cutomGrpSpnPdfLobSelected: [],
        cutomGrpSpnPdfLobSelectedBase64: [],
        siteCreated: false,
        removeCustomSpanishPdtBroPopup: false,
        //----------PLADS-------------
        showPladProductResetPopup: false,
        pladLobData: {},
        pladLobSelectedList: [],
        pladLobSelectedCurrent: {
            browsePdf: false,
            lob: {},
            isPdfUpload: false,
            showSuccessMsg: false,
            showError: false,
            showSizeError: false,
            removePdfPopup: false,
            pdfDetails: {}
        },
        pladPdfTitle: "PLAD: Product",
        pladPdfSize: "500",
        removePladPdfPopup: false,
        currentPladLobIdToRemove: "",
        isLoaderActive: false,
        // ------- Custom Brochure for Individual ---------
        cutomIndvPDfLobSelected: [],
        cutomIndvPDfLobSelectedBase64: [],
        customIndvPdfSize: "500",
        customIndvPdfTitle: "",
        // ------non-aflac user variable----
        aflacRSPdfSize: "500",
        aflacRSPDfSelectedObj: {},
        aflacRSPDfSelectedObjBase64: {},
        aflacRSPdfTitle: "Aflac Benefits: Rate Sheet",
        isAflacRSDisclaimerFirstOnly: false,
        aflacRSPdfToRemovedLobId: "",
        removeAflacRSPdfPopup: false,
        nonAflacPdfLastRetrydData: {},
        nonAflacPdfToRemovedData: {},
        showRemoveNonAflacPdfPopup: false,
        nonAflacProductPdfTitle: "Non Aflac Benefits: Product",
        nonAflacRSPdfTitle: "Non Aflac Benefits: Rate Sheet",
        isPopupFirstOnly: false,
        nonAflacPdfSize: "500",
        nonAflacLob: {},
        nonAflacRSPDfSelectedObj: {},
        nonAflacDisclaimer: false,
        nonAflacLobSelectedList: [],
        nonAflacLobSelectedCurrent: {
            lob: {},
            isRSPdfUpload: false,
            showNonAflacRSSuccessMsg: false,
            showNonAflacRSSizeError: false,
            isPdfUpload: false,
            showNonAflacLobSuccessMsg: false,
            showNonAflacError: false,
            showNonAflacLobSizeError: false,
            removeNonAflacLobPdfPopup: false,
            nonAflacPDFUPloadModalpopup: false,
            listPdfBase64: [],
            rateSheetDetails: {}
        },
        isAnyLobProductSelected: false,
        isUploadRS: false,
        showRateSheetPDFUPloadModalpopup: false, //  used to show Aflac user rate sheet disclamer
        showRateSheetSuccessMsg: false, //  used to show success message after upload rate shet for aflac user
        showRateSheetSizeError: false, //  used to show different-different error message after upload rate sheet for aflac user
        removeRateSheetPdfBropopup: false, // use to show remove modal confirmation
        nonAflacPDFUPloadModalpopup: false,
        // ------non-aflac user variable----
        micrositeIdQRcode: "",
        isProductSelected: "",
        customPdfSize: "500",
        customPdfTitle: "",
        cutomPDfLobSelected: [],
        cutomPDfLobSelectedBase64: [],
        currentLobIdToRemove: "",
        isCurrentPdfSelfRemove: false,
        isPilotMode: false,
        currentProductSelection: "",
        isReadyApiData: false,
        isAlreadyDsiplay: false,
        showClientLogoWrapper: false,
        isClientLogoPreviewErr: false,
        productResetPopupData: {
            oldOrganizationState: "",
            oldGroupState: "",
            newOrganizationState: "",
            newGroupState: "",
            resetOrganizationState: false,
            resetGroupState: false
        },
        isLogoUpdate: false,
        clientLogoSize: "500",
        clientLogoExtension: "",
        isClientLogoPreview: false,
        isRemoveClientLogoModalShow: false,
        clientLogoImageOld: "",
        clientLogoImage: "",
        clientLogoImageExt: "",
        clientLogoImageName: "",
        isClientLogoInvalid: false,
        clientLogoEditMode: false,
        // ---pdf upload functionality variable
        showCustomBrochureSection: false,
        showPDFUPloadModalpopup: false,
        showPDFUPloadModalpopupSpanish: false,
        CustomPdfUPloadModal: false,
        removeCustomGroupPdtBropopup: false,
        isDisclaimerChecked: true,
        customBrochurePdf: "",
        customBrochurePdfName: "",
        customBrochurePdfExt: "",
        CustomPdfUPloadModal: false,
        removeCGPdfBrowserPopup: false,

        removePDFConfirmPopup: false,
        ActivePdfUPload: false,

        // ---pdf upload functionality variable

        //  --Aflac Group brochure variable
        AflacSupplementalchk: false,
        AflacGroupchk: false,
        //  --Aflac Group brochure variable
        isLogoRemoved: false,
        ClientImagePath: "",
        clientImageId: "",
        siteForm: {
            pladSelection: false,
            hideEnrolDate: null,
            organizationName: "",
            organizationState: null,
            groupState: null,
            networkState: null,
            contactName: "",
            contactEmail: "",
            contactNumber: "",
            enrollmentDateFrom: null,
            enrollmentDateTo: null,
            liveSite: false,
            liveDateFrom: null,
            liveDateTo: null,
            accountId: null,
            calenderLink: "",
            selectedEnrollment: "",
            offerAflacGroup: false,
            micrositeUrl: "",
            IsSuggestiveEnable: false,
            showLogoOnEmployeePreviewPage: false,
            individualSelection: false,
            groupSelection: false,
            networkSelection: false,
            calenderLink: "",
            schedulingPhoneNo: "",
            schedulingEnrollUrl: "",
            hideAgent: false,
            disclaimer: false,
            customPdf: "",
            customPdfPath: "",
            CustomIndividualPdf: "",
            CustomIndividualPdfPath: "",
            CustomIndividualSpanishPdfPath: "",
            CustomSpanishPdfPath: ""
        },
        userSaved: false,
        sending: false,
        lastUser: null,
        date: null,
        timeRange: "",
        stateList: [],
        groupStateList: [],
        enrollmentVideos: [],
        enrollmnet_video: [],
        storeDisplayProducts: {
            state: "",
            lineofBusinesses: []
        },
        initDisplayProducts: {
            state: "",
            lineofBusinesses: []
        },
        individualProducts: {
            state: "",
            lineofBusinesses: []
        },
        groupProducts: {
            state: "",
            lineofBusinesses: []
        },
        displayProducts: {
            state: "",
            lineofBusinesses: []
        },
        displayProductsEdit: {
            lineofBusinesses: []
        },
        displayProductsOld: {
            state: "",
            lineofBusinesses: []
        },
        indProductsSelected: [],
        grpProductsSelected: [],
        showPreviewModal: false,
        //1affdf3f7ae64c0bba690e9d163c0325
        micrositeId: "",
        micrositeDetail: {},
        enrollmentDatePickerStart: null,
        enrollmentDatePickerEnd: null,
        liveDatePickerStart: null,
        liveDatePickerEnd: null,
        showPublishModal: false,
        submitted: false,
        initValue: {},
        showUnSavedForm: false,
        isLiveDatesInValid: false,
        isenrollmentDateInValid: false,
        loggedInUserDetail: {},
        showProductModal: false,
        selectedProductSeriesDetail: {
            lineOfBusiness: {},
            ProductSeries: {},
            ProductSeriesSpanish: {}
        },
        listOfGroupProductLevelAndRiderIds: [],

        type: {
            AFLAC_GROUP: "AflacGroup",
            AFLAC_SUPPLEMENTAL: "AflacSupplemental",
            GROUP_SELECTED: "",
            NONAFLAC_PRODUCT: "NonAflacProduct",
            NONAFLAC_RS: "NonAflacRS",
            AFLAC_RS: "AflacRS",
            INDIVIDUAL_SERIES: "productSeries",
            GROUP_SERIES: "productSeriesG",
            AFLAC_LEVEL: "productLevels",
            AFLAC_RIDER: "riders",
            PLAD_PRODUCT: "PLADS",
            GROUP_SERIES_SPANISH: "productSeriesSpanishG",
            INDIVIDUAL_SERIES_SPANISH: "productSeriesSpanish",
            GROUP_SPANISH_PRODUCT: "GroupSpanishProduct",
            INDIVIDUAL_SPANISH_PRODUCT: "SpanishProduct",
            GROUP_SPANISH_SELECTED: ""
        },
        initProductSeries: {},
        initIndividualProducts: {
            state: "",
            lineofBusinesses: []
        },
        initGroupProducts: {
            state: "",
            lineofBusinesses: []
        },
        showProductResetPopup: false,
        productResetPopupData: {
            oldState: "",
            newState: "",
            resetState: false
        },
        checkDirty: "pristine",
        accountIdValidationMsg: "",
        previewConfirmationPopup: false,
        oneDisclaimerShow: false,
        disclaimerCloseButton: false,
        saveAndPublishButton: false,
        invalidPhoneNumbers: []
    }),
    created: function() {
        this.clientLogoImage = "";
        this.clientLogoImageOld = "";
        /*this.$watch("siteForm", this.updateDate, {
            deep: true
        });*/
        // window.addEventListener("beforeunload", this.unload);
    },
    watch: {
        empStateChanged(newVal, oldVal) {
            this.currentProductSelection = this.type.AFLAC_SUPPLEMENTAL;
            if (this.productResetPopupData.resetOrganizationState && !this.micrositeId) {
                this.productResetPopupData.resetOrganizationState = false;
                return;
            } else if (
                this.productResetPopupData.resetOrganizationState &&
                this.micrositeId &&
                this.micrositeDetail.OrganizationState
            ) {
                this.productResetPopupData.resetOrganizationState = false;
                return;
            }

            if (this.micrositeId && this.micrositeDetail.OrganizationState) {
                this.showProductResetPopup = true;
                this.productResetPopupData.oldOrganizationState = oldVal;
                this.productResetPopupData.newOrganizationState = newVal;
            } else {
                //if (JSON.stringify(this.initDisplayProducts) === JSON.stringify(this.displayProducts)) {
                if (!this.checkAnyLevelorRiderSelectedInProductPopup()) {
                    if (this.siteForm.organizationState) {
                        this.closeAllAcc();
                        this.getProduct(this.type.AFLAC_SUPPLEMENTAL).then(p1 => {
                            for (let i = 0; i < this.displayProducts.lineofBusinesses.length; i++) {
                                this.disableOtherLobProducts(
                                    this.type.AFLAC_GROUP,
                                    this.displayProducts,
                                    this.displayProducts.lineofBusinesses[i].ItemId
                                );
                                if (
                                    Object.prototype.hasOwnProperty.call(
                                        this.displayProducts.lineofBusinesses[i],
                                        this.type.GROUP_SERIES
                                    ) &&
                                    this.displayProducts.lineofBusinesses[i].productSeriesG.length > 0
                                ) {
                                    let PScount = 0;
                                    for (
                                        let j = 0;
                                        j < this.displayProducts.lineofBusinesses[i].productSeriesG.length;
                                        j++
                                    ) {
                                        if (
                                            this.displayProducts.lineofBusinesses[i].productSeriesG[j].selected == true
                                        ) {
                                            this.displayProducts.lineofBusinesses[i].showDot = true;
                                            PScount++;
                                        }
                                    }
                                    if (PScount > 0) {
                                        const lobIndex = this.getItemIndexByID(
                                            this.displayProductsOld.lineofBusinesses,
                                            this.displayProducts.lineofBusinesses[i].ItemId
                                        );
                                        if (lobIndex != -1) {
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].showAflacRSPdfSection = this.displayProductsOld.lineofBusinesses[
                                                lobIndex
                                            ].showAflacRSPdfSection;
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].disclaimer = this.displayProductsOld.lineofBusinesses[
                                                lobIndex
                                            ].disclaimer;
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].selected = this.displayProductsOld.lineofBusinesses[lobIndex].selected;
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].aflacRSPdfName = this.displayProductsOld.lineofBusinesses[
                                                lobIndex
                                            ].aflacRSPdfName;
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].aflacRSPdfBase64 = this.displayProductsOld.lineofBusinesses[
                                                lobIndex
                                            ].aflacRSPdfBase64;
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].aflacRSPdfPath = this.displayProductsOld.lineofBusinesses[
                                                lobIndex
                                            ].aflacRSPdfPath;
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].isCustomPdfPreviewErr = this.displayProductsOld.lineofBusinesses[
                                                lobIndex
                                            ].isCustomPdfPreviewErr;
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].isAflacRSPdfUpload = this.displayProductsOld.lineofBusinesses[
                                                lobIndex
                                            ].isAflacRSPdfUpload;
                                        }
                                    }
                                }
                            }
                        });
                    } else if (this.siteForm.groupState && !this.siteForm.organizationState) {
                        let forNotDeletion = this.initGroupProducts.lineofBusinesses.reduce(function(a, e, i) {
                            a.push(e.ItemId);
                            return a;
                        }, []);
                        let arrayTemp = JSON.parse(JSON.stringify(this.displayProducts.lineofBusinesses));
                        this.displayProducts.lineofBusinesses = arrayTemp.filter((item, index) =>
                            forNotDeletion.includes(item.ItemId)
                        );
                        for (let i = 0; i < this.displayProducts.lineofBusinesses.length; i++) {
                            if (
                                Object.prototype.hasOwnProperty.call(
                                    this.displayProducts.lineofBusinesses[i],
                                    this.type.GROUP_SERIES
                                ) &&
                                this.displayProducts.lineofBusinesses[i].productSeriesG.length > 0
                            ) {
                                this.displayProducts.lineofBusinesses[i][this.type.INDIVIDUAL_SERIES] = [];
                                let GPCount = 0;
                                for (
                                    let j = 0;
                                    j < this.displayProducts.lineofBusinesses[i].productSeriesG.length;
                                    j++
                                ) {
                                    this.displayProducts.lineofBusinesses[i].productSeriesG[j].disabled = false;
                                    if (this.displayProducts.lineofBusinesses[i].productSeriesG[j].selected == true) {
                                        GPCount++;
                                    }
                                }
                                if (GPCount > 0) {
                                    this.displayProducts.lineofBusinesses[i].showDot = true;
                                } else {
                                    this.displayProducts.lineofBusinesses[i].showDot = false;
                                }
                            }
                        }
                    } else if (!this.siteForm.groupState && !this.siteForm.organizationState) {
                        this.initDisplayProducts = this.displayProducts = {
                            state: "",
                            lineofBusinesses: []
                        };
                    }
                } else {
                    this.showProductResetPopup = true;
                    this.productResetPopupData.oldOrganizationState = oldVal;
                    this.productResetPopupData.newOrganizationState = newVal;
                    //this.displayProducts =  {};
                }
            }
        },
        stateChanged(newVal, oldVal) {
            this.currentProductSelection = this.type.AFLAC_GROUP;
            if (this.productResetPopupData.resetGroupState && !this.micrositeId) {
                this.productResetPopupData.resetGroupState = false;
                return;
            } else if (
                this.productResetPopupData.resetGroupState &&
                this.micrositeId &&
                this.micrositeDetail.GroupState
            ) {
                this.productResetPopupData.resetGroupState = false;
                return;
            }

            if (this.micrositeId && this.micrositeDetail.GroupState) {
                this.showProductResetPopup = true;
                this.productResetPopupData.oldGroupState = oldVal;
                this.productResetPopupData.newGroupState = newVal;
            } else {
                //if (JSON.stringify(this.initDisplayProducts) === JSON.stringify(this.displayProducts)) {
                if (!this.checkAnyLevelorRiderSelectedInGroupProductPopup()) {
                    if (this.siteForm.groupState) {
                        this.closeAllAcc();
                        this.getProduct(this.type.AFLAC_GROUP).then(p2 => {
                            for (let i = 0; i < this.displayProducts.lineofBusinesses.length; i++) {
                                this.disableOtherLobProducts(
                                    this.type.AFLAC_SUPPLEMENTAL,
                                    this.displayProducts,
                                    this.displayProducts.lineofBusinesses[i].ItemId
                                );
                                if (
                                    Object.prototype.hasOwnProperty.call(
                                        this.displayProducts.lineofBusinesses[i],
                                        this.type.INDIVIDUAL_SERIES
                                    ) &&
                                    this.displayProducts.lineofBusinesses[i].productSeries.length > 0
                                ) {
                                    let PScount = 0;
                                    for (
                                        let j = 0;
                                        j < this.displayProducts.lineofBusinesses[i].productSeries.length;
                                        j++
                                    ) {
                                        if (
                                            this.displayProducts.lineofBusinesses[i].productSeries[j].selected == true
                                        ) {
                                            this.displayProducts.lineofBusinesses[i].showDot = true;
                                            PScount++;
                                        }
                                    }

                                    if (PScount > 0) {
                                        const lobIndex = this.getItemIndexByID(
                                            this.displayProductsOld.lineofBusinesses,
                                            this.displayProducts.lineofBusinesses[i].ItemId
                                        );
                                        if (lobIndex != -1) {
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].showAflacRSPdfSection = this.displayProductsOld.lineofBusinesses[
                                                lobIndex
                                            ].showAflacRSPdfSection;
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].disclaimer = this.displayProductsOld.lineofBusinesses[
                                                lobIndex
                                            ].disclaimer;
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].selected = this.displayProductsOld.lineofBusinesses[lobIndex].selected;
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].aflacRSPdfName = this.displayProductsOld.lineofBusinesses[
                                                lobIndex
                                            ].aflacRSPdfName;
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].aflacRSPdfBase64 = this.displayProductsOld.lineofBusinesses[
                                                lobIndex
                                            ].aflacRSPdfBase64;
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].aflacRSPdfPath = this.displayProductsOld.lineofBusinesses[
                                                lobIndex
                                            ].aflacRSPdfPath;
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].isCustomPdfPreviewErr = this.displayProductsOld.lineofBusinesses[
                                                lobIndex
                                            ].isCustomPdfPreviewErr;
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].isAflacRSPdfUpload = this.displayProductsOld.lineofBusinesses[
                                                lobIndex
                                            ].isAflacRSPdfUpload;
                                        }
                                    }
                                }
                            }
                        });
                    } else if (this.siteForm.organizationState && !this.siteForm.groupState) {
                        let forNotDeletion = this.initIndividualProducts.lineofBusinesses.reduce(function(a, e, i) {
                            a.push(e.ItemId);
                            return a;
                        }, []);
                        let arrayTemp = JSON.parse(JSON.stringify(this.displayProducts.lineofBusinesses));
                        this.displayProducts.lineofBusinesses = arrayTemp.filter((item, index) =>
                            forNotDeletion.includes(item.ItemId)
                        );
                        for (let i = 0; i < this.displayProducts.lineofBusinesses.length; i++) {
                            // this.checkAndEnableProductSectionByLobId(this.displayProducts.lineofBusinesses[i].ItemId, this.type.AFLAC_GROUP);
                            if (
                                Object.prototype.hasOwnProperty.call(
                                    this.displayProducts.lineofBusinesses[i],
                                    this.type.INDIVIDUAL_SERIES
                                ) &&
                                this.displayProducts.lineofBusinesses[i].productSeries.length > 0
                            ) {
                                this.displayProducts.lineofBusinesses[i][this.type.GROUP_SERIES] = [];
                                let PCount = 0;
                                for (
                                    let j = 0;
                                    j < this.displayProducts.lineofBusinesses[i].productSeries.length;
                                    j++
                                ) {
                                    this.displayProducts.lineofBusinesses[i].productSeries[j].disabled = false;
                                    if (this.displayProducts.lineofBusinesses[i].productSeries[j].selected == true) {
                                        PCount++;
                                    }
                                }
                                if (PCount > 0) {
                                    this.displayProducts.lineofBusinesses[i].showDot = true;
                                } else {
                                    this.displayProducts.lineofBusinesses[i].showDot = false;
                                }
                            }
                        }
                    } else if (!this.siteForm.groupState && !this.siteForm.organizationState) {
                        this.initDisplayProducts = this.displayProducts = {
                            state: "",
                            lineofBusinesses: []
                        };
                    }
                } else {
                    this.showProductResetPopup = true;
                    this.productResetPopupData.oldGroupState = oldVal;
                    this.productResetPopupData.newGroupState = newVal;
                }
            }
        },
        siteForm: {
            handler() {
                if (!this.siteForm.individualSelection && this.siteForm.organizationState) {
                    this.currentProductSelection = this.type.AFLAC_SUPPLEMENTAL;
                    this.siteForm.organizationState = "";
                }
                if (!this.siteForm.groupSelection && this.siteForm.groupState) {
                    this.siteForm.groupState = "";
                    this.currentProductSelection = this.type.AFLAC_GROUP;
                }
                if (this.dirty()) {
                    this.checkDirty = "dirty";
                } else {
                    this.checkDirty = "pristine";
                }
            },
            deep: true
        },
        displayProducts: {
            handler() {
                if (this.dirty()) {
                    this.checkDirty = "dirty";
                } else {
                    this.checkDirty = "pristine";
                }
            },
            deep: true
        },
        /*
        groupProducts: {
            handler() {
                if (this.dirty()) {
                    this.checkDirty = "dirty";
                } else {
                    this.checkDirty = "pristine";
                }
            },
            deep: true
        },
        individualProducts: {
            handler() {
                if (this.dirty()) {
                    this.checkDirty = "dirty";
                } else {
                    this.checkDirty = "pristine";
                }
            },
            deep: true
        },*/
        checkDirty: function(newVal) {
            this.onDirtyChanged(newVal);
        }
    },
    computed: {
        stateChanged() {
            return this.siteForm.groupState;
        },

        empStateChanged() {
            return this.siteForm.organizationState;
        }
    },
    validations: {
        siteForm: {
            organizationName: {
                required,
                minLength: minLength(3)
            },
            contactName: {
                required
            },
            contactEmail: {
                required,
                email
            },
            contactNumber: {
                required,
                numberValidation,
                phoneNumberValidatorDynamic
            },
            enrollmentDateTo: {
                required
            },
            enrollmentDateFrom: {
                required
            },
            liveDateFrom: {},
            liveDateTo: {},
            accountId: {
                required
            },
            calenderLink: {},
            selectedEnrollment: {},
            isProductSelected: {
                required: function() {
                    if (!this.siteForm.isProductSelected) {
                        return (
                            (this.siteForm.individualSelection ||
                                this.siteForm.groupSelection ||
                                this.siteForm.pladSelection) &&
                            this.getCountLobSelected()
                        );
                    } else {
                        return true;
                    }
                }
            },
            schedulingPhoneNo: {
                numberValidation,
                phoneNumberValidatorDynamic
            }
        }
    },

    methods: {
        // ConfirmCustomPDFUpload(lob, productSeries, type, groupBrochureType){
        //     this.showPDFUPloadModalpopupSp = true;
        // },

        viewBase64AsUrl(base64, title) {
            let base64EncodedPDF = base64; // the encoded string
            let decodedPdfContent = atob(base64EncodedPDF);
            let byteArray = new Uint8Array(decodedPdfContent.length);
            for (let i = 0; i < decodedPdfContent.length; i++) {
                byteArray[i] = decodedPdfContent.charCodeAt(i);
            }
            let blob = new Blob([byteArray.buffer], { type: "application/pdf" });
            let _pdfurl = URL.createObjectURL(blob);
            var prntWin = window.open("about:blank");
            prntWin.document.write(
                "<html><head><title>" +
                    title +
                    "</title></head><body>" +
                    '<embed width="100%" height="100%" name="plugin" src="' +
                    _pdfurl +
                    '" ' +
                    'type="application/pdf" internalinstanceid="21"></body></html>'
            );
            prntWin.document.close();
        },
        getSelectedCustomSpanishPdfDetails(products, type) {
            let productSeriesType = "";
            let cutomPdfLobSelectedBase64 = [];
            if (type == this.type.AFLAC_SUPPLEMENTAL) {
                productSeriesType = this.type.INDIVIDUAL_SERIES_SPANISH;
                cutomPdfLobSelectedBase64 = this.cutomIndvSpnPdfLobSelectedBase64;
            } else {
                productSeriesType = this.type.GROUP_SERIES_SPANISH;
                cutomPdfLobSelectedBase64 = this.cutomGrpSpnPdfLobSelectedBase64;
            }

            let customPdfData = "";
            const formatType = "Base64";
            if (Array.isArray(products.lineofBusinesses) && products.lineofBusinesses.length > 0) {
                products.lineofBusinesses.forEach(lineofBusiness => {
                    if (
                        Array.isArray(lineofBusiness[productSeriesType]) &&
                        lineofBusiness[productSeriesType].length > 0
                    ) {
                        let totalSeriesItems = lineofBusiness[productSeriesType].length;
                        let customPdfUpload = lineofBusiness[productSeriesType][totalSeriesItems - 1];
                        if (
                            customPdfUpload &&
                            customPdfUpload.customBrochurePdfBase64 &&
                            customPdfUpload.isCustomPdfUpload
                        ) {
                            let pdfName = customPdfUpload.customBrochurePdfName.split(".")[0];
                            customPdfData +=
                                lineofBusiness.LOBShortName +
                                ":" +
                                customPdfUpload.customBrochurePdfBase64 +
                                ":" +
                                pdfName +
                                "|";
                        } else if (
                            customPdfUpload &&
                            !customPdfUpload.customBrochurePdfBase64 &&
                            customPdfUpload.isCustomPdfUpload
                        ) {
                            customPdfData +=
                                lineofBusiness.LOBShortName +
                                ":" +
                                cutomPdfLobSelectedBase64[lineofBusiness.LOBShortName]?.base64 +
                                ":" +
                                cutomPdfLobSelectedBase64[lineofBusiness.LOBShortName]?.name +
                                "|";
                        }
                    }
                });
            }

            if (customPdfData != "") {
                customPdfData = customPdfData.slice(0, -1);
            }
            return customPdfData;
        },
        getSelectedLobSeries(lineofBusinesses, type) {
            if (Array.isArray(lineofBusinesses) && lineofBusinesses.length - 1 > 0) {
                for (let i; i < lineofBusinesses.length; i++) {
                    let productSeriesSelected =
                        type == this.type.AFLAC_SUPPLEMENTAL
                            ? lineofBusinesses[i].productSeries
                            : lineofBusinesses[i].productSeriesG;

                    if (Array.isArray(productSeriesSelected) && productSeriesSelected.length > 0) {
                        let psCount = 0;
                        productSeriesSelected.forEach(item => {
                            if (item.selected) {
                                psCount++;
                            }
                        });

                        return psCount;
                    }
                }
            }
        },
        viewSpanishPdfFile(lobId, type = this.type.AFLAC_SUPPLEMENTAL, title) {
            const lobIndex = this.getItemIndexByID(this.displayProducts.lineofBusinesses, lobId);
            let productSeriesSelected =
                type == this.type.AFLAC_SUPPLEMENTAL
                    ? this.displayProducts.lineofBusinesses[lobIndex].productSeriesSpanish
                    : this.displayProducts.lineofBusinesses[lobIndex].productSeriesSpanishG;
            let customBrochurePdfBase64 =
                productSeriesSelected[productSeriesSelected.length - 1].customBrochurePdfBase64;
            /*let customBrochurePdfBase64 =
                "data:application/pdf;base64," +
                productSeriesSelected[productSeriesSelected.length - 1].customBrochurePdfBase64; */
            let customBrochurePdfPath = productSeriesSelected[productSeriesSelected.length - 1].customBrochurePdfPath;
            let isCustomPdfUpload = productSeriesSelected[productSeriesSelected.length - 1].isCustomPdfUpload;
            if (this.micrositeId && customBrochurePdfPath) {
                const customBrochurePdfUrl = customBrochurePdfPath + "?ts=" + currentTime;
                let pdfWindow = window.open();
                pdfWindow.document.write(
                    "<html><head>" +
                        '<meta http-equiv="Cache-Control" content="no-store" />' +
                        "<title>" +
                        title +
                        "</title></head><body>" +
                        '<embed width="100%" height="100%" name="plugin" src="' +
                        customBrochurePdfUrl +
                        '" ' +
                        'type="application/pdf" internalinstanceid="21"></body></html>'
                );
            } else {
                this.viewBase64AsUrl(customBrochurePdfBase64, title);
            }
        },
        cancelRemoveSpanishPdf(lobId) {
            const lobIndex = this.getItemIndexByID(this.displayProducts.lineofBusinesses, lobId);
            const productSeriesSelectedGroup =
                this.type.GROUP_SPANISH_SELECTED == this.type.INDIVIDUAL_SPANISH_PRODUCT
                    ? this.displayProducts.lineofBusinesses[lobIndex].productSeriesSpanish
                    : this.displayProducts.lineofBusinesses[lobIndex].productSeriesSpanishG;
            if (Array.isArray(productSeriesSelectedGroup) && productSeriesSelectedGroup.length > 0) {
                const lastItem = productSeriesSelectedGroup[productSeriesSelectedGroup.length - 1];
                if (lastItem.isCustomPdfUpload) {
                    lastItem.selected = true;
                    this.removeCustomSpanishPdtBroPopup = false;
                }
            }
        },
        removeSpanishPDFConfirm(lobId, type, isCurrentPdfSelfRemove = false) {
            this.currentLobIdToRemove = lobId;
            this.isCurrentPdfSelfRemove = isCurrentPdfSelfRemove;
            this.removeCustomSpanishPdtBroPopup = true;
            this.type.GROUP_SPANISH_SELECTED =
                type === this.type.INDIVIDUAL_SPANISH_PRODUCT
                    ? this.type.INDIVIDUAL_SPANISH_PRODUCT
                    : this.type.GROUP_SPANISH_PRODUCT;
            this.type.GROUP_SELECTED =
                type === this.type.GROUP_SPANISH_PRODUCT ? this.type.AFLAC_GROUP : this.type.AFLAC_SUPPLEMENTAL;
        },
        removeSpnBrochurePdf(lobId, type = "") {
            const typeSelected =
                this.type.GROUP_SELECTED == this.type.AFLAC_GROUP
                    ? this.type.GROUP_SPANISH_PRODUCT
                    : this.type.INDIVIDUAL_SPANISH_PRODUCT;

            const lobIndex = this.getItemIndexByID(this.displayProducts.lineofBusinesses, lobId);
            let productSeriesSelectedGroup =
                typeSelected == this.type.INDIVIDUAL_SPANISH_PRODUCT
                    ? this.displayProducts.lineofBusinesses[lobIndex].productSeriesSpanish
                    : this.displayProducts.lineofBusinesses[lobIndex].productSeriesSpanishG;
            //const spanishLastItem = productSeriesSelectedGroup[productSeriesSelectedGroup.length - 1];
            if (productSeriesSelectedGroup) {
                productSeriesSelectedGroup[productSeriesSelectedGroup.length - 1].showCustomBrochureSection = false;
                productSeriesSelectedGroup[productSeriesSelectedGroup.length - 1].disclaimer = false;
                productSeriesSelectedGroup[productSeriesSelectedGroup.length - 1].selected = false;
                productSeriesSelectedGroup[productSeriesSelectedGroup.length - 1].customBrochurePdfName = "";
                productSeriesSelectedGroup[productSeriesSelectedGroup.length - 1].customBrochurePdfBase64 = "";
                productSeriesSelectedGroup[productSeriesSelectedGroup.length - 1].isCustomPdfUpload = false;
            }

            this.customBrochurePdf = "";
            this.removeCustomSpanishPdtBroPopup = false;
            this.CustomPdfUPloadModal = false;
        },

        cancelSpanishPdfConfirm(group_selected) {
            this.showPDFUPloadModalpopupSpanish = false;
            this.selectedProductSeriesDetail.productSeriesSpanish.disclaimer = false;
            this.selectedProductSeriesDetail.productSeriesSpanish.selected = false;
        },

        openSpanishPDFConfirm(event, lob, productSeriesSpanish, type) {
            if (lob.showDot) {
                if (productSeriesSpanish.selected) {
                    this.removeSpanishPDFConfirm(lob.ItemId, type);
                } else {
                    this.showPDFUPloadModalpopupSpanish = true;
                    this.selectedProductSeriesDetail.lineOfBusiness = lob;
                    this.selectedProductSeriesDetail.productSeriesSpanish = productSeriesSpanish;
                    this.type.GROUP_SPANISH_SELECTED = type;
                }
            }
        },

        retryBrowseSpnPdf(event, lob, productSeriesSpanish, type) {
            this.showPDFUPloadModalpopupSpanish = false;
            this.selectedProductSeriesDetail.lineOfBusiness = lob;
            this.selectedProductSeriesDetail.productSeriesSpanish = productSeriesSpanish;
            if (lob.showDot) {
                let fileInputElementSPN = this.$refs.retryFileSpnBochurePdf;
                if (this.selectedProductSeriesDetail.productSeriesSpanish.customBrochurePdfName == "") {
                    fileInputElementSPN.click();
                    return;
                }
                // } else if (this.selectedProductSeriesDetail.productSeriesSpanish.isCustomPdfPreviewErr) {
                //     this.removeSpnBrochurePdf(lob.ItemId, type);
                // } else {
                //     this.removeSpanishPDFConfirm(lob.ItemId, type);
                // }
            }
            //  else {
            //     this.removeConfirmSpnBochurePdfPopup(lob.Id);
            // }
        },
        onSpnBochureUpload() {
            let uploadedFile = this.$refs.retryFileSpnBochurePdf.files[0];
            this._processSpnBochurePdfUpload(uploadedFile);
        },
        _processSpnBochurePdfUpload(uploadedFile, type) {
            this.selectedProductSeriesDetail.productSeriesSpanish.selected = true;
            const pdfFileSizeLimit = this.cutomSpnPdfPdfSize;
            this.customBrochurePdfExt = uploadedFile.name
                .split(".")
                .pop()
                .toLowerCase();
            if (this.customBrochurePdfExt != "pdf") {
                this.selectedProductSeriesDetail.productSeriesSpanish.showCustomBrochureSection = true;
                this.selectedProductSeriesDetail.productSeriesSpanish.isCustomPdfPreviewErr = true;
                this.selectedProductSeriesDetail.productSeriesSpanish.isPdfFileInvalid = true;
                this.$refs.retryFileSpnBochurePdf.value = null;
                return;
            } else if (Math.ceil(uploadedFile.size / 1024) > pdfFileSizeLimit) {
                this.selectedProductSeriesDetail.productSeriesSpanish.showCustomBrochureSection = true;
                this.selectedProductSeriesDetail.productSeriesSpanish.isCustomPdfPreviewErr = true;
                this.selectedProductSeriesDetail.productSeriesSpanish.isPdfFileInvalid = false;
                this.$refs.retryFileSpnBochurePdf.value = null;
                return;
            } else {
                this.selectedProductSeriesDetail.productSeriesSpanish.showCustomBrochureSection = true;
                this.selectedProductSeriesDetail.productSeriesSpanish.isCustomPdfPreviewErr = false;
                this.selectedProductSeriesDetail.productSeriesSpanish.customBrochurePdfName = uploadedFile.name;
                this.customBrochurePdf = uploadedFile;
                let file = uploadedFile;
                this.getBase64(file).then(data => {
                    this.selectedProductSeriesDetail.productSeriesSpanish.customBrochurePdfBase64 = data.substring(
                        data.indexOf(",") + 1
                    );

                    this.selectedProductSeriesDetail.productSeriesSpanish.isCustomPdfUpload = true;
                    this.selectedProductSeriesDetail.productSeriesSpanish.customBrochurePdfPath = "";
                    this.$refs.retryFileSpnBochurePdf.value = null;
                    return true;
                });
            }
        },
        getSelectedPladPdfDetails(pladLobSelectedList, type) {
            if (pladLobSelectedList.length > 0) {
                let pladPdfData = "";
                pladLobSelectedList.forEach(async lobRecord => {
                    if (type == this.type.PLAD_PRODUCT && lobRecord.pdfDetails) {
                        let data = lobRecord.pdfDetails;
                        if (data && data.base64) {
                            let pdfName = data.fileName.split(".")[0];
                            pladPdfData += lobRecord.Id + ":" + data.base64 + ":" + pdfName + "|";
                        }
                    }
                });
                if (pladPdfData != "") {
                    pladPdfData = pladPdfData.slice(0, -1);
                }
                return pladPdfData;
            }
        },
        onPladProductResetModalClick(action, pladLobData) {
            if (action == "CANCEL") {
                this.showPladProductResetPopup = false;
                this.siteForm.pladSelection = true;
            } else if (action == "RESET") {
                this.showPladProductResetPopup = false;
                this.pladLobData = {};
                pladLobData = {};
            }
        },
        removeConfirmPladPdfPopup(lobId) {
            this.currentPladLobIdToRemove = lobId;
            this.removePladPdfPopup = true;
        },
        closePladPdfPopup(lobId) {
            this.removePladPdfPopup = false;
            const lobIndex = this.getPladLobIndex(this.pladLobData.PladsProducts, lobId);
            this.pladLobData.PladsProducts[lobIndex].selected = true;
        },
        removePladPdf(lobId) {
            if (!lobId) {
                this.pladLobSelectedCurrent.lob.selected = false;
                return;
            }
            const lobIndex = this.getPladLobIndex(this.pladLobData.PladsProducts, lobId);
            this.pladLobSelectedCurrent.browsePdf = false;
            if (this.pladLobData.PladsProducts[lobIndex].ItemId) {
                this.pladLobData.PladsProducts[lobIndex].selected = false;
                this.pladLobData.PladsProducts[lobIndex].showPladBrochureSection = false;
                this.pladLobData.PladsProducts[lobIndex].showSuccessMsg = false;
                this.pladLobData.PladsProducts[lobIndex].showSizeError = false;
                this.pladLobData.PladsProducts[lobIndex].showError = false;
                this.pladLobData.PladsProducts[lobIndex].pdfDetails = {
                    fileName: "",
                    base64: "",
                    isPdfUpload: false,
                    pdfPath: ""
                };
                this.$refs.retryFilePladLobPdf.value = null;
            }
            this.removePladPdfPopup = false;
        },
        viewFilePlad(lobId, type = this.type.PLAD_PRODUCT, title) {
            const lobIndex = this.getPladLobIndex(this.pladLobData.PladsProducts, lobId);
            let productSelected = this.pladLobData.PladsProducts[lobIndex];
            let brochurePdfBase64 = "data:application/pdf;base64," + productSelected.pdfDetails.base64;
            let brochurePdfPath = productSelected.pdfDetails.pdfPath;
            let isPdfUpload = productSelected.pdfDetails.isPdfUpload;
            if (this.micrositeId && brochurePdfPath) {
                const brochurePdfUrl = brochurePdfPath + "?ts=" + currentTime;
                let pdfWindow = window.open();
                pdfWindow.document.write(
                    "<html><head><title>" +
                        title +
                        "</title></head><body>" +
                        '<embed width="100%" height="100%" name="plugin" src="' +
                        brochurePdfUrl +
                        '" ' +
                        'type="application/pdf" internalinstanceid="21"></body></html>'
                );
            } else {
                this.viewBase64AsUrl(productSelected.pdfDetails.base64, title);
            }
        },
        getPladLobIndex(pladProducts, Id) {
            let index = pladProducts.findIndex(x => x.Id == Id);
            return index;
        },
        _processPladLobPdfUpload(uploadedFile, type) {
            const lobIndex = this.getPladLobIndex(this.pladLobData.PladsProducts, this.pladLobSelectedCurrent.lob.Id);
            this.pladLobData.PladsProducts[lobIndex].showPladBrochureSection = true;
            let pladLobPdfPdfExt = uploadedFile.name
                .split(".")
                .pop()
                .toLowerCase();
            this.pladLobData.PladsProducts[lobIndex].selected = true;
            if (pladLobPdfPdfExt != "pdf") {
                this.pladLobData.PladsProducts[lobIndex].showSuccessMsg = false;
                this.pladLobData.PladsProducts[lobIndex].showSizeError = false;
                this.pladLobData.PladsProducts[lobIndex].showError = true;
                this.$refs.retryFilePladLobPdf.value = null;
                return;
            } else if (Math.ceil(uploadedFile.size / 1024) > this.pladPdfSize) {
                this.pladLobSelectedCurrent.pladPDFUPloadModalpopup = false;
                this.pladLobData.PladsProducts[lobIndex].showSuccessMsg = false;
                this.pladLobData.PladsProducts[lobIndex].showSizeError = true;
                this.pladLobData.PladsProducts[lobIndex].showError = true;
                this.$refs.retryFilePladLobPdf.value = null;
                return;
            } else {
                this.pladLobSelectedCurrent.pladPDFUPloadModalpopup = false;
                this.pladLobData.PladsProducts[lobIndex].showSuccessMsg = true;
                this.pladLobData.PladsProducts[lobIndex].showSizeError = false;
                this.pladLobData.PladsProducts[lobIndex].showError = false;

                let file = uploadedFile;
                this.getBase64(file).then(data => {
                    let base64Value = data.substring(data.indexOf(",") + 1);
                    let pladPdfDetailsBase64 = {};
                    pladPdfDetailsBase64 = {
                        fileName: uploadedFile.name,
                        base64: base64Value,
                        isPdfUpload: true,
                        pdfPath: ""
                    };
                    this.pladLobData.PladsProducts[lobIndex].pdfDetails = pladPdfDetailsBase64;
                    //this.pladLobData.PladsProducts[lobIndex].isPdfUpload = true;
                    this.pladLobData.PladsProducts[lobIndex].showSuccessMsg = true;
                    this.pladLobData.PladsProducts[lobIndex].showSizeError = false;
                    this.pladLobData.PladsProducts[lobIndex].showError = false;
                    this.$refs.retryFilePladLobPdf.value = null;
                    return true;
                });
            }
        },
        addCustomFieldsInPladProducts: function(products) {
            if (Array.isArray(products) && products.length > 0) {
                for (let i = 0; i < products.length; i++) {
                    let product = products[i];
                    Vue.set(product, "selected", false);
                    Vue.set(product, "disabled", false);
                    Vue.set(product, "showPladBrochureSection", false);
                    const pladPdfDetailsBase64 = {
                        fileName: "",
                        base64: "",
                        isPdfUpload: false,
                        pdfPath: ""
                    };
                    Vue.set(product, "pdfDetails", pladPdfDetailsBase64);
                }
            }
        },
        retryBrowsePladPdf(event, lob, type) {
            if (lob.selected) {
                this.pladLobSelectedCurrent.browsePdf = false;
                this.pladLobSelectedCurrent.lob = lob;
                let fileInputElementRS = this.$refs.retryFilePladLobPdf;
                fileInputElementRS.click();
                return;
            } else {
                this.removeConfirmPladPdfPopup(lob.Id);
            }
        },
        onPladLobUpload() {
            this.pladLobSelectedCurrent.browsePdf = true;
            let uploadedFile = this.$refs.retryFilePladLobPdf.files[0];
            this._processPladLobPdfUpload(uploadedFile);
        },
        getPlads() {
            if (this.siteForm.pladSelection) {
                return new Promise((resolve, reject) => {
                    const url = `/PladsProducts/GetPladsProducts`;
                    this.getPladProductDataByApi(url)
                        .then(result => {
                            this.pladLobData = result.data.Result;
                            this.addCustomFieldsInPladProducts(this.pladLobData.PladsProducts);
                            resolve(this.pladLobData);
                        })
                        .catch(err => "There is issue fetching plad data");
                });
            } else {
                const selectedPladPdfDetail = this.getSelectedPladPdfDetails(
                    this.pladLobData.PladsProducts,
                    this.type.PLAD_PRODUCT
                );
                if (selectedPladPdfDetail) {
                    this.showPladProductResetPopup = true;
                } else {
                    this.pladLobData = {};
                }
            }
        },
        getPladProductDataByApi(url) {
            const promise = new Promise((resolve, reject) => {
                setTimeout(() => {
                    httpClient.get(url).then(result => {
                        if (result.data.Result) {
                            resolve(result);
                        } else {
                            reject({
                                error: "Fetching plad data issue"
                            });
                        }
                    });
                }, 100);
            });
            return promise;
        },
        unselectAllProductSeriesByLobId(lobId, type) {
            if (type == this.type.AFLAC_SUPPLEMENTAL) {
                const countProductSelected = this.selectedProductSeriesDetail;
                const lobIndex = this.getItemIndexByID(this.displayProducts.lineofBusinesses, lobId);
                let productSeriesSelectedIndv = this.displayProducts.lineofBusinesses[lobIndex].productSeries;
                if (this.getSelectedCount(productSeriesSelectedIndv) > 0) {
                    if (
                        Array.isArray(this.displayProducts.lineofBusinesses) &&
                        this.displayProducts.lineofBusinesses.length > 0
                    ) {
                        const productSeries = this.displayProducts.lineofBusinesses[lobIndex].productSeries;
                        for (let i = 0; i < productSeries.length - 1; i++) {
                            if (this.displayProducts.lineofBusinesses[lobIndex].productSeries[i].ItemId) {
                                this.displayProducts.lineofBusinesses[lobIndex].productSeries[i].selected = false;
                                if (
                                    Array.isArray(productSeries[i].productLevels) &&
                                    productSeries[i].productLevels.length > 0
                                ) {
                                    for (let k = 0; k < productSeries[i].productLevels.length; k++) {
                                        productSeries[i].productLevels[k].selected = false;
                                    }
                                }
                                if (Array.isArray(productSeries[i].riders) && productSeries[i].riders.length > 0) {
                                    for (let k = 0; k < productSeries[i].riders.length; k++) {
                                        productSeries[i].riders[k].selected = false;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        isSelectedCustomIndvBrocure() {
            let lobCount = 0;
            let customIndvBrochureCount = 0;
            if (
                Array.isArray(this.displayProducts.lineofBusinesses) &&
                this.displayProducts.lineofBusinesses.length > 0
            ) {
                this.displayProducts.lineofBusinesses.forEach(lob => {
                    if (
                        (lob.showDot && Array.isArray(lob.productSeries) && lob.productSeries.length > 0) ||
                        (lob.showDot && Array.isArray(lob.productSeriesSpanish) && lob.productSeriesSpanish.length > 0)
                    ) {
                        lobCount++;
                        if (lob.productSeries) {
                            lob.productSeries.forEach(ps => {
                                if (ps.isCustomPdfUpload) {
                                    customIndvBrochureCount++;
                                }
                            });
                        }
                        if (lob.productSeriesSpanish) {
                            lob.productSeriesSpanish.forEach(ps => {
                                if (ps.isCustomPdfUpload) {
                                    customIndvBrochureCount++;
                                }
                            });
                        }
                    }
                });
            }
            if (customIndvBrochureCount > 0) {
                this.siteForm.IsSuggestiveEnable = false;
                return true;
            } else {
                return false;
            }
        },
        openOneDisclaimer() {
            this.oneDisclaimerShow = true;
        },
        closeOneDisclaimer() {
            this.oneDisclaimerShow = false;
            this.saveAndPublishButton = this.disclaimerCloseButton ? true : false;
        },
        setLobAflacRSBase64() {
            if (Object.keys(this.aflacRSPDfSelectedObj).length > 0) {
                Object.keys(this.aflacRSPDfSelectedObj).forEach(async LOBShortName => {
                    let url = this.aflacRSPDfSelectedObj[LOBShortName].pdfPath;
                    let base64Data = await getBase64FromUrl(url).then(data => data);
                    let obj = (this.aflacRSPDfSelectedObjBase64[LOBShortName] = {
                        aflacRSPdfBase64: base64Data.substring(base64Data.indexOf(",") + 1)
                    });
                });
            }
        },
        setLobAflacRSDetails() {
            const lineofBusinesses = this.displayProducts.lineofBusinesses;
            if (lineofBusinesses.length > 0) {
                lineofBusinesses.forEach(async (lineofBusiness, i) => {
                    if (
                        Object.keys(this.aflacRSPDfSelectedObj).includes(lineofBusiness.LOBShortName) &&
                        this.aflacRSPDfSelectedObj.hasOwnProperty(lineofBusiness.LOBShortName) &&
                        this.aflacRSPDfSelectedObj[lineofBusiness.LOBShortName].hasOwnProperty("pdfPath") &&
                        typeof this.aflacRSPDfSelectedObj[lineofBusiness.LOBShortName].pdfPath != "undefined"
                    ) {
                        this.displayProducts.lineofBusinesses[i].aflacRSPdfPath = this.aflacRSPDfSelectedObj[
                            lineofBusiness.LOBShortName
                        ].pdfPath;
                        this.displayProducts.lineofBusinesses[i].aflacRSPdfName = this.aflacRSPDfSelectedObj[
                            lineofBusiness.LOBShortName
                        ].fileName;
                        this.displayProducts.lineofBusinesses[i].showAflacRSPdfSection = true;
                    }
                });
            }
        },
        getSelectedAflacRSPdfDetails(displayProducts, type) {
            const lineofBusinesses = displayProducts.lineofBusinesses;
            if (lineofBusinesses.length > 0) {
                let aflacPdfData = "";
                let formatType = "Base64";
                lineofBusinesses.forEach((lob, index) => {
                    let pdfName = "";
                    if (type == this.type.AFLAC_RS && lob && lob.showDot) {
                        if (lob && lob.aflacRSPdfBase64) {
                            pdfName = lob.aflacRSPdfName.split(".")[0];
                            aflacPdfData += lob.LOBShortName + ":" + lob.aflacRSPdfBase64 + ":" + pdfName + "|";
                        } else if (lob && this.aflacRSPDfSelectedObjBase64[lob.LOBShortName]) {
                            pdfName = lob.aflacRSPdfName.split(".")[0];
                            aflacPdfData +=
                                lob.LOBShortName +
                                ":" +
                                this.aflacRSPDfSelectedObjBase64[lob.LOBShortName].aflacRSPdfBase64 +
                                ":" +
                                pdfName +
                                "|";
                        }
                    } else {
                        lineofBusinesses[index].showAflacRSPdfSection = false;
                        lineofBusinesses[index].disclaimer = false;
                        lineofBusinesses[index].selected = false;
                        lineofBusinesses[index].aflacRSPdfName = "";
                        lineofBusinesses[index].aflacRSPdfBase64 = "";
                        lineofBusinesses[index].aflacRSPdfPath = "";
                        lineofBusinesses[index].isCustomPdfPreviewErr = false;
                        lineofBusinesses[index].isAflacRSPdfUpload = false;
                    }
                });
                if (aflacPdfData != "") {
                    aflacPdfData = aflacPdfData.slice(0, -1);
                }
                return aflacPdfData;
            }
        },
        selectLOBAflacRSPdf(lineofBusiness, aflacRSPDfSelectedObj) {
            if (lineofBusiness.length > 0) {
                lineofBusiness.forEach(lob => {
                    const lobIndex = this.getItemIndexByID(this.displayProducts.lineofBusinesses, lob.ItemId);
                    if (
                        aflacRSPDfSelectedObj.hasOwnProperty(lob.LOBShortName) &&
                        aflacRSPDfSelectedObj[lob.LOBShortName].hasOwnProperty("pdfPath") &&
                        typeof aflacRSPDfSelectedObj[lob.LOBShortName].pdfPath != "undefined"
                    ) {
                        this.displayProducts.lineofBusinesses[lobIndex].aflacRSPdfBase64 =
                            aflacRSPDfSelectedObj[lob.LOBShortName].base64;
                        this.displayProducts.lineofBusinesses[lobIndex].aflacRSPdfPath =
                            aflacRSPDfSelectedObj[lob.LOBShortName].pdfPath;
                        this.displayProducts.lineofBusinesses[lobIndex].aflacRSPdfName =
                            aflacRSPDfSelectedObj[lob.LOBShortName].fileName;
                        this.displayProducts.lineofBusinesses[lobIndex].showAflacRSPdfSection = true;
                    }
                });
            }
        },
        viewFileAflacRSPdf(lobId) {
            const lobIndex = this.getItemIndexByID(this.displayProducts.lineofBusinesses, lobId);
            let LOBSelected = this.displayProducts.lineofBusinesses[lobIndex];
            // let aflacRSPdfBase64 = "data:application/pdf;base64," + LOBSelected.aflacRSPdfBase64;
            let aflacRSPdfPath = LOBSelected.aflacRSPdfPath;
            let isAflacRSPdfUpload = LOBSelected.isAflacRSPdfUpload;
            const title = this.aflacRSPdfTitle;
            if (this.micrositeId && aflacRSPdfPath) {
                const aflacRSPdfUrl = aflacRSPdfPath + "?ts=" + currentTime;
                let pdfWindow = window.open();
                pdfWindow.document.write(
                    "<html><head><title>" +
                        title +
                        "</title></head><body>" +
                        '<embed width="100%" height="100%" name="plugin" src="' +
                        aflacRSPdfUrl +
                        '" ' +
                        'type="application/pdf" internalinstanceid="21"></body></html>'
                );
            } else {
                this.viewBase64AsUrl(LOBSelected.aflacRSPdfBase64, title);
            }
        },
        removeConfirmAflacPdfPopup(lobId) {
            this.aflacRSPdfToRemovedLobId = lobId;
            this.removeAflacRSPdfPopup = true;
        },
        closeAflacPdfPopup(lobId) {
            this.removeAflacRSPdfPopup = false;
        },
        retryBrowseAflacRSPdf($event, lob) {
            this.selectedProductSeriesDetail.lineOfBusiness = lob;
            let fileInputElement = this.$refs.retryFileAflacRSPdf;
            fileInputElement.click();
            //$refs.retryFileCustomPdf.click()
        },
        onAflacRSUpload() {
            let uploadedFile = this.$refs.retryFileAflacRSPdf.files[0];
            this._processAflacRSPdfUpload(uploadedFile);
        },
        uploadAflacRSPdf(event, lobId) {
            this.showRateSheetPDFUPloadModalpopup = false;
            this.isAflacRSDisclaimerFirstOnly = true;
            let uploadedFile = this.$refs.fileAflacRsPdf.files[0];
            this._processAflacRSPdfUpload(uploadedFile);
        },
        /*onSelectAflacRS(lob) {
            this.selectedProductSeriesDetail.lineOfBusiness = lob;
            if (!this.isAflacRSDisclaimerFirstOnly) {
                this.showRateSheetPDFUPloadModalpopup = true;
            } else {
                this.showRateSheetPDFUPloadModalpopup = false;
                let fileInputElement = this.$refs.retryFileAflacRSPdf;
                fileInputElement.click();
            }
        },*/
        _processAflacRSPdfUpload(uploadedFile) {
            this.customBrochurePdfExt = uploadedFile.name
                .split(".")
                .pop()
                .toLowerCase();
            if (this.customBrochurePdfExt != "pdf") {
                this.selectedProductSeriesDetail.lineOfBusiness.showAflacRSPdfSection = true;
                this.selectedProductSeriesDetail.lineOfBusiness.isCustomPdfPreviewErr = true;
                this.selectedProductSeriesDetail.lineOfBusiness.isPdfFileInvalid = true;
                this.$refs.retryFileAflacRSPdf.value = null;
                return;
            } else if (Math.ceil(uploadedFile.size / 1024) > this.aflacRSPdfSize) {
                this.selectedProductSeriesDetail.lineOfBusiness.showAflacRSPdfSection = true;
                this.selectedProductSeriesDetail.lineOfBusiness.isCustomPdfPreviewErr = true;
                this.selectedProductSeriesDetail.lineOfBusiness.isPdfFileInvalid = false;
                this.$refs.retryFileAflacRSPdf.value = null;
                return;
            } else {
                this.selectedProductSeriesDetail.lineOfBusiness.showAflacRSPdfSection = true;
                this.selectedProductSeriesDetail.lineOfBusiness.isCustomPdfPreviewErr = false;
                this.selectedProductSeriesDetail.lineOfBusiness.aflacRSPdfName = uploadedFile.name;
                this.getBase64(uploadedFile).then(data => {
                    this.selectedProductSeriesDetail.lineOfBusiness.aflacRSPdfBase64 = data.substring(
                        data.indexOf(",") + 1
                    );
                    //this.selectedProductSeriesDetail.lineOfBusiness.showDot = true;
                    this.selectedProductSeriesDetail.lineOfBusiness.isAflacRSPdfUpload = true;
                    this.$refs.retryFileAflacRSPdf.value = null;
                    return true;
                });
            }
        },
        removeAflacRSPdf(lobId) {
            const lobIndex = this.getItemIndexByID(this.displayProducts.lineofBusinesses, lobId);
            let lineOfBusinessObj = this.displayProducts.lineofBusinesses[lobIndex];
            if (this.micrositeId && this.aflacRSPDfSelectedObjBase64[lineOfBusinessObj.LOBShortName]) {
                delete this.aflacRSPDfSelectedObjBase64[lineOfBusinessObj.LOBShortName];
                lineOfBusinessObj.showAflacRSPdfSection = false;
                lineOfBusinessObj.disclaimer = false;
                lineOfBusinessObj.selected = false;
                lineOfBusinessObj.aflacRSPdfName = "";
                lineOfBusinessObj.aflacRSPdfBase64 = "";
                lineOfBusinessObj.aflacRSPdfPath = "";
                lineOfBusinessObj.isCustomPdfPreviewErr = false;
                lineOfBusinessObj.isAflacRSPdfUpload = false;
            } else if (
                this.selectedProductSeriesDetail.lineOfBusiness &&
                this.selectedProductSeriesDetail.lineOfBusiness.ItemId
            ) {
                lineOfBusinessObj.showAflacRSPdfSection = false;
                lineOfBusinessObj.disclaimer = false;
                lineOfBusinessObj.selected = false;
                lineOfBusinessObj.aflacRSPdfName = "";
                lineOfBusinessObj.aflacRSPdfBase64 = "";
                lineOfBusinessObj.aflacRSPdfPath = "";
                lineOfBusinessObj.isCustomPdfPreviewErr = false;
                lineOfBusinessObj.isAflacRSPdfUpload = false;
            }
            this.removeAflacRSPdfPopup = false;
        },
        isMediaUrl(url) {
            return url.toLowerCase() == "https" || url.toLowerCase() == "http" ? false : true;
        },
        isNonAflacProductSelect(lobId) {
            let count = 0;
            if (lobId) {
                const lobIndex = this.getNonAflacLobIndex(this.nonAflacLobSelectedList, lobId);
                let recd = this.nonAflacLobSelectedList[lobIndex];
                if (recd.listPdfBase64.length > 0) {
                    return true;
                } else {
                    return false;
                }
            } else {
                if (Array.isArray(this.nonAflacLobSelectedList)) {
                    this.nonAflacLobSelectedList.forEach(recd => {
                        if (recd.listPdfBase64.length > 0) {
                            count++;
                        }
                    });
                    if (count > 0) {
                        this.siteForm.IsSuggestiveEnable = false;
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            }
        },
        getSelectedNonAflacPdfDetails(nonAflacLobSelectedList, type) {
            if (nonAflacLobSelectedList.length > 0) {
                let nonAflacPdfData = "";
                let formatType = "Base64";
                nonAflacLobSelectedList.forEach(lobRecord => {
                    if (type == this.type.NONAFLAC_RS && lobRecord.rateSheetDetails) {
                        let data = lobRecord.rateSheetDetails;
                        if (data && data.base64) {
                            let pdfName = data.fileName.split(".")[0];
                            nonAflacPdfData += lobRecord.lob.Id + ":" + data.base64 + ":" + pdfName + "|";
                        } else if (data && !data.base64 && data.isPdfUpload) {
                            nonAflacPdfData +=
                                lobRecord.lob.Id +
                                ":" +
                                this.nonAfalcPdfSelectedBase64[lobRecord.lob.Name][index].base64 +
                                ":" +
                                this.nonAfalcPdfSelectedBase64[lobRecord.lob.Name][index].name +
                                "|";
                        }
                    } else if (type == this.type.NONAFLAC_PRODUCT && lobRecord.listPdfBase64.length > 0) {
                        lobRecord.listPdfBase64.forEach((data, index) => {
                            if (data && data.base64) {
                                let pdfName = data.fileName.split(".")[0];
                                nonAflacPdfData += lobRecord.lob.Id + ":" + data.base64 + ":" + pdfName + "|";
                            } else if (data && !data.base64 && data.isPdfUpload) {
                                nonAflacPdfData +=
                                    lobRecord.lob.Id +
                                    ":" +
                                    this.nonAfalcPdfSelectedBase64[lobRecord.lob.Name][index].base64 +
                                    ":" +
                                    this.nonAfalcPdfSelectedBase64[lobRecord.lob.Name][index].name +
                                    "|";
                            }
                        });
                    }
                });
                if (nonAflacPdfData != "") {
                    nonAflacPdfData = nonAflacPdfData.slice(0, -1);
                }
                return nonAflacPdfData;
            }
        },
        nonAflacPdfLevelChange(str, name) {
            return str.replace("#", name);
        },
        removeConfirmNonAflacPdfPopup(type, lobId, findex) {
            this.nonAflacPdfToRemovedData = { type: type, lobId: lobId, findex: findex };
            this.showRemoveNonAflacPdfPopup = true;
        },
        closeNonAflacPdfPopup() {
            this.showRemoveNonAflacPdfPopup = false;
        },
        removeNonAflacPdfByPopup() {
            let { type, lobId, findex } = this.nonAflacPdfToRemovedData;
            this.showRemoveNonAflacPdfPopup = false;
            const lobIndex = this.getNonAflacLobIndex(this.nonAflacLobSelectedList, lobId);
            if (type == this.type.NONAFLAC_RS) {
                this.nonAflacLobSelectedList[lobIndex].rateSheetDetails = {};
                this.nonAflacLobSelectedList[lobIndex].showNonAflacRSSuccessMsg = false;
                this.nonAflacLobSelectedList[lobIndex].isRSPdfUpload = false;
            } else {
                let listPdfBase64New = this.nonAflacLobSelectedList[lobIndex].listPdfBase64.filter((v, ind) => {
                    return ind != findex;
                });
                if (listPdfBase64New && listPdfBase64New.length > 0) {
                    this.nonAflacPdfToRemovedData = {};
                    this.nonAflacLobSelectedList[lobIndex].listPdfBase64 = listPdfBase64New;
                } else {
                    this.nonAflacLobSelectedList[lobIndex].listPdfBase64 = listPdfBase64New;
                    this.nonAflacLobSelectedList[lobIndex].rateSheetDetails = {};
                    this.nonAflacLobSelectedList[lobIndex].showNonAflacRSSuccessMsg = false;
                    this.nonAflacLobSelectedList[lobIndex].showNonAflacError = false;
                    this.nonAflacLobSelectedList[lobIndex].isRSPdfUpload = false;
                }
            }
        },
        viewNonAflacPdfFile(type, lobId, findex) {
            let pdfPath = "";
            let pdfBase64 = "";
            let title = "";
            if (type == this.type.NONAFLAC_RS) {
                title = this.nonAflacRSPdfTitle;
                const lobIndex = this.getNonAflacLobIndex(this.nonAflacLobSelectedList, lobId);
                let listPdfBase64 = this.nonAflacLobSelectedList[lobIndex].rateSheetDetails;
                pdfBase64 = listPdfBase64.base64;
                //pdfBase64 = "data:application/pdf;base64," + listPdfBase64.base64;
                pdfPath = listPdfBase64.pdfPath;
            } else {
                title = this.nonAflacProductPdfTitle;
                const lobIndex = this.getNonAflacLobIndex(this.nonAflacLobSelectedList, lobId);
                let listPdfBase64 = this.nonAflacLobSelectedList[lobIndex].listPdfBase64;
                pdfBase64 = listPdfBase64[findex].base64;
                //pdfBase64 = "data:application/pdf;base64," + listPdfBase64[findex].base64;
                pdfPath = listPdfBase64[findex].pdfPath;
            }
            if (this.micrositeId && pdfPath) {
                const pdfUrl = pdfPath + "?ts=" + currentTime;
                let pdfWindow = window.open();
                pdfWindow.document.write(
                    "<html><head><title>" +
                        title +
                        "</title></head><body>" +
                        '<embed width="100%" height="100%" name="plugin" src="' +
                        pdfUrl +
                        '" ' +
                        'type="application/pdf" internalinstanceid="21"></body></html>'
                );
            } else {
                this.viewBase64AsUrl(pdfBase64, title);
            }
        },
        getNonAflacLobIndex(nonAflacLobSelectedList, Id) {
            let index = nonAflacLobSelectedList.findIndex(x => x.lob.Id == Id);
            return index;
        },
        getNonAflacLobItemFromList(nonAflacLob, Id) {
            let item = nonAflacLob.NonAflacBenefits.find(x => x.Id === Id);
            return item;
        },
        addCustomFieldsInNonAflacProducts: function(products) {
            if (Array.isArray(products) && products.length > 0) {
                for (let i = 0; i < products.length; i++) {
                    let product = products[i];
                    Vue.set(product, "selected", false);
                    Vue.set(product, "disabled", false);
                }
            }
        },
        retryBrowseNonAflacPdf($event, lob, type) {
            this.nonAflacPdfLastRetrydData = { type: type, lobId: lob.Id };
            if (type == this.type.NONAFLAC_RS) {
                this.nonAflacLobSelectedCurrent.lob = {};
                let lobData = this.nonAflacLob.NonAflacBenefits.filter(data => {
                    return data.Id == lob.Id;
                });
                this.nonAflacLobSelectedCurrent.lob = lobData[0];
                let fileInputElementRS = this.$refs.retryFileNonAflacRSPdf;
                fileInputElementRS.click();
            } else {
                this.nonAflacLobSelectedCurrent.lob = {};
                let lobData = this.nonAflacLob.NonAflacBenefits.filter(data => {
                    return data.Id == lob.Id;
                });
                this.nonAflacLobSelectedCurrent.lob = lobData[0];
                let fileInputElement = this.$refs.retryFileNonAflacLobPdf;
                fileInputElement.click();
            }
        },
        onNonAflacRSUpload() {
            let uploadedFile = this.$refs.retryFileNonAflacRSPdf.files[0];
            this._processNonAflacLobPdfUpload(uploadedFile, this.type.NONAFLAC_RS);
        },
        onNonAflacLobUpload() {
            let uploadedFile = this.$refs.retryFileNonAflacLobPdf.files[0];
            this._processNonAflacLobPdfUpload(uploadedFile);
        },
        uploadNonAflacLobPdf(event, lobId) {
            this.nonAflacLobSelectedCurrent.nonAflacPDFUPloadModalpopup = false;
            let uploadedFile = this.$refs.fileNonAflacPdf.files[0];
            this._processNonAflacLobPdfUpload(uploadedFile);
        },
        _processNonAflacLobPdfUpload(uploadedFile, type) {
            const lobIndex = this.getNonAflacLobIndex(
                this.nonAflacLobSelectedList,
                this.nonAflacLobSelectedCurrent.lob.Id
            );
            let nonAflacLobPdfPdfExt = uploadedFile.name
                .split(".")
                .pop()
                .toLowerCase();
            if (nonAflacLobPdfPdfExt != "pdf") {
                this.nonAflacLobSelectedCurrent.nonAflacPDFUPloadModalpopup = false;
                this.nonAflacLobSelectedList[lobIndex].showNonAflacLobSuccessMsg = false;
                this.nonAflacLobSelectedList[lobIndex].showNonAflacLobSizeError = false;
                this.nonAflacLobSelectedList[lobIndex].showNonAflacError = true;
                this.$refs.retryFileNonAflacLobPdf.value = null;
                this.$refs.retryFileNonAflacRSPdf.value = null;
                return;
            } else if (Math.ceil(uploadedFile.size / 1024) > this.nonAflacPdfSize) {
                this.nonAflacLobSelectedCurrent.nonAflacPDFUPloadModalpopup = false;
                this.nonAflacLobSelectedList[lobIndex].showNonAflacLobSuccessMsg = false;
                this.nonAflacLobSelectedList[lobIndex].showNonAflacLobSizeError = true;
                this.nonAflacLobSelectedList[lobIndex].showNonAflacError = true;
                this.$refs.retryFileNonAflacLobPdf.value = null;
                this.$refs.retryFileNonAflacRSPdf.value = null;
                return;
            } else {
                this.nonAflacLobSelectedCurrent.nonAflacPDFUPloadModalpopup = false;
                this.nonAflacLobSelectedList[lobIndex].showNonAflacLobSuccessMsg = true;
                this.nonAflacLobSelectedList[lobIndex].showNonAflacLobSizeError = false;
                this.nonAflacLobSelectedList[lobIndex].showNonAflacError = false;
                nonAflacLobPdfPdfExt;
                let file = uploadedFile;
                this.getBase64(file).then(data => {
                    this.uploadCounter++;
                    let base64Value = data.substring(data.indexOf(",") + 1);
                    if (type == this.type.NONAFLAC_RS) {
                        let rateSheetDetails = {};
                        rateSheetDetails = {
                            fileName: uploadedFile.name,
                            base64: base64Value,
                            isRSPdfUpload: true,
                            pdfPath: ""
                        };
                        this.nonAflacLobSelectedList[lobIndex].rateSheetDetails = rateSheetDetails;
                        this.nonAflacLobSelectedList[lobIndex].isRSPdfUpload = true;
                        this.nonAflacLobSelectedList[lobIndex].showNonAflacRSSuccessMsg = true;
                        this.nonAflacLobSelectedList[lobIndex].showNonAflacRSSizeError = false;
                        this.nonAflacLobSelectedList[lobIndex].showNonAflacError = false;
                        this.$refs.retryFileNonAflacRSPdf.value = null;
                    } else {
                        let nonAflacPdfDetailsBse64 = {};
                        nonAflacPdfDetailsBse64 = {
                            fileName: uploadedFile.name,
                            base64: base64Value,
                            isPdfUpload: true,
                            pdfPath: ""
                        };
                        this.nonAflacLobSelectedList[lobIndex].listPdfBase64.push(nonAflacPdfDetailsBse64);
                        this.nonAflacLobSelectedList[lobIndex].isPdfUpload = true;
                        this.nonAflacLobSelectedList[lobIndex].showNonAflacLobSuccessMsg = true;
                        this.nonAflacLobSelectedList[lobIndex].showNonAflacLobSizeError = false;
                        this.nonAflacLobSelectedList[lobIndex].showNonAflacError = false;
                        this.$refs.retryFileNonAflacLobPdf.value = null;
                    }
                    return true;
                });
            }
        },
        closeNonAflacPDFDisclaimer() {
            if (this.micrositeId && this.nonAflacLobSelectedList.length == 0) {
                this.nonAflacDisclaimer = false;
                this.nonAflacLobSelectedCurrent.nonAflacPDFUPloadModalpopup = false;
                this.isPopupFirstOnly = false;
                this.nonAflacLobSelectedList = [];
            } else {
                this.nonAflacDisclaimer = false;
                this.nonAflacLobSelectedCurrent.nonAflacPDFUPloadModalpopup = false;
                this.isPopupFirstOnly = false;
                this.nonAflacLobSelectedList = [];
            }
        },
        selectNonAflacLob(event, naflac) {
            this.nonAflacLobSelectedCurrent.lob = naflac;
            if (!this.isPopupFirstOnly) {
                this.isPopupFirstOnly = true;
                //this.nonAflacLobSelectedCurrent.nonAflacPDFUPloadModalpopup = true;
                const currentLobData = JSON.parse(JSON.stringify(this.nonAflacLobSelectedCurrent));
                this.nonAflacLobSelectedList.push(currentLobData);
            } else {
                let found = this.nonAflacLobSelectedList.findIndex(
                    x => x.lob.Id === this.nonAflacLobSelectedCurrent.lob.Id
                );
                if (found >= 0) {
                    this.nonAflacLobSelectedCurrent.nonAflacPDFUPloadModalpopup = false;
                } else {
                    this.nonAflacLobSelectedCurrent.nonAflacPDFUPloadModalpopup = false;
                    const currentLobData = JSON.parse(JSON.stringify(this.nonAflacLobSelectedCurrent));
                    this.nonAflacLobSelectedList.push(currentLobData);
                }
                //let fileInputElement = this.$refs.retryFileNonAflacLobPdf;
                //fileInputElement.click();
            }
            let fileInputElement = this.$refs.retryFileNonAflacLobPdf;
            fileInputElement.click();
        },
        getNonAflacEmployeeBenefits(id) {
            let url = `/NonAflacEmployee/GetNonAflacBenefitsList`;
            httpClient.get(url).then(result => {
                this.nonAflacLob = result.data.Result;
                this.addCustomFieldsInNonAflacProducts(this.nonAflacLob.NonAflacBenefits);
            });
        },
        viewQRCode(base64) {
            const title = "QR Code";
            //const data = "data:application/pdf;base64," + base64;
            let base64EncodedPDF = base64; // the encoded string
            let decodedPdfContent = atob(base64EncodedPDF);
            let byteArray = new Uint8Array(decodedPdfContent.length);
            for (let i = 0; i < decodedPdfContent.length; i++) {
                byteArray[i] = decodedPdfContent.charCodeAt(i);
            }
            let blob = new Blob([byteArray.buffer], { type: "application/pdf" });
            let _pdfurl = URL.createObjectURL(blob);
            var prntWin = window.open("about:blank");
            prntWin.document.write(
                "<html><head><title>" +
                    title +
                    "</title></head><body>" +
                    '<embed width="100%" height="100%" name="plugin" src="' +
                    _pdfurl +
                    '" ' +
                    'type="application/pdf" internalinstanceid="21"></body></html>'
            );
            prntWin.document.close();
        },
        qrCodeUrl(id) {
            let url = `QRCode/Get?micrositeId=${id}`;
            httpClient.get(url).then(result => {
                this.viewQRCode(result.data.Result);
            });
        },
        getCountLobSelected() {
            let lobCount = 0;
            // find any plad product slected
            if (Array.isArray(this.pladLobData.PladsProducts) && this.pladLobData.PladsProducts.length > 0) {
                if (this.pladLobData.PladsProducts) {
                    this.pladLobData.PladsProducts.forEach(ps => {
                        if (ps.selected) {
                            lobCount++;
                        }
                    });
                }
            }
            // find any LOB having products ( individual or group )
            if (
                Array.isArray(this.displayProducts.lineofBusinesses) &&
                this.displayProducts.lineofBusinesses.length > 0
            ) {
                this.displayProducts.lineofBusinesses.forEach(lob => {
                    if (lob.showDot) {
                        lobCount++;
                    }
                });
            }
            return lobCount > 0 ? true : false;
        },
        resetCheckboxSuggestiveTool() {
            this.siteForm.IsSuggestiveEnable = false;
        },
        checkAnyLevelorRiderSelectedInGroupProductPopup: function() {
            let count = 0;
            let GPCount = 0;
            if (Array.isArray(this.displayProducts.lineofBusinesses)) {
                this.displayProducts.lineofBusinesses.forEach(lob => {
                    count++;
                    if (
                        Object.prototype.hasOwnProperty.call(lob, this.type.GROUP_SERIES) &&
                        lob.productSeriesG.length > 0
                    ) {
                        lob.productSeriesG.forEach(gps => {
                            if (gps.selected) {
                                GPCount++;
                            }
                        });
                    }
                });
                if (count == this.displayProducts.lineofBusinesses.length && GPCount > 0) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        checkAnyLevelorRiderSelectedInProductPopup: function() {
            let count = 0;
            let PCount = 0;
            if (Array.isArray(this.displayProducts.lineofBusinesses)) {
                this.displayProducts.lineofBusinesses.forEach(lob => {
                    count++;
                    if (
                        Object.prototype.hasOwnProperty.call(lob, this.type.INDIVIDUAL_SERIES) &&
                        lob.productSeries.length
                    ) {
                        lob.productSeries.forEach(ps => {
                            if (ps.selected) {
                                PCount++;
                            }
                        });
                    }
                });
                if (count == this.displayProducts.lineofBusinesses.length && PCount > 0) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        disableOtherLobProducts(type, products, lobItemId) {
            const lobIndex = this.getItemIndexByID(products.lineofBusinesses, lobItemId);
            if (type == this.type.AFLAC_SUPPLEMENTAL) {
                const productSeriesSelectedIndividual = products.lineofBusinesses[lobIndex].productSeries;
                if (this.getSelectedCount(productSeriesSelectedIndividual) > 0) {
                    if (
                        Array.isArray(this.displayProducts.lineofBusinesses) &&
                        this.displayProducts.lineofBusinesses.length > 0
                    ) {
                        const productSeries = this.displayProducts.lineofBusinesses[lobIndex].productSeriesG;
                        for (let i = 0; i < productSeries.length; i++) {
                            this.displayProducts.lineofBusinesses[lobIndex].productSeriesG[i].disabled = true;
                        }
                        // spanish group brochure disabling
                        const productSeriesSpanishG = this.displayProducts.lineofBusinesses[lobIndex]
                            .productSeriesSpanishG;
                        if (Array.isArray(productSeriesSpanishG) && productSeriesSpanishG.length > 0) {
                            for (let i = 0; i < productSeriesSpanishG.length; i++) {
                                this.displayProducts.lineofBusinesses[lobIndex].productSeriesSpanishG[
                                    i
                                ].disabled = true;
                            }
                        }
                    }
                }
            } else {
                const lobIndex = this.getItemIndexByID(products.lineofBusinesses, lobItemId);
                let productSeriesSelectedGroup = products.lineofBusinesses[lobIndex].productSeriesG;
                if (this.getSelectedCount(productSeriesSelectedGroup) > 0) {
                    if (
                        Array.isArray(this.displayProducts.lineofBusinesses) &&
                        this.displayProducts.lineofBusinesses.length > 0
                    ) {
                        const productSeries = this.displayProducts.lineofBusinesses[lobIndex].productSeries;
                        for (let i = 0; i < productSeries.length; i++) {
                            this.displayProducts.lineofBusinesses[lobIndex].productSeries[i].disabled = true;
                        }
                        // spanish individual brochure disabling
                        const productSeriesSpanish = this.displayProducts.lineofBusinesses[lobIndex]
                            .productSeriesSpanish;
                        if (Array.isArray(productSeriesSpanish) && productSeriesSpanish.length > 0) {
                            for (let i = 0; i < productSeriesSpanish.length; i++) {
                                this.displayProducts.lineofBusinesses[lobIndex].productSeriesSpanish[i].disabled = true;
                            }
                        }
                    }
                }
            }
        },
        getSelectedCount(items, type) {
            let countGroupSelected = 0;
            if (Array.isArray(items) && items.length > 0) {
                for (let i = 0; i < items.length; i++) {
                    if (items[i].selected) {
                        countGroupSelected++;
                    }
                }
                return countGroupSelected;
            }
        },
        getSelectedProductCount(items) {
            let countGroupSelected = 0;
            let loopCount = 0;
            if (Array.isArray(items) && items.length > 0) {
                for (let i = 0; i < items.length; i++) {
                    loopCount++;
                    if (items[i].ItemId) {
                        if (
                            Object.prototype.hasOwnProperty.call(items[i], this.type.AFLAC_LEVEL) &&
                            Object.keys(items[i]).length > 0
                        ) {
                            for (let k = 0; k < items[i].productLevels.length; k++) {
                                if (items[i].productLevels[k].selected) {
                                    countGroupSelected++;
                                }
                            }
                        }
                        if (
                            Object.prototype.hasOwnProperty.call(items[i], this.type.AFLAC_RIDER) &&
                            Object.keys(items[i]).length > 0
                        ) {
                            for (let l = 0; l < items[i].riders.length; l++) {
                                if (items[i].riders[l].selected) {
                                    countGroupSelected++;
                                }
                            }
                        }
                    }
                }
                return countGroupSelected;
            }
        },
        checkAndEnableProductSectionByLobId(lobId, type) {
            if (type == this.type.AFLAC_SUPPLEMENTAL) {
                const lobIndex = this.getItemIndexByID(this.displayProducts.lineofBusinesses, lobId);
                const productSeriesSelectedIndividual = this.displayProducts.lineofBusinesses[lobIndex].productSeries;
                if (
                    this.getSelectedProductCount(productSeriesSelectedIndividual) == 0 ||
                    this.getSelectedCount(productSeriesSelectedIndividual) == 0
                ) {
                    if (
                        Array.isArray(this.displayProducts.lineofBusinesses) &&
                        this.displayProducts.lineofBusinesses.length > 0
                    ) {
                        const productSeries = this.displayProducts.lineofBusinesses[lobIndex].productSeriesG;
                        for (let i = 0; i < productSeries.length; i++) {
                            this.displayProducts.lineofBusinesses[lobIndex].productSeriesG[i].disabled = false;
                        }
                        // spanish brochure enable - individual(self section)
                        const productSeriesSpanish = this.displayProducts.lineofBusinesses[lobIndex]
                            .productSeriesSpanish;
                        if (Array.isArray(productSeriesSpanish) && productSeriesSpanish.length > 0) {
                            for (let k = 0; k < productSeriesSpanish.length; k++) {
                                this.displayProducts.lineofBusinesses[lobIndex].productSeriesSpanish[
                                    k
                                ].disabled = false;
                            }
                        }
                    }
                }
            }
            if (type == this.type.AFLAC_GROUP) {
                const lobIndex = this.getItemIndexByID(this.displayProducts.lineofBusinesses, lobId);
                let productSeriesSelectedGroup = this.displayProducts.lineofBusinesses[lobIndex].productSeriesG;
                if (
                    this.getSelectedProductCount(productSeriesSelectedGroup) == 0 ||
                    this.getSelectedCount(productSeriesSelectedGroup) == 0
                ) {
                    if (
                        Array.isArray(this.displayProducts.lineofBusinesses) &&
                        this.displayProducts.lineofBusinesses.length > 0
                    ) {
                        const productSeries = this.displayProducts.lineofBusinesses[lobIndex].productSeries;
                        for (let i = 0; i < productSeries.length; i++) {
                            this.displayProducts.lineofBusinesses[lobIndex].productSeries[i].disabled = false;
                        }
                        // spanish brochure enable - Group ( self section)
                        const productSeriesSpanishG = this.displayProducts.lineofBusinesses[lobIndex]
                            .productSeriesSpanishG;
                        if (Array.isArray(productSeriesSpanishG) && productSeriesSpanishG.length > 0) {
                            for (let k = 0; k < productSeriesSpanishG.length; k++) {
                                this.displayProducts.lineofBusinesses[lobIndex].productSeriesSpanishG[
                                    k
                                ].disabled = false;
                            }
                        }
                    }
                }
            }
        },
        // This function used to select group item as single and disable other group items
        checkAndUnselectProductSeriesByLobId(lobId, type) {
            if (type == this.type.AFLAC_SUPPLEMENTAL) {
                const countProductSelected = this.selectedProductSeriesDetail;

                /*
                const lobIndex = this.getItemIndexByID(this.displayProducts.lineofBusinesses, lobId);
                let productSeriesSelectedIndv = this.displayProducts.lineofBusinesses[lobIndex].productSeries;
                if (this.getSelectedCount(productSeriesSelectedIndv) > 0) {
                    if (
                        Array.isArray(this.displayProducts.lineofBusinesses) &&
                        this.displayProducts.lineofBusinesses.length > 0
                    ) {
                        const productSeries = this.displayProducts.lineofBusinesses[lobIndex].productSeries;
                        for (let i = 0; i < productSeries.length; i++) {
                            if (
                                this.selectedProductSeriesDetail.productSeries.ItemId !=
                                this.displayProducts.lineofBusinesses[lobIndex].productSeries[i].ItemId
                            ) {
                                this.displayProducts.lineofBusinesses[lobIndex].productSeries[i].selected = false;
                                if (
                                    Array.isArray(productSeries[i].productLevels) &&
                                    productSeries[i].productLevels.length > 0
                                ) {
                                    for (let k = 0; k < productSeries[i].productLevels.length; k++) {
                                        productSeries[i].productLevels[k].selected = false;
                                    }
                                }
                                if (Array.isArray(productSeries[i].riders) && productSeries[i].riders.length > 0) {
                                    for (let k = 0; k < productSeries[i].riders.length; k++) {
                                        productSeries[i].riders[k].selected = false;
                                    }
                                }
                            }
                        }
                    }
                }*/
                if (
                    Array.isArray(this.displayProducts.lineofBusinesses) &&
                    this.displayProducts.lineofBusinesses.length > 0
                ) {
                    let lobIndex = this.getItemIndexByID(this.displayProducts.lineofBusinesses, lobId);
                    const productSeries = this.displayProducts.lineofBusinesses[lobIndex].productSeriesG;
                    for (let i = 0; i < productSeries.length; i++) {
                        this.displayProducts.lineofBusinesses[lobIndex].productSeriesG[i].disabled = true;
                        this.displayProducts.lineofBusinesses[lobIndex].productSeriesG[i].selected = false;
                        this.displayProducts.lineofBusinesses[lobIndex].productSeriesG[
                            i
                        ].showCustomBrochureSection = false;
                    }
                    // Spanish disabling - Group
                    const productSeriesSpanishG = this.displayProducts.lineofBusinesses[lobIndex].productSeriesSpanishG;
                    if (Array.isArray(productSeriesSpanishG) && productSeriesSpanishG.length > 0) {
                        for (let i = 0; i < productSeriesSpanishG.length; i++) {
                            this.displayProducts.lineofBusinesses[lobIndex].productSeriesSpanishG[i].disabled = true;
                            this.displayProducts.lineofBusinesses[lobIndex].productSeriesSpanishG[i].selected = false;
                            this.displayProducts.lineofBusinesses[lobIndex].productSeriesSpanishG[
                                i
                            ].showCustomBrochureSection = false;
                        }
                    }
                }
            }
            if (type == this.type.AFLAC_GROUP) {
                const lobIndex = this.getItemIndexByID(this.displayProducts.lineofBusinesses, lobId);
                let productSeriesSelectedGroup = this.displayProducts.lineofBusinesses[lobIndex].productSeriesG;
                if (this.getSelectedCount(productSeriesSelectedGroup) > 0) {
                    if (
                        Array.isArray(this.displayProducts.lineofBusinesses) &&
                        this.displayProducts.lineofBusinesses.length > 0
                    ) {
                        const productSeries = this.displayProducts.lineofBusinesses[lobIndex].productSeriesG;
                        for (let i = 0; i < productSeries.length; i++) {
                            if (
                                this.selectedProductSeriesDetail.productSeries.ItemId !=
                                this.displayProducts.lineofBusinesses[lobIndex].productSeriesG[i].ItemId
                            ) {
                                this.displayProducts.lineofBusinesses[lobIndex].productSeriesG[i].selected = false;
                                if (
                                    Array.isArray(productSeries[i].productLevels) &&
                                    productSeries[i].productLevels.length > 0
                                ) {
                                    for (let k = 0; k < productSeries[i].productLevels.length; k++) {
                                        productSeries[i].productLevels[k].selected = false;
                                    }
                                }
                                if (Array.isArray(productSeries[i].riders) && productSeries[i].riders.length > 0) {
                                    for (let k = 0; k < productSeries[i].riders.length; k++) {
                                        productSeries[i].riders[k].selected = false;
                                    }
                                }
                            }
                        }
                    }
                }
                if (
                    Array.isArray(this.displayProducts.lineofBusinesses) &&
                    this.displayProducts.lineofBusinesses.length > 0
                ) {
                    let lobIndex = this.getItemIndexByID(this.displayProducts.lineofBusinesses, lobId);
                    const productSeries = this.displayProducts.lineofBusinesses[lobIndex].productSeries;
                    for (let i = 0; i < productSeries.length; i++) {
                        this.displayProducts.lineofBusinesses[lobIndex].productSeries[i].disabled = true;
                        this.displayProducts.lineofBusinesses[lobIndex].productSeries[i].selected = false;
                        this.displayProducts.lineofBusinesses[lobIndex].productSeries[
                            i
                        ].showCustomBrochureSection = false;
                    }

                    // Spanish disabling - Individual
                    const productSeriesSpanish = this.displayProducts.lineofBusinesses[lobIndex].productSeriesSpanish;
                    if (Array.isArray(productSeriesSpanish) && productSeriesSpanish.length > 0) {
                        for (let i = 0; i < productSeriesSpanish.length; i++) {
                            this.displayProducts.lineofBusinesses[lobIndex].productSeriesSpanish[i].disabled = true;
                            this.displayProducts.lineofBusinesses[lobIndex].productSeriesSpanish[i].selected = false;
                            this.displayProducts.lineofBusinesses[lobIndex].productSeriesSpanish[
                                i
                            ].showCustomBrochureSection = false;
                        }
                    }
                }
            }
        },
        resetRemoveProducts(type) {
            // group brochure code
            if (!this.siteForm.groupSelection && type == this.type.AFLAC_GROUP) {
                this.groupProducts = {};
                this.siteForm.groupState = "";
            } else if (!this.siteForm.individualSelection && type == this.type.AFLAC_SUPPLEMENTAL) {
                this.individualProducts = {};
            }
        },
        viewFile(lobId, type = this.type.AFLAC_SUPPLEMENTAL, title) {
            const lobIndex = this.getItemIndexByID(this.displayProducts.lineofBusinesses, lobId);
            let productSeriesSelected =
                type == this.type.AFLAC_SUPPLEMENTAL
                    ? this.displayProducts.lineofBusinesses[lobIndex].productSeries
                    : this.displayProducts.lineofBusinesses[lobIndex].productSeriesG;
            let customBrochurePdfBase64 =
                productSeriesSelected[productSeriesSelected.length - 1].customBrochurePdfBase64;
            /* let customBrochurePdfBase64 =
                "data:application/pdf;base64," +
                productSeriesSelected[productSeriesSelected.length - 1].customBrochurePdfBase64; */
            let customBrochurePdfPath = productSeriesSelected[productSeriesSelected.length - 1].customBrochurePdfPath;
            let isCustomPdfUpload = productSeriesSelected[productSeriesSelected.length - 1].isCustomPdfUpload;
            if (this.micrositeId && customBrochurePdfPath) {
                const customBrochurePdfUrl = customBrochurePdfPath + "?ts=" + currentTime;
                let pdfWindow = window.open();
                pdfWindow.document.write(
                    "<html><head>" +
                        '<meta http-equiv="Cache-Control" content="no-store" />' +
                        "<title>" +
                        title +
                        "</title></head><body>" +
                        '<embed width="100%" height="100%" name="plugin" src="' +
                        customBrochurePdfUrl +
                        '" ' +
                        'type="application/pdf" internalinstanceid="21"></body></html>'
                );
            } else {
                this.viewBase64AsUrl(customBrochurePdfBase64, title);
            }
        },
        retryBrowsePdf($event, lob, productSeries, type) {
            this.type.GROUP_SELECTED = type;
            this.selectedProductSeriesDetail.lineOfBusiness = lob;
            this.selectedProductSeriesDetail.productSeries = productSeries;
            let fileInputElement = this.$refs.retryFileCustomPdf;
            fileInputElement.click();
            //$refs.retryFileCustomPdf.click()
        },
        onCustomBrochureUpload() {
            this.selectedProductSeriesDetail.productSeries.selected = true;
            let uploadedFile = this.$refs.retryFileCustomPdf.files[0];
            this._processPdfUpload(uploadedFile);
        },
        uploadPdf(event, lobId, type) {
            // allow single selection for Group section
            this.checkAndUnselectProductSeriesByLobId(lobId, type);
            this.showPDFUPloadModalpopup = false;
            this.showPDFUPloadModalpopupSpanish = false;
            let uploadedFile = this.$refs.fileCustomPdf.files[0];
            this._processPdfUpload(uploadedFile);
        },
        _processPdfUpload(uploadedFile) {
            const pdfFileSizeLimit =
                this.type.GROUP_SELECTED === this.type.AFLAC_SUPPLEMENTAL ? this.customIndvPdfSize : this.customPdfSize;
            this.customBrochurePdfExt = uploadedFile.name
                .split(".")
                .pop()
                .toLowerCase();
            if (this.customBrochurePdfExt != "pdf") {
                this.selectedProductSeriesDetail.productSeries.showCustomBrochureSection = true;
                this.selectedProductSeriesDetail.productSeries.isCustomPdfPreviewErr = true;
                this.selectedProductSeriesDetail.productSeries.isPdfFileInvalid = true;
                this.$refs.retryFileCustomPdf.value = null;
                return;
            } else if (Math.ceil(uploadedFile.size / 1024) > pdfFileSizeLimit) {
                this.selectedProductSeriesDetail.productSeries.showCustomBrochureSection = true;
                this.selectedProductSeriesDetail.productSeries.isCustomPdfPreviewErr = true;
                this.selectedProductSeriesDetail.productSeries.isPdfFileInvalid = false;
                this.$refs.retryFileCustomPdf.value = null;
                return;
            } else {
                this.selectedProductSeriesDetail.productSeries.showCustomBrochureSection = true;
                this.selectedProductSeriesDetail.productSeries.isCustomPdfPreviewErr = false;
                this.selectedProductSeriesDetail.productSeries.customBrochurePdfName = uploadedFile.name;
                this.customBrochurePdf = uploadedFile;
                let file = uploadedFile;
                this.getBase64(file).then(data => {
                    this.selectedProductSeriesDetail.productSeries.customBrochurePdfBase64 = data.substring(
                        data.indexOf(",") + 1
                    );
                    //this.selectedProductSeriesDetail.lineOfBusiness.showDot = true;
                    this.selectedProductSeriesDetail.productSeries.isCustomPdfUpload = true;
                    this.updateBlueDotandDisableLOB(true, "pdf");
                    this.selectedProductSeriesDetail.productSeries.customBrochurePdfPath = "";
                    this.$refs.retryFileCustomPdf.value = null;
                    return true;
                });
            }
        },
        removePdf(lobId, isOnCheck = true) {
            const lobIndex = this.getItemIndexByID(this.displayProducts.lineofBusinesses, lobId);
            let productSeriesSelectedGroup =
                this.type.GROUP_SELECTED == this.type.AFLAC_SUPPLEMENTAL
                    ? this.displayProducts.lineofBusinesses[lobIndex].productSeries
                    : this.displayProducts.lineofBusinesses[lobIndex].productSeriesG;
            const lastItem = productSeriesSelectedGroup[productSeriesSelectedGroup.length - 1];
            if (
                //!this.selectedProductSeriesDetail.productSeries.selected &&
                !this.isCurrentPdfSelfRemove &&
                lastItem &&
                this.selectedProductSeriesDetail.productSeries &&
                this.selectedProductSeriesDetail.productSeries.ItemId != "" &&
                lastItem.ItemId != this.selectedProductSeriesDetail.productSeries.ItemId
            ) {
                productSeriesSelectedGroup[productSeriesSelectedGroup.length - 1].showCustomBrochureSection = false;
                productSeriesSelectedGroup[productSeriesSelectedGroup.length - 1].disclaimer = false;
                productSeriesSelectedGroup[productSeriesSelectedGroup.length - 1].selected = false;
                productSeriesSelectedGroup[productSeriesSelectedGroup.length - 1].customBrochurePdfName = "";
                productSeriesSelectedGroup[productSeriesSelectedGroup.length - 1].customBrochurePdfBase64 = "";
                productSeriesSelectedGroup[productSeriesSelectedGroup.length - 1].isCustomPdfUpload = false;
                this.showProductModal = isOnCheck == true;
            } else {
                productSeriesSelectedGroup[productSeriesSelectedGroup.length - 1].showCustomBrochureSection = false;
                productSeriesSelectedGroup[productSeriesSelectedGroup.length - 1].disclaimer = false;
                productSeriesSelectedGroup[productSeriesSelectedGroup.length - 1].selected = false;
                productSeriesSelectedGroup[productSeriesSelectedGroup.length - 1].customBrochurePdfName = "";
                productSeriesSelectedGroup[productSeriesSelectedGroup.length - 1].customBrochurePdfBase64 = "";
                productSeriesSelectedGroup[productSeriesSelectedGroup.length - 1].isCustomPdfUpload = false;
            }

            this.customBrochurePdf = "";
            this.removeCustomGroupPdtBropopup = false;
            this.CustomPdfUPloadModal = false;
            //this.updateBlueDotandDisableLOB(false,'pdf');
            this.displayProducts.lineofBusinesses[lobIndex].showDot = false;
            this.checkAndEnableProductSectionByLobId(lobId, this.type.GROUP_SELECTED);
            // remove spanish brochure
            //this.removeSpnBrochurePdf(lobId, this.type.GROUP_SELECTED);
            this.removeSpnBrochurePdf(lobId);
        },
        cancelRemovePDFConfirm(lobId) {
            const lobIndex = this.getItemIndexByID(this.displayProducts.lineofBusinesses, lobId);
            this.removeCustomGroupPdtBropopup = false;
            this.onProductModalCancel("product");
        },
        removePDFConfirm(lobId, type, isCurrentPdfSelfRemove = false) {
            this.currentLobIdToRemove = lobId;
            this.isCurrentPdfSelfRemove = isCurrentPdfSelfRemove;
            this.removeCustomGroupPdtBropopup = true;
            this.type.GROUP_SELECTED =
                type === this.type.AFLAC_SUPPLEMENTAL ? this.type.AFLAC_SUPPLEMENTAL : this.type.AFLAC_GROUP;
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        cropImage() {
            this.checkDirty = "dirty";
            let fileInputObj = document.getElementById("files");
            let filePathName = fileInputObj.value;
            const actualFileSize = fileInputObj.files[0].size;
            this.clientLogoImageName = filePathName
                .split("\\")
                .pop()
                .toLowerCase();
            this.clientLogoImageExt = filePathName
                .split(".")
                .pop()
                .toLowerCase();
            this.showClientLogoWrapper = false;
            this.isClientLogoPreview = true;
            this.$refs.fileClientLogo.value = null;
            this.clientLogoEditMode = false;
            const result = this.$refs.cropper.getResult();
            let base64Image = result.canvas
                .toDataURL("image/png")
                .replace("image/png", `image/${this.clientLogoImageExt}`);
            const buffer = Buffer.from(base64Image.substring(base64Image.indexOf(",") + 1));
            if (actualFileSize > 1024 * this.clientLogoSize && buffer.length > 1024 * this.clientLogoSize) {
                this.clientLogoImage = "";
                this.clientLogoImageExt = "";
                this.clientLogoImageOld = this.clientLogoImage;
                this.isClientLogoPreviewErr = true;
                this.isClientLogoInvalid = false;
            } else {
                this.clientLogoImage = base64Image;
                this.clientLogoImageOld = this.clientLogoImage;
                this.isClientLogoPreviewErr = false;
            }
        },
        uploadImage(event) {
            const fileInput = document.getElementById("files");
            const filePath = fileInput.value;
            const allowedExtensionsArr = this.clientLogoExtension.split(",");
            const fileExtension = filePath.split(".").pop();
            if (!allowedExtensionsArr.includes(fileExtension.toLowerCase())) {
                this.isClientLogoPreview = true;
                this.clientLogoImage = "";
                this.clientLogoImageExt = "";
                this.clientLogoImageOld = this.clientLogoImage;
                this.isClientLogoPreviewErr = true;
                fileInput.value = "";
                this.isClientLogoInvalid = true;
                return false;
            } else {
                this.showClientLogoWrapper = true;
                var input = event.target;
                // Ensure that you have a file before attempting to read it
                if (input.files && input.files[0]) {
                    // create a new FileReader to read this image and convert to base64 format
                    var reader = new FileReader();
                    // Define a callback function to run, when FileReader finishes its job
                    reader.onload = e => {
                        // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
                        // Read image as base64 and set to imageData
                        this.clientLogoImage = e.target.result;
                    };
                    // Start the reader job - read file as a data url (base64 format)
                    reader.readAsDataURL(input.files[0]);
                } else {
                    this.showClientLogoWrapper = false;
                }
            }
        },
        uploadImageBrowse(event) {
            let fileInputElement = this.$refs.fileClientLogo;
            fileInputElement.click();
        },
        rotate(angle) {
            this.$refs.cropper.rotate(angle);
        },
        resetCropper() {
            //this.$refs.cropper.reset();
            this.$refs.cropper.refresh();
            this.$refs.fileClientLogo.value = null;
            this.isClientLogoInvalid = this.isClientLogoInvalid == false ? false : true;
            this.showClientLogoWrapper = false;
            this.isRemoveClientLogoModalShow = false;
            this.clientLogoImage = this.clientLogoImageOld;
        },
        removeClientLogo(isEditMode) {
            if (!isEditMode) {
                this.checkDirty = "dirty";
                this.$refs.cropper.reset();
                this.$refs.cropper.refresh();
            }

            this.$refs.fileClientLogo.value = null;
            this.showClientLogoWrapper = false;
            this.isClientLogoPreview = false;
            this.isRemoveClientLogoModalShow = false;
            this.clientLogoImage = "";
            this.clientLogoImageOld = "";
            this.isLogoRemoved = isEditMode == 1 || !this.ClientImagePath ? false : true;
        },
        removeConfirmClientLogo() {
            this.isRemoveClientLogoModalShow = true;
        },
        cancelClientLogo() {
            this.isClientLogoPreview = this.clientLogoImageOld == "" ? false : true;
            this.isRemoveClientLogoModalShow = false;
            this.clientLogoImage = this.clientLogoImageOld;
        },
        dirty: function() {
            let isFromDirty = false;
            for (let prop in this.siteForm) {
                if (this.siteForm[prop] !== this.initValue[prop]) {
                    isFromDirty = true;
                }
            }
            if (JSON.stringify(this.initDisplayProducts) != JSON.stringify(this.displayProducts)) {
                isFromDirty = true;
            }
            return isFromDirty;
        },
        loadData: function() {
            this.getEnrollmentVideos();
            this.getNonAflacEmployeeBenefits();
            this.getStates().then(() => {
                if (this.micrositeId) {
                    this.fetchMicrositeDetail();
                    //this.getGroupStates();
                }
            });

            this.getGroupStates().then(() => {
                /*if (this.micrositeId) {
                    this.fetchMicrositeDetail();
                }*/
            });
        },
        // focusout:function(id){
        //     alert(id);
        //     $('#'+id).eq(1).addClass('invalid-danger');
        // },

        fetchMicrositeDetail: function() {
            // var oldURL = document.referrer;
            // let splitvalue = oldURL.split("/").pop();
            let data = {
                UserName: this.loggedInUserDetail.EmailAddress,
                MicrositeId: this.micrositeId,
                // IsPreviewMode: splitvalue.toLowerCase() == "account" ? false : true
                IsPreviewMode: false
            };
            //let url = `Microsite/GetMicrositeDetails?UserName=${this.loggedInUserDetail.EmailAddress}&MicrositeId=${this.micrositeId}&IsPreviewMode=${data.IsPreviewMode}`;
            let url = "Microsite/GetMicrositeDetails";
            httpClient.post(url, data).then(result => {
                //httpClient.get(url).then(result => {
                this.micrositeDetail = result.data;
                // one-disclaimer
                this.saveAndPublishButton = true;
                this.disclaimerCloseButton = true;
                // client logo upload
                this.isClientLogoPreview = true;
                this.clientLogoEditMode = true;
                this.clientLogoImage = this.micrositeDetail.ClientImagePath + "?" + new Date().getTime();
                if (!this.micrositeDetail.ClientImagePath) {
                    let ClientLogoSection = document.getElementById("ClientLogoSection");
                    if (ClientLogoSection != null) {
                        this.removeClientLogo(1);
                    }
                }
                this.ClientImagePath = this.micrositeDetail.ClientImagePath;
                this.clientLogoImageOld = this.micrositeDetail.ClientImagePath + "?" + new Date().getTime();
                this.clientImageId = this.micrositeDetail.ClientImageID;

                //  Brochure for PLAD product
                if (this.micrositeDetail.PladsPdfPath) {
                    let PladPdfPathArr = this.micrositeDetail.PladsPdfPath.split(":");
                    let pladPdfFilePath = PladPdfPathArr[1];
                }

                //"CustomPdfPath": "AI:/-/medialibrary/pdf/Test.pdf:Test|CA:/-/medialibrary/pdf/Test1.pdf:Test"
                if (this.micrositeDetail.CustomPdfPath) {
                    let CustomPdfPathArr = this.micrositeDetail.CustomPdfPath.split(":");
                    let customPdfFilePath = CustomPdfPathArr[1];
                }
                // Custom Brochure for Individual
                if (this.micrositeDetail.CustomIndividualPdfPath) {
                    let CustomIndvPdfPathArr = this.micrositeDetail.CustomIndividualPdfPath.split(":");
                    let customIndvPdfFilePath = CustomIndvPdfPathArr[1];
                }

                // Products Individusal / group brochure
                this.siteForm.individualSelection = this.micrositeDetail.IndividualSelection == "True" ? true : false;
                let pdfUploadSection = document.getElementById("custom-select-group");
                if (pdfUploadSection != null) {
                    this.siteForm.groupSelection = this.micrositeDetail.GroupSelection == "True" ? true : false;
                    this.productResetPopupData.resetGroupState = true;
                    this.siteForm.groupState = this.getGroupStateByName(this.micrositeDetail.GroupState);
                }
                this.siteForm.hideAgent = this.micrositeDetail.HideAgent == "True";
                this.siteForm.hideEnrollDate = this.micrositeDetail.HideEnrollDate == "True";
                //this.siteForm.offerGroup = true;
                //this.clientLogoImageExt = this.micrositeDetail.ClientImageExtension;
                //this.clientLogoImageExtOld = this.micrositeDetail.ClientImageExtension;
                this.siteForm.micrositeUrl = environment.enrollmentDomain + this.micrositeDetail.PublishUrl;
                this.siteForm.organizationName = this.micrositeDetail.OrganizationName;
                this.siteForm.IsSuggestiveEnable = this.micrositeDetail.IsSuggestiveEnable == "True";
                // to prevent state change watcher.
                //this.productResetPopupData.resetState = true;
                this.productResetPopupData.resetOrganizationState = true;

                this.siteForm.organizationState = this.getStateByName(this.micrositeDetail.OrganizationState);
                this.siteForm.contactName = this.micrositeDetail.ContactName;
                this.siteForm.contactEmail = this.micrositeDetail.ContactEmail;
                this.siteForm.contactNumber = this.micrositeDetail.ContactNumber;

                this.siteForm.enrollmentDateFrom = this.micrositeDetail.EnrollmentStartDate;
                this.siteForm.enrollmentDateTo = this.micrositeDetail.EnrollmentEndDate;
                this.siteForm.liveSite = this.micrositeDetail.IsLiveDateChecked == "True";
                this.siteForm.liveDateFrom = this.micrositeDetail.LiveStartDate;
                this.siteForm.liveDateTo = this.micrositeDetail.LiveEndDate;

                this.enrollmentDatePickerStart.setDate(new Date(this.micrositeDetail.EnrollmentStartDate), true);
                this.enrollmentDatePickerEnd.setDate(new Date(this.micrositeDetail.EnrollmentEndDate), true);

                this.liveDatePickerStart.setDate(new Date(this.micrositeDetail.LiveStartDate), true);
                this.liveDatePickerEnd.setDate(new Date(this.micrositeDetail.LiveEndDate), true);

                this.siteForm.accountId = this.micrositeDetail.AccountId;
                if (this.micrositeDetail.SchedulingPhoneNo && this.isPilotMode) {
                    this.siteForm.schedulingPhoneNo = this.micrositeDetail.SchedulingPhoneNo;
                    this.acceptschedulingPhoneNo("schedulingPhoneNo");
                } else if (this.micrositeDetail.SchedulingEnrollUrl && this.isPilotMode) {
                    this.siteForm.schedulingEnrollUrl = this.micrositeDetail.SchedulingEnrollUrl;
                    this.trackEnrollURL("schedulingEnrollUrl");
                } else if (this.micrositeDetail.SchedulingLink) {
                    this.siteForm.calenderLink = this.micrositeDetail.SchedulingLink;
                    if (this.isPilotMode) {
                        this.tracklinkfield("calenderLink");
                    }
                } else {
                    this.siteForm.calenderLink = "";
                    if (this.isPilotMode) {
                        this.tracklinkfield("calenderLink");
                    }
                }

                // PLAD Brochure
                if (this.micrositeDetail.PladsPdfPath) {
                    this.siteForm.pladSelection = true;
                    this.getPlads().then(pladData => {
                        let fileObj = {};
                        let selectedPladPdfData = this.micrositeDetail.PladsPdfPath.split("|");
                        if (Array.isArray(pladData.PladsProducts) && pladData.PladsProducts.length > 0) {
                            selectedPladPdfData.forEach(async data => {
                                let dataArr = data.split(":");
                                const lobIndex = this.getPladLobIndex(pladData.PladsProducts, dataArr[0]);
                                let url = this.isMediaUrl(dataArr[1]) ? dataArr[1] : "https:" + dataArr[2];
                                let base64Data = await getBase64FromUrl(url).then(data => data);
                                fileObj = {
                                    base64: base64Data.substring(base64Data.indexOf(",") + 1),
                                    pdfPath: url,
                                    fileName: (this.isMediaUrl(dataArr[1]) ? dataArr[2] : dataArr[3]) + ".pdf",
                                    isPdfUpload: false
                                };
                                this.pladLobData.PladsProducts[lobIndex].pdfDetails = fileObj;
                                this.pladLobData.PladsProducts[lobIndex].selected = true;
                                this.pladLobData.PladsProducts[lobIndex].showPladBrochureSection = true;
                            });
                        }
                    });
                }

                if (this.micrositeDetail.CustomPdfPath) {
                    let cutomPDfLobSelectedArr = [];
                    let cutomPDfLobSelectedBase64Obj = {};
                    let selectedCustomPdfData = this.micrositeDetail.CustomPdfPath.split("|");
                    selectedCustomPdfData.forEach(async data => {
                        let lobSNArr = data.split(":");
                        //let lobObj = {'LSN' : lobSNArr[0], 'path':lobSNArr[1], fileName:lobSNArr[2] }
                        cutomPDfLobSelectedArr.push(lobSNArr[0]);
                        let base64Data = await getBase64FromUrl(lobSNArr[1]).then(data => data);
                        cutomPDfLobSelectedBase64Obj[lobSNArr[0]] = {
                            base64: base64Data.substring(base64Data.indexOf(",") + 1),
                            name: lobSNArr[2]
                        };
                    });
                    this.cutomPDfLobSelected = cutomPDfLobSelectedArr;
                    this.cutomPDfLobSelectedBase64 = cutomPDfLobSelectedBase64Obj;
                }

                // Custom Brochure for Individual
                if (this.micrositeDetail.CustomIndividualPdfPath) {
                    let cutomIndvPDfLobSelectedArr = [];
                    let cutomIndvPDfLobSelectedBase64Obj = {};
                    let selectedCustomPdfData = this.micrositeDetail.CustomIndividualPdfPath.split("|");
                    selectedCustomPdfData.forEach(async data => {
                        let lobSNArr = data.split(":");
                        //let lobObj = {'LSN' : lobSNArr[0], 'path':lobSNArr[1], fileName:lobSNArr[2] }
                        cutomIndvPDfLobSelectedArr.push(lobSNArr[0]);
                        let base64Data = await getBase64FromUrl(lobSNArr[1]).then(data => data);
                        cutomIndvPDfLobSelectedBase64Obj[lobSNArr[0]] = {
                            base64: base64Data.substring(base64Data.indexOf(",") + 1),
                            name: lobSNArr[2]
                        };
                    });
                    this.cutomIndvPDfLobSelected = cutomIndvPDfLobSelectedArr;
                    this.cutomIndvPDfLobSelectedBase64 = cutomIndvPDfLobSelectedBase64Obj;
                }

                /**
                 * Aflac Rate Sheet:pdf file
                 */
                if (this.micrositeDetail.AflacRSPdfPath) {
                    let fileObj = {};
                    let selectedAflacRSPdfData = this.micrositeDetail.AflacRSPdfPath.split("|");
                    selectedAflacRSPdfData.forEach(data => {
                        this.isAflacRSDisclaimerFirstOnly = true;
                        let dataArr = data.split(":");
                        let url = this.isMediaUrl(dataArr[1]) ? dataArr[1] : "https:" + dataArr[2];
                        fileObj = {
                            base64: "",
                            pdfPath: url,
                            fileName: (this.isMediaUrl(dataArr[1]) ? dataArr[2] : dataArr[3]) + ".pdf",
                            isPdfUpload: false
                        };
                        this.aflacRSPDfSelectedObj[dataArr[0]] = fileObj;
                    });
                }
                /**
                 * Non-Aflac Rate Sheet:pdf file
                 */
                if (this.micrositeDetail.NonAflacRSPdfPath) {
                    let fileObj = {};
                    let selectedNonAflacRSPdfData = this.micrositeDetail.NonAflacRSPdfPath.split("|");
                    selectedNonAflacRSPdfData.forEach(async data => {
                        let dataArr = data.split(":");
                        //let lobObj = {'LSN' : dataArr[0], 'path':dataArr[1], fileName:dataArr[2] }
                        let url = this.isMediaUrl(dataArr[1]) ? dataArr[1] : "https:" + dataArr[2];
                        fileObj = {
                            base64: "",
                            pdfPath: url,
                            fileName: (this.isMediaUrl(dataArr[1]) ? dataArr[2] : dataArr[3]) + ".pdf",
                            isPdfUpload: false
                        };
                        this.nonAflacRSPDfSelectedObj[dataArr[0]] = fileObj;
                    });
                }
                /**
                 * Non-Aflac Employee Benefits:pdf files
                 */
                if (this.micrositeDetail.NonAflacPdfPath) {
                    let nonAflacRSPDfSelectedObj = this.nonAflacRSPDfSelectedObj;
                    let lobIndex;
                    this.isPopupFirstOnly = true;
                    let selectedNonAflacPdfData = this.micrositeDetail.NonAflacPdfPath.split("|");
                    selectedNonAflacPdfData.forEach(data => {
                        let fileObj = {};
                        let nonAflacLobSelectedCurrent = JSON.parse(JSON.stringify(this.nonAflacLobSelectedCurrent));
                        let dataArr = data.split(":");
                        let url = this.isMediaUrl(dataArr[1]) ? dataArr[1] : "https:" + dataArr[2];
                        let lobData = this.getNonAflacLobItemFromList(this.nonAflacLob, dataArr[0]);
                        nonAflacLobSelectedCurrent.showNonAflacLobSuccessMsg = true;
                        nonAflacLobSelectedCurrent.lob = lobData;
                        lobIndex = this.getNonAflacLobIndex(this.nonAflacLobSelectedList, dataArr[0]);
                        if (lobIndex == -1) {
                            getBase64FromUrl(url).then(base64Data => {
                                fileObj = {
                                    base64: base64Data.substring(base64Data.indexOf(",") + 1),
                                    fileName: (this.isMediaUrl(dataArr[1]) ? dataArr[2] : dataArr[3]) + ".pdf",
                                    isPdfUpload: false
                                };
                                nonAflacLobSelectedCurrent.listPdfBase64.push(fileObj);
                                let lobId = nonAflacLobSelectedCurrent.lob.Id;
                                if (
                                    Object.keys(nonAflacRSPDfSelectedObj).length > 0 &&
                                    Object.keys(nonAflacRSPDfSelectedObj).includes(lobId)
                                ) {
                                    let rateSheetDetailsObj = this.nonAflacRSPDfSelectedObj[lobId];
                                    getBase64FromUrl(rateSheetDetailsObj["pdfPath"]).then(base64DataRS => {
                                        rateSheetDetailsObj["base64"] = base64DataRS.substring(
                                            base64DataRS.indexOf(",") + 1
                                        );
                                    });
                                    nonAflacLobSelectedCurrent.rateSheetDetails = rateSheetDetailsObj;
                                    nonAflacLobSelectedCurrent.showNonAflacRSSuccessMsg = true;
                                    nonAflacLobSelectedCurrent.isRSPdfUpload = true;
                                }
                            });

                            this.nonAflacLobSelectedList.push(nonAflacLobSelectedCurrent);
                        } else {
                            let lobIndexInner = lobIndex;
                            getBase64FromUrl(url).then(base64Data => {
                                fileObj = {
                                    base64: base64Data.substring(base64Data.indexOf(",") + 1),
                                    fileName: (this.isMediaUrl(dataArr[1]) ? dataArr[2] : dataArr[3]) + ".pdf",
                                    isPdfUpload: false
                                };
                                this.nonAflacLobSelectedList[lobIndexInner].listPdfBase64.push(fileObj);
                            });
                            this.nonAflacLobSelectedList[lobIndex].showNonAflacLobSuccessMsg = true;
                        }
                    });
                }

                // Custom Brochure for Individual Spanish
                if (this.micrositeDetail.CustomIndividualSpanishPdfPath) {
                    let cutomIndvSpnPdfLobSelectedArr = [];
                    let cutomIndvSpnPdfLobSelectedBase64Obj = {};
                    let selectedCustomPdfData = this.micrositeDetail.CustomIndividualSpanishPdfPath.split("|");
                    selectedCustomPdfData.forEach(async data => {
                        let lobSNArr = data.split(":");
                        //let lobObj = {'LSN' : lobSNArr[0], 'path':lobSNArr[1], fileName:lobSNArr[2] }
                        cutomIndvSpnPdfLobSelectedArr.push(lobSNArr[0]);
                        let base64Data = await getBase64FromUrl(lobSNArr[1]).then(data => data);
                        cutomIndvSpnPdfLobSelectedBase64Obj[lobSNArr[0]] = {
                            base64: base64Data.substring(base64Data.indexOf(",") + 1),
                            name: lobSNArr[2]
                        };
                    });
                    this.cutomIndvSpnPdfLobSelected = cutomIndvSpnPdfLobSelectedArr;
                    this.cutomIndvSpnPdfLobSelectedBase64 = cutomIndvSpnPdfLobSelectedBase64Obj;
                }

                // Custom Brochure for Group Spanish
                if (this.micrositeDetail.CustomSpanishPdfPath) {
                    let cutomGrpSpnPdfLobSelectedArr = [];
                    let cutomGrpSpnPdfLobSelectedBase64Obj = {};
                    let selectedCustomPdfData = this.micrositeDetail.CustomSpanishPdfPath.split("|");
                    selectedCustomPdfData.forEach(async data => {
                        let dataArr = data.split(":");
                        //let lobObj = {'LSN' : lobSNArr[0], 'path':lobSNArr[1], fileName:lobSNArr[2] }
                        cutomGrpSpnPdfLobSelectedArr.push(dataArr[0]);
                        let url = this.isMediaUrl(dataArr[1]) ? dataArr[1] : "https:" + dataArr[2];
                        let base64Data = await getBase64FromUrl(url).then(data => data);
                        cutomGrpSpnPdfLobSelectedBase64Obj[dataArr[0]] = {
                            base64: base64Data.substring(base64Data.indexOf(",") + 1),
                            name: (this.isMediaUrl(dataArr[1]) ? dataArr[2] : dataArr[3]) + ".pdf"
                        };
                    });
                    this.cutomGrpSpnPdfLobSelected = cutomGrpSpnPdfLobSelectedArr;
                    this.cutomGrpSpnPdfLobSelectedBase64 = cutomGrpSpnPdfLobSelectedBase64Obj;
                }

                const productPromises = [];
                if (
                    this.siteForm.individualSelection &&
                    this.micrositeDetail.IndividualSelection == "True" &&
                    this.micrositeDetail.OrganizationState
                ) {
                    productPromises.push(this.getProduct(this.type.AFLAC_SUPPLEMENTAL));
                }
                if (
                    this.siteForm.groupSelection &&
                    this.micrositeDetail.GroupSelection == "True" &&
                    this.micrositeDetail.GroupState
                ) {
                    productPromises.push(this.getProduct(this.type.AFLAC_GROUP));
                }
                //let p1 = this.getProduct(this.type.AFLAC_SUPPLEMENTAL);
                //let p2 = this.getProduct(this.type.AFLAC_GROUP);
                Promise.all(productPromises).then(products => {
                    this.setLobAflacRSDetails();
                    this.setLobAflacRSBase64();
                    this.selectProduct(this.micrositeDetail.SelectedProductList);
                    this.selectLobCustomGroupPdf(this.displayProducts, this.type.AFLAC_SUPPLEMENTAL);
                    let pdfUploadSection = document.getElementById("custom-select-group");
                    if (pdfUploadSection != null) {
                        this.selectLobCustomGroupPdf(this.displayProducts, this.type.AFLAC_GROUP);
                        this.selectProductGroup(this.micrositeDetail.SelectedProductList);
                    }

                    this.checkDirty = "pristine";
                    if (products.length > 0) {
                        const lineofBusinesses = this.displayProducts.lineofBusinesses;
                        if (Array.isArray(lineofBusinesses) && lineofBusinesses.length > 0) {
                            for (let i = 0; i < lineofBusinesses.length; i++) {
                                const productSeriesInd = this.displayProducts.lineofBusinesses[i].productSeries;
                                if (Array.isArray(productSeriesInd) && productSeriesInd.length > 0) {
                                    for (let j = 0; j < productSeriesInd.length; j++) {
                                        this.disableOtherLobProducts(
                                            this.type.AFLAC_SUPPLEMENTAL,
                                            this.displayProducts,
                                            this.displayProducts.lineofBusinesses[i].ItemId
                                        );
                                    }
                                }

                                const productSeriesGrp = this.displayProducts.lineofBusinesses[i].productSeriesG;
                                if (Array.isArray(productSeriesGrp) && productSeriesGrp.length > 0) {
                                    for (let j = 0; j < productSeriesGrp.length; j++) {
                                        //this.individualProducts.lineofBusinesses[i].productSeries[j].disabled = true;
                                        this.disableOtherLobProducts(
                                            this.type.AFLAC_GROUP,
                                            this.displayProducts,
                                            this.displayProducts.lineofBusinesses[i].ItemId
                                        );
                                    }
                                }
                            }
                        }
                    }
                    this.initDisplayProducts = JSON.parse(JSON.stringify(this.displayProducts));
                });
                // to format contact number.
                this.acceptNumber();
                this.initValue = { ...this.siteForm };
            });
        },
        /**
         * Function used to select Custom Brochure for LOB's
         * @param {*} products: Array
         * @param {*} type: String
         */
        selectLobCustomGroupPdf(products, type) {
            let typeOfSeries = "";
            let cutomPDfLobSelected = [];
            let CustomPdfPath = "";
            // for Spanish pdf
            let typeOfSeriesSpanish = "";
            let cutomSpanishPdfLobSelected = [];
            let CustomSpanishPdfPath = "";
            if (type == this.type.AFLAC_GROUP) {
                typeOfSeries = this.type.GROUP_SERIES;
                cutomPDfLobSelected = this.cutomPDfLobSelected;
                CustomPdfPath = this.micrositeDetail.CustomPdfPath;
                /** spanish pdf */
                typeOfSeriesSpanish = this.type.GROUP_SERIES_SPANISH;
                cutomSpanishPdfLobSelected = this.cutomGrpSpnPdfLobSelected;
                CustomSpanishPdfPath = this.micrositeDetail.CustomSpanishPdfPath;
            } else {
                typeOfSeries = this.type.INDIVIDUAL_SERIES;
                cutomPDfLobSelected = this.cutomIndvPDfLobSelected;
                CustomPdfPath = this.micrositeDetail.CustomIndividualPdfPath;
                /** spanish pdf */
                typeOfSeriesSpanish = this.type.INDIVIDUAL_SERIES_SPANISH;
                cutomSpanishPdfLobSelected = this.cutomIndvSpnPdfLobSelected;
                CustomSpanishPdfPath = this.micrositeDetail.CustomIndividualSpanishPdfPath;
            }

            if (Array.isArray(products.lineofBusinesses) && products.lineofBusinesses.length > 0) {
                for (let i = 0; i < products.lineofBusinesses.length; i++) {
                    let lineofBusiness = products.lineofBusinesses[i];
                    if (
                        !cutomPDfLobSelected.includes(lineofBusiness.LOBShortName) &&
                        !cutomSpanishPdfLobSelected.includes(lineofBusiness.LOBShortName)
                    ) {
                        continue;
                    }

                    if (cutomPDfLobSelected && cutomPDfLobSelected.includes(lineofBusiness.LOBShortName)) {
                        let productSeries = lineofBusiness[typeOfSeries][lineofBusiness[typeOfSeries].length - 1];
                        if (productSeries.selected == false) {
                            productSeries.selected = true;
                            if (this.micrositeId && CustomPdfPath) {
                                let selectedCustomPdfData = CustomPdfPath.split("|");
                                selectedCustomPdfData.forEach(data => {
                                    let lobSNArr = data.split(":");
                                    if (lineofBusiness.LOBShortName == lobSNArr[0]) {
                                        productSeries.customBrochurePdfName = lobSNArr[2] + ".pdf";
                                        productSeries.customBrochurePdfPath = lobSNArr[1];
                                    }
                                });
                            }
                            productSeries.isCustomPdfUpload = true;
                            lineofBusiness.showDot = true;
                        }
                    }

                    /**
                     * For Spanish slection
                     */
                    if (
                        cutomSpanishPdfLobSelected &&
                        cutomSpanishPdfLobSelected.includes(lineofBusiness.LOBShortName)
                    ) {
                        let productSeriesSpanish =
                            lineofBusiness[typeOfSeriesSpanish][lineofBusiness[typeOfSeriesSpanish].length - 1];
                        if (productSeriesSpanish.selected == false) {
                            productSeriesSpanish.selected = true;
                            if (this.micrositeId && CustomSpanishPdfPath) {
                                let selectedCustomPdfData = CustomSpanishPdfPath.split("|");
                                selectedCustomPdfData.forEach(data => {
                                    let lobSNArr = data.split(":");
                                    let url = this.isMediaUrl(lobSNArr[1]) ? lobSNArr[1] : "https:" + lobSNArr[2];
                                    if (lineofBusiness.LOBShortName == lobSNArr[0]) {
                                        productSeriesSpanish.customBrochurePdfName =
                                            (this.isMediaUrl(lobSNArr[1]) ? lobSNArr[2] : lobSNArr[3]) + ".pdf";
                                        productSeriesSpanish.customBrochurePdfPath = url;
                                    }
                                });
                            }
                            productSeriesSpanish.isCustomPdfUpload = true;
                        }
                    }
                }
            }
        },
        selectProduct: function(productIds) {
            productIds = productIds.split("|");
            productIds.forEach(productId => {
                let isFoundInSupplement = this.selectProductById(
                    this.displayProducts,
                    productId,
                    this.type.AFLAC_SUPPLEMENTAL
                );
                //if (!isFoundInGroup) {
                // this.selectProductById(this.individualProducts, productId, this.type.AFLAC_SUPPLEMENTAL);
                //}
            });
        },
        selectProductGroup: function(productIds) {
            productIds = productIds.split("|");
            productIds.forEach(productId => {
                let isFoundInGroup = this.selectProductById(this.displayProducts, productId, this.type.AFLAC_GROUP);
                //if (!isFoundInGroup) {
                // this.selectProductById(this.individualProducts, productId, this.type.AFLAC_SUPPLEMENTAL);
                //}
            });
        },
        selectProductById: function(products, selectedProductDetail, type) {
            let typeOfSeries = type == this.type.AFLAC_GROUP ? this.type.GROUP_SERIES : this.type.INDIVIDUAL_SERIES;
            //lobshortName:productSeriesid:productSeriesName:productid1|lobid2ShortName:productSeriesid:productSeriesName:productid1
            let selectedProductDetailArr = selectedProductDetail.split(":");
            let productId = selectedProductDetailArr[3];
            for (let i = 0; i < products.lineofBusinesses.length; i++) {
                let lineofBusiness = products.lineofBusinesses[i];
                if (lineofBusiness.LOBShortName != selectedProductDetailArr[0]) {
                    continue;
                }

                for (let j = 0; j < lineofBusiness[typeOfSeries].length; j++) {
                    let productSeries = lineofBusiness[typeOfSeries][j];
                    if (
                        productSeries.ItemId != selectedProductDetailArr[1] ||
                        productSeries.ItemName != selectedProductDetailArr[2]
                    ) {
                        continue;
                    }

                    if (Array.isArray(productSeries.productLevels)) {
                        for (let k = 0; k < productSeries.productLevels.length; k++) {
                            if (productSeries.productLevels[k].ItemId == productId) {
                                productSeries.productLevels[k].selected = true;
                                if (productSeries.selected == false) {
                                    productSeries.selected = true;
                                    lineofBusiness.showDot = true;
                                    //disbale lob of other group
                                    // disable by @abdul
                                    //this.disableOtherGroupLob(type, lineofBusiness.ItemId);
                                }
                                return true;
                            }
                        }
                    }
                    if (Array.isArray(productSeries.riders)) {
                        for (let k = 0; k < productSeries.riders.length; k++) {
                            if (productSeries.riders[k].ItemId == productId) {
                                productSeries.riders[k].selected = true;
                                if (productSeries.selected == false) {
                                    productSeries.selected = true;
                                    lineofBusiness.showDot = true;
                                    //disbale lob of other group
                                    // disable by @abdul
                                    // this.disableOtherGroupLob(type, lineofBusiness.ItemId);
                                }
                                return true;
                            }
                        }
                    }
                }
                //} // end cutom pdf
            }
            return false;
        },
        disableOtherGroupLob(type, lobItemId) {
            let otherGroupProducts = type == this.type.AFLAC_GROUP ? this.individualProducts : this.groupProducts;
            let otherGroupLobArr = otherGroupProducts.lineofBusinesses.filter(lob => {
                return lob.ItemId == lobItemId;
            });
            if (Array.isArray(otherGroupLobArr) && otherGroupLobArr.length > 0) {
                otherGroupLobArr[0].disabled = true;
            }
        },
        getEnrollmentById: function(enrollmentId) {
            let enrollment = this.enrollmentVideos.filter(enrollemtObj => {
                return enrollemtObj.itemId.toLowerCase() == enrollmentId.toLowerCase();
            });
            if (enrollment.length > 0) {
                return enrollment[0];
            } else {
                return {};
            }
        },
        getStateByName: function(stateName) {
            let states = this.stateList.filter(state => {
                return state.State == stateName;
            });
            if (states.length > 0) {
                return states[0];
            } else {
                return null;
            }
        },
        getGroupStateByName: function(stateName) {
            let states = this.groupStateList.filter(state => {
                return state.State == stateName;
            });
            if (states.length > 0) {
                return states[0];
            } else {
                return null;
            }
        },
        getStates: function() {
            return new Promise(resolve => {
                httpClient.get("States/Get").then(result => {
                    this.stateList = result.data.Result;
                    resolve();
                });
            });
        },
        getGroupStates: function() {
            return new Promise(resolve => {
                httpClient.get("States/Get").then(result => {
                    this.groupStateList = result.data.Result;
                    resolve();
                });
            });
        },
        getEnrollmentVideos: function() {
            httpClient.get("EnrollmentVideos/Get").then(result => {
                this.enrollmentVideos = result.data.Result;
                this.enrollmnet_video = this.enrollmentVideos;
                // this.checkDirty = "pristine";
            });
        },
        addCustomFieldsinProducts: function(products, type) {
            const productSeriesType =
                type == this.type.AFLAC_SUPPLEMENTAL ? this.type.INDIVIDUAL_SERIES : this.type.GROUP_SERIES;
            const productSeriesSpanishType =
                type == this.type.AFLAC_SUPPLEMENTAL
                    ? this.type.INDIVIDUAL_SERIES_SPANISH
                    : this.type.GROUP_SERIES_SPANISH;
            for (let i = 0; i < products.lineofBusinesses.length; i++) {
                let lineofBusiness = products.lineofBusinesses[i];
                Vue.set(lineofBusiness, "showDot", false);
                Vue.set(lineofBusiness, "disabled", false);
                // added for Afalc Rate Sheet PDF upload
                Vue.set(lineofBusiness, "disclaimer", false);
                Vue.set(lineofBusiness, "isAflacRSPdfUpload", false); // optional for edit mode only
                Vue.set(lineofBusiness, "showAflacRSPdfSection", false);
                Vue.set(lineofBusiness, "isCustomPdfPreviewErr", false);
                Vue.set(lineofBusiness, "isPdfFileInvalid", false);
                Vue.set(lineofBusiness, "aflacRSPdfName", "");
                Vue.set(lineofBusiness, "aflacRSPdfPath", "");
                Vue.set(lineofBusiness, "aflacRSPdfBase64", "");
                for (let j = 0; j < lineofBusiness[productSeriesType].length; j++) {
                    let productSeries = lineofBusiness[productSeriesType][j];
                    Vue.set(productSeries, "selected", false);
                    Vue.set(productSeries, "disabled", false);
                    // added for custom PDF upload
                    Vue.set(productSeries, "disclaimer", false);
                    Vue.set(productSeries, "isCustomPdfUpload", false); // optional for edit mode only
                    Vue.set(productSeries, "showCustomBrochureSection", false);
                    Vue.set(productSeries, "isCustomPdfPreviewErr", false);
                    Vue.set(productSeries, "isPdfFileInvalid", false);
                    Vue.set(productSeries, "customBrochurePdfName", "");
                    Vue.set(productSeries, "customBrochurePdfPath", "");
                    Vue.set(productSeries, "customBrochurePdfBase64", "");

                    if (Array.isArray(productSeries.productLevels)) {
                        for (let k = 0; k < productSeries.productLevels.length; k++) {
                            Vue.set(productSeries.productLevels[k], "selected", false);
                        }
                    }
                    if (Array.isArray(productSeries.riders)) {
                        for (let k = 0; k < productSeries.riders.length; k++) {
                            Vue.set(productSeries.riders[k], "selected", false);
                        }
                    }
                }
                /**
                 * Object properties set for Custom Spanish Brochure upload
                 */
                if (
                    Object.prototype.hasOwnProperty.call(lineofBusiness, productSeriesSpanishType) &&
                    Array.isArray(lineofBusiness[productSeriesSpanishType])
                ) {
                    for (let sp = 0; sp < lineofBusiness[productSeriesSpanishType].length; sp++) {
                        let productSeriesSpanish = lineofBusiness[productSeriesSpanishType][sp];
                        Vue.set(productSeriesSpanish, "selected", false);
                        Vue.set(productSeriesSpanish, "disabled", false);
                        // added for custom PDF upload
                        Vue.set(productSeriesSpanish, "disclaimer", false);
                        Vue.set(productSeriesSpanish, "isCustomPdfUpload", false); // optional for edit mode only
                        Vue.set(productSeriesSpanish, "showCustomBrochureSection", false);
                        Vue.set(productSeriesSpanish, "isCustomPdfPreviewErr", false);
                        Vue.set(productSeriesSpanish, "isPdfFileInvalid", false);
                        Vue.set(productSeriesSpanish, "customBrochurePdfName", "");
                        Vue.set(productSeriesSpanish, "customBrochurePdfPath", "");
                        Vue.set(productSeriesSpanish, "customBrochurePdfBase64", "");

                        if (Array.isArray(productSeriesSpanish.productLevels)) {
                            for (let k = 0; k < productSeriesSpanish.productLevels.length; k++) {
                                Vue.set(productSeriesSpanish.productLevels[k], "selected", false);
                            }
                        }
                        if (Array.isArray(productSeriesSpanish.riders)) {
                            for (let k = 0; k < productSeriesSpanish.riders.length; k++) {
                                Vue.set(productSeriesSpanish.riders[k], "selected", false);
                            }
                        }
                    }
                }
            }
        },
        getProduct: function(type = type.AFLAC_SUPPLEMENTAL) {
            this.isReadyApiData = false;
            return new Promise((resolve, reject) => {
                if (type == this.type.AFLAC_SUPPLEMENTAL && this.siteForm.organizationState) {
                    this.displayProductsOld = JSON.parse(JSON.stringify(this.displayProducts));
                    this.getProductDataByApi(
                        `Product/Get?StateId=${this.siteForm.organizationState.itemId}&IsGroup=false&lang=en`
                    )
                        .then(result => {
                            this.individualProducts.state = result.data.Result.state;
                            this.displayProducts.state = result.data.Result.state;
                            this.individualProducts.lineofBusinesses = result.data.Result.LineofBusiness;
                            if (Array.isArray(result.data.Result.LineofBusiness)) {
                                if (this.displayProducts.lineofBusinesses.length == 0) {
                                    this.displayProducts.lineofBusinesses = result.data.Result.LineofBusiness;
                                    for (let i = 0; i < result.data.Result.LineofBusiness.length; i++) {
                                        this.displayProducts.lineofBusinesses[i][this.type.GROUP_SERIES] = [];
                                    }
                                } else {
                                    if (this.siteForm.groupState) {
                                        for (let i = 0; i < result.data.Result.LineofBusiness.length; i++) {
                                            const lobIndex = this.getItemIndexByID(
                                                this.displayProducts.lineofBusinesses,
                                                result.data.Result.LineofBusiness[i].ItemId
                                            );

                                            if (lobIndex != -1) {
                                                this.displayProducts.lineofBusinesses[lobIndex][
                                                    this.type.INDIVIDUAL_SERIES
                                                ] = result.data.Result.LineofBusiness[i].productSeries;
                                                /** Spanish custom brochure : Individual */
                                                this.displayProducts.lineofBusinesses[lobIndex][
                                                    this.type.INDIVIDUAL_SERIES_SPANISH
                                                ] = result.data.Result.LineofBusiness[i].productSeriesSpanish;
                                            } else {
                                                this.displayProducts.lineofBusinesses[lobIndex][
                                                    this.type.INDIVIDUAL_SERIES
                                                ] = [];
                                                /** Spanish custom brochure : Individual */
                                                this.displayProducts.lineofBusinesses[lobIndex][
                                                    this.type.INDIVIDUAL_SERIES_SPANISH
                                                ] = [];
                                            }
                                        }
                                    } else {
                                        this.displayProducts.lineofBusinesses = result.data.Result.LineofBusiness;
                                        for (let i = 0; i < result.data.Result.LineofBusiness.length; i++) {
                                            this.displayProducts.lineofBusinesses[i][this.type.GROUP_SERIES] = [];
                                            /** Spanish custom brochure : group reset */
                                            this.displayProducts.lineofBusinesses[i][
                                                this.type.GROUP_SERIES_SPANISH
                                            ] = [];
                                        }
                                    }
                                }
                                this.isReadyApiData = true;
                            }
                            if (this.isReadyApiData && this.displayProducts.lineofBusinesses.length > 0) {
                                this.addCustomFieldsinProducts(this.displayProducts, type);
                                this.initIndividualProducts = JSON.parse(JSON.stringify(this.individualProducts));
                                this.initDisplayProducts = JSON.parse(JSON.stringify(this.displayProducts));
                                resolve(this.displayProducts);
                            }
                        })
                        .catch(err => reject("product fetching issue"));
                } else if (type == this.type.AFLAC_GROUP && this.siteForm.groupState) {
                    this.displayProductsOld = JSON.parse(JSON.stringify(this.displayProducts));
                    this.getProductDataByApi(
                        `Product/Get?StateId=${this.siteForm.groupState.itemId}&IsGroup=true&lang=en`
                    ).then(result => {
                        if (result.data.Result) {
                            this.groupProducts.state = result.data.Result.state;
                            this.groupProducts.lineofBusinesses = result.data.Result.LineofBusiness;
                            if (Array.isArray(result.data.Result.LineofBusiness)) {
                                if (this.displayProducts.lineofBusinesses.length == 0) {
                                    this.displayProducts.lineofBusinesses = result.data.Result.LineofBusiness;
                                    for (let i = 0; i < result.data.Result.LineofBusiness.length; i++) {
                                        this.displayProducts.lineofBusinesses[i][this.type.GROUP_SERIES] =
                                            result.data.Result.LineofBusiness[i].productSeries;
                                        this.displayProducts.lineofBusinesses[i][this.type.INDIVIDUAL_SERIES] = [];
                                        /** Spanish custom group brochure : SET-RESET */
                                        this.displayProducts.lineofBusinesses[i][this.type.GROUP_SERIES_SPANISH] =
                                            result.data.Result.LineofBusiness[i].productSeriesSpanish;
                                        this.displayProducts.lineofBusinesses[i][
                                            this.type.INDIVIDUAL_SERIES_SPANISH
                                        ] = [];
                                    }
                                } else {
                                    this.storeDisplayProducts = JSON.parse(JSON.stringify(this.displayProducts));
                                    if (
                                        this.displayProducts.lineofBusinesses.length <
                                        result.data.Result.LineofBusiness.length
                                    ) {
                                        this.displayProducts.lineofBusinesses = result.data.Result.LineofBusiness;
                                    }

                                    for (let j = 0; j < result.data.Result.LineofBusiness.length; j++) {
                                        const lobIndexOld = this.getItemIndexByID(
                                            this.storeDisplayProducts.lineofBusinesses,
                                            result.data.Result.LineofBusiness[j].ItemId
                                        );
                                        const lobIndexNew = this.getItemIndexByID(
                                            this.displayProducts.lineofBusinesses,
                                            result.data.Result.LineofBusiness[j].ItemId
                                        );

                                        if (lobIndexOld != -1) {
                                            this.displayProducts.lineofBusinesses[lobIndexNew][this.type.GROUP_SERIES] =
                                                result.data.Result.LineofBusiness[j].productSeries;
                                            this.displayProducts.lineofBusinesses[lobIndexNew][
                                                this.type.INDIVIDUAL_SERIES
                                            ] = this.storeDisplayProducts.lineofBusinesses[lobIndexOld][
                                                this.type.INDIVIDUAL_SERIES
                                            ];
                                            /** keeping existing spanish custom brochure */
                                            this.displayProducts.lineofBusinesses[lobIndexNew][
                                                this.type.GROUP_SERIES_SPANISH
                                            ] = result.data.Result.LineofBusiness[j].productSeriesSpanish;
                                            this.displayProducts.lineofBusinesses[lobIndexNew][
                                                this.type.INDIVIDUAL_SERIES_SPANISH
                                            ] = this.storeDisplayProducts.lineofBusinesses[lobIndexOld][
                                                this.type.INDIVIDUAL_SERIES_SPANISH
                                            ];
                                        } else {
                                            this.displayProducts.lineofBusinesses[j][
                                                this.type.GROUP_SERIES
                                            ] = this.displayProducts.lineofBusinesses[j].productSeries;
                                            this.displayProducts.lineofBusinesses[j][this.type.INDIVIDUAL_SERIES] = [];
                                            /** Custom Spanish brochure for group */
                                            this.displayProducts.lineofBusinesses[j][
                                                this.type.GROUP_SERIES_SPANISH
                                            ] = this.displayProducts.lineofBusinesses[j].productSeriesSpanish;
                                            this.displayProducts.lineofBusinesses[j][
                                                this.type.INDIVIDUAL_SERIES_SPANISH
                                            ] = [];
                                        }
                                    }
                                }
                                this.isReadyApiData = true;
                            }
                        }
                        if (this.isReadyApiData && this.displayProducts.lineofBusinesses.length > 0) {
                            this.addCustomFieldsinProducts(this.displayProducts, type);
                            this.initGroupProducts = JSON.parse(JSON.stringify(this.groupProducts));
                            this.initDisplayProducts = JSON.parse(JSON.stringify(this.displayProducts));
                            resolve(this.displayProducts);
                        }
                    });
                }
            });
        },
        getProductDataByApi(url) {
            const promise = new Promise((resolve, reject) => {
                setTimeout(() => {
                    httpClient.get(url).then(result => {
                        if (result.data.Result) {
                            resolve(result);
                        } else {
                            reject({
                                error: "Fetching data..."
                            });
                        }
                    });
                }, 100);
            });
            return promise;
        },
        isAnyLevelorRiderSelectedInProductPopup: function() {
            let selectedProductLevel = [];
            let selectedRider = [];
            if (Array.isArray(this.selectedProductSeriesDetail.productSeries.productLevels)) {
                selectedProductLevel = this.selectedProductSeriesDetail.productSeries.productLevels.filter(
                    productLevel => {
                        return productLevel.selected == true;
                    }
                );
            }
            if (Array.isArray(this.selectedProductSeriesDetail.productSeries.riders)) {
                selectedRider = this.selectedProductSeriesDetail.productSeries.riders.filter(rider => {
                    return rider.selected == true;
                });
            }
            if (selectedProductLevel.length > 0 || selectedRider.length > 0) {
                return true;
            } else {
                return false;
            }
        },
        getItemByID(items, ItemId) {
            for (let i = 0; i < items.length; i++) {
                if (items[i].ItemId == ItemId) {
                    return items[i];
                }
            }
            return null;
        },
        getItemIndexByID(items, ItemId) {
            for (let i = 0; i < items.length; i++) {
                if (items[i].ItemId == ItemId) {
                    return i;
                }
            }
            return -1;
        },
        getInitSeriesById(type, lobID, seriesId) {
            let products = type == this.type.AFLAC_GROUP ? this.initGroupProducts : this.initIndividualProducts;
            let lob = this.getItemByID(products.lineofBusinesses, lobID);
            return this.getItemByID(lob.productSeries, seriesId);
        },
        updateBlueDotandDisableLOB: function(isProductSeriesChecked, groupBrochureType) {
            let isSelected = false;
            /*let otherGroup;
            otherGroup =
                this.type.GROUP_SELECTED == this.type.AFLAC_GROUP ? this.individualProducts : this.groupProducts;
                // custom function added  to disbale other lob products series items
              */
            this.selectedProductSeriesDetail.lineOfBusiness.showDot = false;
            /* let otherGroupLobArr = otherGroup.lineofBusinesses.filter(lineofBusiness1 => {
                return lineofBusiness1.ItemId == this.selectedProductSeriesDetail.lineOfBusiness.ItemId;
            });
            let otherGroupLob;
            if (otherGroupLobArr.length > 0) {
                otherGroupLob = otherGroupLobArr[0];
                otherGroupLob.disabled = false;
            }
            */
            if (isProductSeriesChecked) {
                // set disable other group lob in case product series is checked and submit
                isSelected = groupBrochureType == "pdf" ? true : this.isAnyLevelorRiderSelectedInProductPopup();
                if (isSelected) {
                    // allow single selection for Group section
                    this.checkAndUnselectProductSeriesByLobId(
                        this.selectedProductSeriesDetail.lineOfBusiness.ItemId,
                        this.type.GROUP_SELECTED
                    );
                    // show dot in create mode.
                    this.selectedProductSeriesDetail.lineOfBusiness.showDot = true;
                    let lobId = this.selectedProductSeriesDetail.lineOfBusiness.ItemId;
                    const lobIndex = this.getItemIndexByID(this.displayProducts.lineofBusinesses, lobId);
                    const lob = this.displayProducts.lineofBusinesses[lobIndex];
                    let productSeriesAll =
                        this.type.GROUP_SELECTED == this.type.AFLAC_GROUP ? lob.productSeriesG : lob.productSeries;
                    let lastItem = productSeriesAll[productSeriesAll.length - 1];
                    if (
                        lastItem &&
                        this.selectedProductSeriesDetail.productSeries.ItemId != "" &&
                        this.selectedProductSeriesDetail.productSeries.ItemId != lastItem.ItemId
                    ) {
                        this.checkAndUnselectProductSeriesByLobId(lobId, this.type.GROUP_SELECTED);
                        lastItem.showCustomBrochureSection = false;
                        lastItem.isCustomPdfUpload = false;
                        lastItem.disclaimer = false;
                    } else if (lastItem.isCustomPdfUpload && this.type.GROUP_SELECTED == this.type.AFLAC_SUPPLEMENTAL) {
                        this.unselectAllProductSeriesByLobId(lobId, this.type.GROUP_SELECTED);
                    }
                } else {
                    let lobId = this.selectedProductSeriesDetail.lineOfBusiness.ItemId;
                    const lobIndex = this.getItemIndexByID(this.displayProducts.lineofBusinesses, lobId);
                    const lob = this.displayProducts.lineofBusinesses[lobIndex];
                    //let productSeriesAll = lob.productSeriesG;
                    let productSeriesAll =
                        this.type.GROUP_SELECTED == this.type.AFLAC_GROUP ? lob.productSeriesG : lob.productSeries;
                    let lastItem = productSeriesAll[productSeriesAll.length - 1];
                    if (
                        this.selectedProductSeriesDetail.productSeries.ItemId != "" &&
                        this.selectedProductSeriesDetail.productSeries.ItemId != lastItem.ItemId &&
                        lastItem.selected
                    ) {
                        // allow single selection for Group section
                        this.checkAndUnselectProductSeriesByLobId(lobId, this.type.GROUP_SELECTED);
                        this.selectedProductSeriesDetail.productSeries.selected = false;
                        lastItem.selected = true;
                        //remove spanish brochure
                        this.removeSpnBrochurePdf(lobId, this.type.GROUP_SELECTED);
                    } else {
                        this.checkAndEnableProductSectionByLobId(lobId, this.type.GROUP_SELECTED);
                        this.selectedProductSeriesDetail.productSeries.selected = false;
                        //remove spanish brochure
                        this.removeSpnBrochurePdf(lobId, this.type.GROUP_SELECTED);
                    }
                }
            } else {
                if (this.type.GROUP_SELECTED == this.type.AFLAC_GROUP) {
                    let lobId = this.selectedProductSeriesDetail.lineOfBusiness.ItemId;
                    const lobIndex = this.getItemIndexByID(this.displayProducts.lineofBusinesses, lobId);
                    const lob = this.displayProducts.lineofBusinesses[lobIndex];
                    let productSeriesAll =
                        this.type.GROUP_SELECTED == this.type.AFLAC_GROUP ? lob.productSeriesG : lob.ProductSeries;
                    let lastItem = productSeriesAll[productSeriesAll.length - 1];
                    lastItem.showCustomBrochureSection = false;
                    lastItem.isCustomPdfUpload = false;
                    lastItem.disclaimer = false;
                }
                if (groupBrochureType == "pdf") {
                    let lobId = this.currentLobIdToRemove;
                    const lobIndex = this.getItemIndexByID(this.displayProducts.lineofBusinesses, lobId);
                    this.displayProducts.lineofBusinesses[lobIndex].showDot = false;
                    this.checkAndEnableProductSectionByLobId(lobId, this.type.GROUP_SELECTED);
                } else {
                    // set disable other group lob in case product series is unchecked. uncheck all selected levels and riders of the series
                    if (
                        Object.prototype.hasOwnProperty.call(
                            this.selectedProductSeriesDetail,
                            this.type.INDIVIDUAL_SERIES
                        ) &&
                        Object.prototype.hasOwnProperty.call(
                            this.selectedProductSeriesDetail.productSeries,
                            this.type.AFLAC_LEVEL
                        ) &&
                        Array.isArray(this.selectedProductSeriesDetail.productSeries.productLevels) &&
                        this.selectedProductSeriesDetail.productSeries.productLevels.length > 0
                    ) {
                        for (let k = 0; k < this.selectedProductSeriesDetail.productSeries.productLevels.length; k++) {
                            this.selectedProductSeriesDetail.productSeries.productLevels[k].selected = false;
                        }
                    }
                    if (
                        Object.prototype.hasOwnProperty.call(
                            this.selectedProductSeriesDetail,
                            this.type.INDIVIDUAL_SERIES
                        ) &&
                        Object.prototype.hasOwnProperty.call(
                            this.selectedProductSeriesDetail.productSeries,
                            this.type.AFLAC_RIDER
                        ) &&
                        Array.isArray(this.selectedProductSeriesDetail.productSeries.riders) &&
                        this.selectedProductSeriesDetail.productSeries.riders.length > 0
                    ) {
                        for (let k = 0; k < this.selectedProductSeriesDetail.productSeries.riders.length; k++) {
                            this.selectedProductSeriesDetail.productSeries.riders[k].selected = false;
                        }
                    }
                    /*if (otherGroupLob) {
                        otherGroupLob.disabled = false;
                    }*/
                }
            }
            for (let i = 0; i < this.selectedProductSeriesDetail.lineOfBusiness.productSeries.length; i++) {
                if (this.selectedProductSeriesDetail.lineOfBusiness.productSeries[i].selected == true) {
                    this.selectedProductSeriesDetail.lineOfBusiness.showDot = true;
                }
            }
            // if (this.micrositeId) {
            //     // show dot in edit mode.
            //     let initSeries = this.getInitSeriesById(
            //         this.type.GROUP_SELECTED,
            //         this.selectedProductSeriesDetail.lineOfBusiness.ItemId,
            //         this.selectedProductSeriesDetail.productSeries.ItemId
            //     );
            //     if (
            //         JSON.stringify(this.selectedProductSeriesDetail.productSeries.productLevels) !==
            //             JSON.stringify(initSeries.productLevels) ||
            //         JSON.stringify(this.selectedProductSeriesDetail.productSeries.riders) !==
            //             JSON.stringify(initSeries.riders)
            //     ) {
            //         this.selectedProductSeriesDetail.lineOfBusiness.showDot = true;
            //     }
            // }
            /*if (otherGroupLob && otherGroupLob.disabled) {
                //close other group accordion
                let otherGroupLobIndex = this.getItemIndexByID(otherGroup.lineofBusinesses, otherGroupLob.ItemId);
                let otherGroupType =
                    this.type.GROUP_SELECTED == this.type.AFLAC_GROUP
                        ? this.type.AFLAC_SUPPLEMENTAL
                        : this.type.AFLAC_GROUP;
               //this.closeAccByIndex(otherGroupType, otherGroupLobIndex);
            }*/
        },
        onCustomPDFUpload: function() {
            this.showPDFUPloadModal = true;
            this.CustomPdfUPloadModal = true;
        },
        onProductSeriesSelect: function(lob, productSeries, type, groupBrochureType) {
            // set current lobId by @Abdul : Custom  PDF upload
            //this.currentLobIdToRemove = lob.ItemId;
            this.type.GROUP_SELECTED =
                type == this.type.AFLAC_GROUP ? this.type.AFLAC_GROUP : this.type.AFLAC_SUPPLEMENTAL;

            this.checkAndEnableProductSectionByLobId(lob.ItemId, this.type.GROUP_SELECTED);
            //this.selectedProductSeries = productSeries;
            this.selectedProductSeriesDetail.lineOfBusiness = lob;
            this.selectedProductSeriesDetail.productSeries = productSeries;
            this.initProductSeries = JSON.parse(JSON.stringify(this.selectedProductSeriesDetail.productSeries));

            if (productSeries.selected) {
                if (type == this.type.AFLAC_SUPPLEMENTAL) {
                    if (groupBrochureType == "pdf") {
                        this.showPDFUPloadModalpopup = true;
                    } else {
                        this.showPDFUPloadModalpopup = false;
                        let productSeriesAll = lob.productSeries;
                        let lastItem = productSeriesAll[productSeriesAll.length - 1];
                        if (lastItem.isCustomPdfUpload && (lastItem.selected || lastItem.disclaimer)) {
                            this.removePDFConfirm(lob.ItemId, type);
                        } else if (lastItem.ItemId == null && productSeries.ItemId == null) {
                            this.retryBrowsePdf(null, lob, productSeries, this.type.AFLAC_SUPPLEMENTAL);
                        } else {
                            this.showProductModal = true;
                        }
                    }
                } else if (type == this.type.AFLAC_GROUP) {
                    if (type == this.type.AFLAC_GROUP) {
                        let productSeriesAll =
                            this.type.GROUP_SELECTED == this.type.AFLAC_GROUP ? lob.productSeriesG : lob.ProductSeries;
                        let lastItem = productSeriesAll[productSeriesAll.length - 1];
                        if (lastItem.isCustomPdfUpload && (lastItem.selected || lastItem.disclaimer)) {
                            this.removePDFConfirm(lob.ItemId, type);
                        } else if (lastItem.ItemId == null && productSeries.ItemId == null) {
                            this.showPDFUPloadModalpopup = false;
                            this.retryBrowsePdf(null, lob, productSeries, this.type.AFLAC_GROUP);
                        } else {
                            this.showProductModal = true;
                        }
                    } else {
                        this.showProductModal = true;
                    }
                }
            } else {
                // remove all selection and update dot and disable property

                if (groupBrochureType == "pdf") {
                    this.removePDFConfirm(lob.ItemId, type);
                } else {
                    if (productSeries.ItemId == null && productSeries.isCustomPdfUpload) {
                        this.removePDFConfirm(lob.ItemId, type);
                        this.updateBlueDotandDisableLOB(true, "pdf");
                    } else {
                        this.updateBlueDotandDisableLOB(false);
                        //remove custom brochure when wrong pdf
                        this.removePdf(lob.ItemId, false);
                    }
                }
            }
        },
        onProductModalCancel: function(groupBrochureType) {
            let isSelected = false;
            if (groupBrochureType == "pdf") {
                this.showPDFUPloadModalpopup = false;
                this.showPDFUPloadModalpopupSpanish = false;
                isSelected = true;
                //isSelected = this.isAnyLevelorRiderSelectedInProductPopup();
                this.selectedProductSeriesDetail.productSeries.disclaimer = false;
                this.selectedProductSeriesDetail.productSeries.selected = false;
            } else {
                this.showProductModal = false;
                // revert product popup changes
                if (this.selectedProductSeriesDetail.hasOwnProperty(this.type.INDIVIDUAL_SERIES)) {
                    if (Array.isArray(this.selectedProductSeriesDetail.productSeries.productLevels)) {
                        for (let k = 0; k < this.selectedProductSeriesDetail.productSeries.productLevels.length; k++) {
                            this.selectedProductSeriesDetail.productSeries.productLevels[
                                k
                            ].selected = this.initProductSeries.productLevels[k].selected;
                        }
                    }
                    if (Array.isArray(this.selectedProductSeriesDetail.productSeries.riders)) {
                        for (let k = 0; k < this.selectedProductSeriesDetail.productSeries.riders.length; k++) {
                            this.selectedProductSeriesDetail.productSeries.riders[
                                k
                            ].selected = this.initProductSeries.riders[k].selected;
                        }
                    }
                    isSelected = this.isAnyLevelorRiderSelectedInProductPopup();
                }
            }
            if (!isSelected && this.selectedProductSeriesDetail.hasOwnProperty(this.type.INDIVIDUAL_SERIES)) {
                if (this.selectedProductSeriesDetail.productSeries.isCustomPdfUpload) {
                    this.selectedProductSeriesDetail.productSeries.selected = true;
                } else {
                    if (this.type.GROUP_SELECTED == this.type.AFLAC_GROUP) {
                        let lobId = this.selectedProductSeriesDetail.lineOfBusiness.ItemId;
                        const lobIndex = this.getItemIndexByID(this.displayProducts.lineofBusinesses, lobId);
                        const lob = this.displayProducts.lineofBusinesses[lobIndex];
                        let productSeriesAll =
                            this.type.GROUP_SELECTED == this.type.AFLAC_GROUP ? lob.productSeriesG : lob.ProductSeries;
                        let lastItem = productSeriesAll[productSeriesAll.length - 1];
                        if (
                            this.selectedProductSeriesDetail.productSeries.ItemId != "" &&
                            this.selectedProductSeriesDetail.productSeries.ItemId != lastItem.ItemId &&
                            lastItem.isCustomPdfUpload
                        ) {
                            this.selectedProductSeriesDetail.productSeries.selected = false;
                            // allow single selection for Group section
                            this.checkAndUnselectProductSeriesByLobId(lobId, this.type.GROUP_SELECTED);
                            lastItem.selected = true;
                        } else if (lastItem.selected) {
                            this.selectedProductSeriesDetail.productSeries.selected = false;
                            // allow single selection for Group section
                            this.checkAndUnselectProductSeriesByLobId(lobId, this.type.GROUP_SELECTED);
                            lastItem.selected = true;
                        } else {
                            this.selectedProductSeriesDetail.productSeries.selected = false;
                            lastItem.selected = false;
                        }
                    } else {
                        this.selectedProductSeriesDetail.productSeries.selected = false;
                    }
                }
                /*this.checkAndEnableProductSectionByLobId(
                    this.selectedProductSeriesDetail.lineOfBusiness.ItemId,
                    this.type.GROUP_SELECTED
                );*/
            }
        },
        onProductModalSubmit: function(lobID) {
            this.showProductModal = false;
            // Unselect and disaled lob items for Group only
            // this.checkAndUnselectProductSeriesByLobId(lobID,this.type.GROUP_SELECTED);
            // start from type which group need to disable
            this.updateBlueDotandDisableLOB(true);
        },
        closeAllAcc: function() {
            let panel;
            let iconId;
            let minusIconId;
            let totalAccordion = this.displayProducts.lineofBusinesses.length;
            for (let index = 0; index < totalAccordion; index++) {
                panel = document.getElementById("#supplemental" + index);
                iconId = document.getElementById("supplementalplusIcon" + index);
                minusIconId = document.getElementById("supplementalminusIcon" + index);

                iconId.style.display = "block";
                minusIconId.style.display = "none";
                panel.style.display = "none";
            }
            let pladProductsDiv = document.getElementById("pladSection");
            if (pladProductsDiv) {
                this.closePladProducts();
            }
            let nonAflacSection = document.getElementById("nonAflacSection");
            if (nonAflacSection) {
                this.closeNonAflacAllAcc();
            }
        },
        closeAccByIndex: function(type, index) {
            let panel;
            let iconId;
            let minusIconId;
            if (type == this.type.AFLAC_GROUP) {
                panel = document.getElementById("#group" + index);
                iconId = document.getElementById("groupplusIcon" + index);
                minusIconId = document.getElementById("groupminusIcon" + index);
            } else if (type == this.type.AFLAC_SUPPLEMENTAL) {
                panel = document.getElementById("#supplemental" + index);
                iconId = document.getElementById("supplementalplusIcon" + index);
                minusIconId = document.getElementById("supplementalminusIcon" + index);
            }
            iconId.style.display = "block";
            minusIconId.style.display = "none";
            panel.style.display = "none";
        },
        openAcc: function(index, type, event) {
            var panel;
            let iconId;
            let minusIconId;
            if (event && event.currentTarget.classList.value.indexOf("disabled-state") > -1) {
                return;
            }
            if (type == this.type.AFLAC_GROUP) {
                panel = document.getElementById("#group" + index);
                iconId = document.getElementById("groupplusIcon" + index);
                minusIconId = document.getElementById("groupminusIcon" + index);
            } else if (type == this.type.AFLAC_SUPPLEMENTAL) {
                panel = document.getElementById("#supplemental" + index);
                iconId = document.getElementById("supplementalplusIcon" + index);
                minusIconId = document.getElementById("supplementalminusIcon" + index);
            }

            minusIconId.style.display = "none";
            if (panel.style.display === "block") {
                iconId.style.display = "block";
                minusIconId.style.display = "none";
                panel.style.display = "none";
            } else {
                iconId.style.display = "none";
                minusIconId.style.display = "block";
                panel.style.display = "block";
            }
        },

        openAccNonAflac: function(index, type, event) {
            var panel;
            let iconId;
            let minusIconId;
            if (type == this.type.NONAFLAC_PRODUCT) {
                panel = document.getElementById("#supplementalNonAflac");
                iconId = document.getElementById("supplementalplusIconNonAflac");
                minusIconId = document.getElementById("supplementalminusIconNonAflac");
                minusIconId.style.display = "none";
                if (panel.style.display === "block") {
                    iconId.style.display = "block";
                    minusIconId.style.display = "none";
                    panel.style.display = "none";
                } else {
                    iconId.style.display = "none";
                    minusIconId.style.display = "block";
                    panel.style.display = "block";
                }
            }
        },
        closeNonAflacAllAcc: function() {
            let panel;
            let iconId;
            let minusIconId;
            panel = document.getElementById("#supplementalNonAflac");
            iconId = document.getElementById("supplementalplusIconNonAflac");
            minusIconId = document.getElementById("supplementalminusIconNonAflac");
            iconId.style.display = "block";
            minusIconId.style.display = "none";
            panel.style.display = "none";
            // this.openPladProducts(index, $event);
        },
        onEnrollmentSelect: function() {},
        updateDate: function(newVal) {
            if (newVal.enrollmentDateTo == null || newVal.enrollmentDateTo == "") {
                let val = Object.assign(newVal, {});
                this.$v.siteForm["enrollmentDateTo"].$model = val.enrollmentDateFrom;
            }
        },

        openPladProducts: function(index, event) {
            var panel;
            let iconId;
            let minusIconId;

            panel = document.getElementById("#pladProduct" + index);
            iconId = document.getElementById("pladplusIcon" + index);
            minusIconId = document.getElementById("pladminusIcon" + index);
            minusIconId.style.display = "none";
            if (panel.style.display === "block") {
                iconId.style.display = "block";
                minusIconId.style.display = "none";
                panel.style.display = "none";
            } else {
                iconId.style.display = "none";
                minusIconId.style.display = "block";
                panel.style.display = "block";
            }
        },
        closePladProducts: function() {
            let panel;
            let iconId;
            let minusIconId;
            panel = document.getElementsByClassName("dvPlads");
            iconId = document.getElementsByClassName("plusIcon");
            minusIconId = document.getElementsByClassName("minusIcon");
            for (let i = 0; i < panel.length; i++) {
                panel[i].style.display = "none";
            }
            for (let i = 0; i < iconId.length; i++) {
                iconId[i].style.display = "block";
            }
            for (let i = 0; i < minusIconId.length; i++) {
                minusIconId[i].style.display = "none";
            }
        },
        getValidationClass(fieldName) {
            const field = this.$v.siteForm[fieldName];
            if (fieldName == "enrollmentDateFrom" || fieldName == "enrollmentDateTo") {
                //console.log("======", this.$v.siteForm["enrollmentDateFrom"].$model);
                //console.log("======", this.$v.siteForm["enrollmentDateTo"].$model);
            }
            if (field) {
                return {
                    "is-invalid": field.$invalid && field.$dirty
                };
            }
        },
        updateDateValues(fromDateValue = null, toDateValue = null, type) {
            if (type == "EnrollmentDate") {
                this.$v.siteForm["enrollmentDateFrom"].$model = fromDateValue;
                this.$v.siteForm["enrollmentDateTo"].$model = toDateValue;
            } else if (type == "LiveDate") {
                this.$v.siteForm["liveDateFrom"].$model = fromDateValue;
                this.$v.siteForm["liveDateTo"].$model = toDateValue;
            }
        },
        viewPreviewContent(startDate, endDate) {
            try {
                // let currentDate = new Date();
                // let st_Date = new Date(startDate);
                // let en_Date = new Date(endDate);
                // // Reduce 14 days
                // st_Date.setDate(st_Date.getDate() - 14);
                // let videos = this.enrollmentVideos;
                // this.enrollmnet_video = this.enrollmentVideos;
                // this.siteForm.selectedEnrollment = this.enrollmentVideos[0];
                // if (currentDate.getTime() >= st_Date.getTime() && currentDate.getTime() <= en_Date.getTime()) {
                //     let enrollmentVideo = videos.filter(x => x.itemId == "18da069c-16d8-4217-b820-4a8758e7da64");
                //     this.enrollmnet_video = enrollmentVideo;
                //     this.siteForm.selectedEnrollment = enrollmentVideo[0];
                // }
                // else {
                //     let NotenrollmentVideo = videos.filter(x => x.itemId == "15f24bdd-68fe-4833-8b7d-1a2b6e847bcd");
                //     this.enrollmnet_video = NotenrollmentVideo;
                //     this.siteForm.selectedEnrollment = NotenrollmentVideo[0];
                //     this.siteForm.selectedEnrollment.Description=enrollmentVideo[0].Description;
                // }
            } catch (ex) {}
        },
        /*getDateRange(data, type) {
            if (data) {
                let dateData = data.toString().split("to");
                let fromDate = dateData[0].trim();
                let toDate = null;

                if (dateData[1]) {
                    toDate = dateData[1].trim();
                } else {
                    toDate = fromDate;
                }
                this.isLiveDatesInValid = true;

                // if (type == "LiveDate" && this.siteForm.enrollmentDateFrom && this.siteForm.enrollmentDateTo) {
                //     let isLiveStartDateAfter = Vue.moment(fromDate).isAfter(this.siteForm.enrollmentDateFrom);
                //     let isLiveEndDateBefore = Vue.moment(toDate).isBefore(this.siteForm.enrollmentDateTo);
                //     if (isLiveStartDateAfter || isLiveEndDateBefore) {
                //         this.isLiveDatesInValid = false;
                //     }
                // }
                // if (type == "EnrollmentDate" && this.siteForm.liveDateFrom && this.siteForm.liveDateTo) {
                //     let isEnrollStartDateBefore = Vue.moment(fromDate).isBefore(this.siteForm.liveDateFrom);
                //     let isEnrollEndDateAfter = Vue.moment(toDate).isAfter(this.siteForm.liveDateTo);
                //     if (isEnrollStartDateBefore || isEnrollEndDateAfter) {
                //         this.isLiveDatesInValid = false;
                //     }
                // }
                // if (!this.isLiveDatesInValid) {
                //     this.liveDatePicker.setDate([], true);
                //     this.siteForm.liveDateFrom = null;
                //     this.siteForm.liveDateTo = null;
                //      if (type == "LiveDate") {
                //          return;
                //      }
                // } else {
                //     this.isLiveDatesInValid = true;
                // }
                this.updateDateValues(fromDate, toDate, type);
                // this.viewPreviewContent(dateData[0], dateData[1]);
            }
        },*/
        clearForm() {
            this.$v.$reset();
            this.siteForm.organizationName = null;
            this.siteForm.organizationState = {};
            this.siteForm.groupState = {};
            this.siteForm.contactName = null;
            this.siteForm.contactEmail = null;
            this.siteForm.contactNumber = null;
            this.siteForm.enrollmentDateTo = null;
            this.siteForm.accountId = null;
            this.siteForm.calenderLink = null;
        },
        getSelectedProductIds(products, type) {
            const productSeriesType =
                type == this.type.AFLAC_SUPPLEMENTAL ? this.type.INDIVIDUAL_SERIES : this.type.GROUP_SERIES;
            let productIds = "";
            products.lineofBusinesses.forEach(lineofBusiness => {
                lineofBusiness[productSeriesType].forEach(productSeries => {
                    if (Array.isArray(productSeries.productLevels)) {
                        productSeries.productLevels.forEach(productLevel => {
                            if (productLevel.selected) {
                                productIds += productLevel.ItemId + "|";
                            }
                        });
                    }
                    if (Array.isArray(productSeries.riders)) {
                        productSeries.riders.forEach(rider => {
                            if (rider.selected) {
                                productIds += rider.ItemId + "|";
                            }
                        });
                    }
                });
            });
            if (productIds != "") {
                productIds = productIds.slice(0, -1);
            }
            return productIds;
        },
        getSelectedProductDetails(products, type) {
            const productSeriesType =
                type == this.type.AFLAC_SUPPLEMENTAL ? this.type.INDIVIDUAL_SERIES : this.type.GROUP_SERIES;
            let productIds = "";
            products.lineofBusinesses.forEach(lineofBusiness => {
                // let lobShortName = productIds + lineofBusiness.LOBShortName ;
                lineofBusiness[productSeriesType].forEach(productSeries => {
                    // productIds = productIds + productSeries.ItemId + ":"+ productSeries.ItemName ;
                    if (Array.isArray(productSeries.productLevels)) {
                        productSeries.productLevels.forEach(productLevel => {
                            if (productLevel.selected) {
                                productIds +=
                                    lineofBusiness.LOBShortName +
                                    ":" +
                                    productSeries.ItemId +
                                    ":" +
                                    productSeries.ItemName +
                                    ":" +
                                    productLevel.ItemId +
                                    "|";
                            }
                        });
                    }
                    if (Array.isArray(productSeries.riders)) {
                        productSeries.riders.forEach(rider => {
                            if (rider.selected) {
                                productIds +=
                                    lineofBusiness.LOBShortName +
                                    ":" +
                                    productSeries.ItemId +
                                    ":" +
                                    productSeries.ItemName +
                                    ":" +
                                    rider.ItemId +
                                    "|";
                            }
                        });
                    }
                });
            });
            if (productIds != "") {
                productIds = productIds.slice(0, -1);
            }
            return productIds;
        },
        getSelectedlobids(products, type) {
            const productSeriesType =
                type == this.type.AFLAC_SUPPLEMENTAL ? this.type.INDIVIDUAL_SERIES : this.type.GROUP_SERIES;
            let lobids = "";
            products.lineofBusinesses.forEach(lineofBusiness => {
                let isAnyProductSeriesSelected = false;
                //if(lineofBusiness.showDot){
                lineofBusiness[productSeriesType].forEach(productSeries => {
                    if (productSeries.selected) {
                        isAnyProductSeriesSelected = true;
                    }
                });
                //}

                if (isAnyProductSeriesSelected) {
                    lobids += lineofBusiness.ItemId + "|";
                }
            });
            if (lobids != "") {
                lobids = lobids.slice(0, -1);
            }
            return lobids;
        },
        getSelectedCustomPdfDetails(products, type) {
            let productSeriesType = "";
            let cutomPDfLobSelectedBase64 = [];
            if (type == this.type.AFLAC_SUPPLEMENTAL) {
                productSeriesType = this.type.INDIVIDUAL_SERIES;
                cutomPDfLobSelectedBase64 = this.cutomIndvPDfLobSelectedBase64;
            } else {
                productSeriesType = this.type.GROUP_SERIES;
                cutomPDfLobSelectedBase64 = this.cutomPDfLobSelectedBase64;
            }

            let customPdfData = "";
            let formatType = "Base64";
            products.lineofBusinesses.forEach(lineofBusiness => {
                // let lobShortName = productIds + lineofBusiness.LOBShortName ;
                let totalSeriesItems = lineofBusiness[productSeriesType].length;
                let customPdfUpload = lineofBusiness[productSeriesType][totalSeriesItems - 1];
                if (customPdfUpload && customPdfUpload.customBrochurePdfBase64 && customPdfUpload.isCustomPdfUpload) {
                    let pdfName = customPdfUpload.customBrochurePdfName.split(".")[0];
                    customPdfData +=
                        lineofBusiness.LOBShortName +
                        ":" +
                        customPdfUpload.customBrochurePdfBase64 +
                        ":" +
                        pdfName +
                        "|";
                } else if (
                    customPdfUpload &&
                    !customPdfUpload.customBrochurePdfBase64 &&
                    customPdfUpload.isCustomPdfUpload
                ) {
                    customPdfData +=
                        lineofBusiness.LOBShortName +
                        ":" +
                        cutomPDfLobSelectedBase64[lineofBusiness.LOBShortName].base64 +
                        ":" +
                        cutomPDfLobSelectedBase64[lineofBusiness.LOBShortName].name +
                        "|";
                }
                /*lineofBusiness[productSeriesType].forEach(productSeries => {
                    // productIds = productIds + productSeries.ItemId + ":"+ productSeries.ItemName ;
                });*/
            });
            //return false;
            if (customPdfData != "") {
                customPdfData = customPdfData.slice(0, -1);
            }
            return customPdfData;
        },
        getItemSuffixByDate() {
            const today = new Date();
            const mmPadToFour = ("00" + today.getMinutes()).slice(-2);
            const ssPadToFour = ("00" + today.getSeconds()).slice(-2);
            const msPadToFour = ("000" + today.getMilliseconds()).slice(-3);
            const mmssms = mmPadToFour + "" + ssPadToFour + "" + msPadToFour;
            return mmssms;
        },
        getUpdatedItemId() {
            if (this.siteForm.micrositeUrl) {
                return this.siteForm.micrositeUrl.split("/").pop();
            }
        },
        createMicrosite(btnType) {
            let selectedPladPdfDetail = "";
            let selectedAflacPdfDetail = "";
            let selectedNonAflacPdfDetail = "";
            let selectedNonAflacRSPdfDetail = "";
            let productIds = "";
            let lobIds = "";
            let selectedProductList = "";
            let clientLogoImage = "";
            let clientLogoImageExt = "";
            let selectedCustomPdfDetail = "";
            let selectedCustomIndvPdfDetail = "";
            let selectedSpanishGroupPdfDetail = "";
            let selectedSpanishIndvPdfDetail = "";
            let filteredOrgName = this.siteForm.organizationName.replace(/[^a-zA-Z0-9]/g, "");
            /* Adding http to scheduling/option link if url external */
            const scheduling_url_regex = /(http(s?)):\/\//i;
            if (
                this.siteForm.calenderLink &&
                isValidUrl(this.siteForm.calenderLink) &&
                !scheduling_url_regex.test(this.siteForm.calenderLink)
            ) {
                this.siteForm.calenderLink = "https://" + this.siteForm.calenderLink;
            }
            if (
                this.siteForm.schedulingEnrollUrl &&
                isValidUrl(this.siteForm.schedulingEnrollUrl) &&
                !scheduling_url_regex.test(this.siteForm.schedulingEnrollUrl)
            ) {
                this.siteForm.schedulingEnrollUrl = "https://" + this.siteForm.schedulingEnrollUrl;
            }
            if (this.micrositeId) {
                var publishUrl = environment.enrollmentDomain + filteredOrgName + "/" + this.getUpdatedItemId();
                if (this.clientLogoImage.split("?")[0] == this.ClientImagePath && !this.isLogoRemoved) {
                    clientLogoImage = "";
                    clientLogoImageExt = "";
                    this.isLogoUpdate = false;
                } else if (this.clientLogoImage == "" && !this.isLogoRemoved) {
                    clientLogoImage = "";
                    clientLogoImageExt = "";
                    this.isLogoUpdate = false;
                } else if (this.clientLogoImage == "" && this.isLogoRemoved) {
                    clientLogoImage = "";
                    clientLogoImageExt = "";
                    this.isLogoUpdate = true;
                } else {
                    clientLogoImage = this.clientLogoImage;
                    clientLogoImageExt = this.clientLogoImageExt;
                    this.isLogoUpdate = true;
                }
            } else {
                var publishUrl =
                    environment.enrollmentDomain +
                    filteredOrgName +
                    "/" +
                    this.siteForm.accountId +
                    this.getItemSuffixByDate();

                if (this.clientLogoImage == "") {
                    clientLogoImage = "";
                    clientLogoImageExt = "";
                    this.isLogoUpdate = false;
                } else {
                    clientLogoImage = this.clientLogoImage;
                    clientLogoImageExt = this.clientLogoImageExt;
                    this.isLogoUpdate = true;
                }
            }

            this.siteForm.liveDateFrom = this.siteForm.liveSite
                ? this.siteForm.liveDateFrom
                    ? this.siteForm.liveDateFrom
                    : this.siteForm.enrollmentDateFrom
                : this.siteForm.enrollmentDateFrom;
            this.siteForm.liveDateTo = this.siteForm.liveSite
                ? this.siteForm.liveDateTo
                    ? this.siteForm.liveDateTo
                    : this.siteForm.enrollmentDateTo
                : this.siteForm.enrollmentDateTo;
            //if (this.siteForm.offerAflacGroup) {
            if (this.siteForm.groupSelection) {
                productIds = this.getSelectedProductIds(this.displayProducts, this.type.AFLAC_GROUP);
                lobIds = this.getSelectedlobids(this.displayProducts, this.type.AFLAC_GROUP);
                selectedProductList = this.getSelectedProductDetails(this.displayProducts, this.type.AFLAC_GROUP);
                // for Custom pdf upload
                selectedCustomPdfDetail = this.getSelectedCustomPdfDetails(this.displayProducts, this.type.AFLAC_GROUP);
                // Group Custom Spanish pdf upload
                selectedSpanishGroupPdfDetail = this.getSelectedCustomSpanishPdfDetails(
                    this.displayProducts,
                    this.type.AFLAC_GROUP
                );
            }
            let individualProductIds = this.getSelectedProductIds(this.displayProducts, this.type.AFLAC_SUPPLEMENTAL);
            let individualLobIds = this.getSelectedlobids(this.displayProducts, this.type.AFLAC_SUPPLEMENTAL);
            let individualSelectedProductList = this.getSelectedProductDetails(
                this.displayProducts,
                this.type.AFLAC_SUPPLEMENTAL
            );
            // for Custom pdf upload for Individual
            selectedCustomIndvPdfDetail = this.getSelectedCustomPdfDetails(
                this.displayProducts,
                this.type.AFLAC_SUPPLEMENTAL
            );

            // Individudal Custom Spanish pdf upload
            selectedSpanishIndvPdfDetail = this.getSelectedCustomSpanishPdfDetails(
                this.displayProducts,
                this.type.AFLAC_SUPPLEMENTAL
            );

            if (individualProductIds) {
                if (productIds) {
                    productIds = productIds + "|" + individualProductIds;
                } else {
                    productIds = individualProductIds;
                }
            }
            if (individualLobIds) {
                if (lobIds) {
                    lobIds = lobIds + "|" + individualLobIds;
                } else {
                    lobIds = individualLobIds;
                }
            }
            if (individualSelectedProductList) {
                if (selectedProductList) {
                    selectedProductList = selectedProductList + "|" + individualSelectedProductList;
                } else {
                    selectedProductList = individualSelectedProductList;
                }
            }
            this.siteForm.selectedEnrollment = this.enrollmentVideos[0];
            //lobshortName:productSeriesid:productSeriesName:productid1|lobid2ShortName:productSeriesid:productSeriesName:productid1
            /* eslint-disable */
            if (clientLogoImage) {
                let imgStripIndex = clientLogoImage.indexOf(",") + 1;
                clientLogoImage = clientLogoImage.substring(imgStripIndex);
            }
            /**
             * non Aflac lob products selected
             */
            if (this.nonAflacLobSelectedList.length > 0) {
                selectedNonAflacPdfDetail = this.getSelectedNonAflacPdfDetails(
                    this.nonAflacLobSelectedList,
                    this.type.NONAFLAC_PRODUCT
                );
                selectedNonAflacRSPdfDetail = this.getSelectedNonAflacPdfDetails(
                    this.nonAflacLobSelectedList,
                    this.type.NONAFLAC_RS
                );
            }

            /**
             * PLADS lob products selected
             */
            if (
                this.pladLobData &&
                Object.prototype.hasOwnProperty.call(this.pladLobData, "PladsProducts") &&
                this.pladLobData.PladsProducts.length > 0
            ) {
                selectedPladPdfDetail = this.getSelectedPladPdfDetails(
                    this.pladLobData.PladsProducts,
                    this.type.PLAD_PRODUCT
                );
            }
            /**
             * Aflac lob Rate Sheet selected
             */
            selectedAflacPdfDetail = this.getSelectedAflacRSPdfDetails(this.displayProducts, this.type.AFLAC_RS);

            let data = {
                SchedulingPhoneNo: this.siteForm.schedulingPhoneNo ? this.siteForm.schedulingPhoneNo : "",
                SchedulingEnrollUrl: this.siteForm.schedulingEnrollUrl ? this.siteForm.schedulingEnrollUrl : "",
                IndividualSelection: this.siteForm.individualSelection ? "True" : "False",
                GroupSelection: this.siteForm.groupSelection ? "True" : "False",
                ClientImage: clientLogoImage,
                ClientImageExtension: clientLogoImageExt ? "." + clientLogoImageExt : clientLogoImageExt,
                IsLogoUpdate: this.isLogoUpdate,
                ClientImageID: this.isLogoUpdate ? "" : this.clientImageId,
                ItemId: this.micrositeId,
                OrganizationName: this.siteForm.organizationName,
                OrganizationState:
                    this.siteForm.organizationState &&
                    typeof this.siteForm.organizationState === "object" &&
                    this.siteForm.organizationState.State
                        ? this.siteForm.organizationState.State
                        : "",
                //new group state
                GroupState:
                    this.siteForm.groupState &&
                    typeof this.siteForm.groupState === "object" &&
                    this.siteForm.groupState.State
                        ? this.siteForm.groupState.State
                        : "",
                HideAgent: this.siteForm.hideAgent ? "True" : "False",
                HideEnrollDate: this.siteForm.hideEnrollDate ? "True" : "False",

                ContactName: this.siteForm.contactName,
                ContactNumber: this.siteForm.contactNumber,
                ContactEmail: this.siteForm.contactEmail,
                EnrollmentStartDate: this.siteForm.enrollmentDateFrom,
                EnrollmentEndDate: this.siteForm.enrollmentDateTo,
                LiveStartDate: this.siteForm.liveDateFrom,
                LiveEndDate: this.siteForm.liveDateTo,
                AccountId: this.siteForm.accountId,
                SchedulingLink: this.siteForm.calenderLink ? this.siteForm.calenderLink : "",
                EnrollmentId: this.siteForm.selectedEnrollment.itemId,
                //  GroupSelection: this.siteForm.offerAflacGroup,
                IsLiveDateChecked: this.siteForm.liveSite,
                ProductList: productIds,
                lobids: lobIds,
                AvailableIndividualProductList: "",
                SelectedIndividualProductList: "",
                CreatedBY: this.loggedInUserDetail.EmailAddress,
                CreatedDate: "",
                Active: true,
                IsPublish: btnType == "SUBMIT" ? true : false,
                ParentId: "",
                PublishUrl: publishUrl.replace(/\s/g, ""),
                SelectedProductList: selectedProductList,
                IsSuggestiveEnable: !this.siteForm.groupSelection
                    ? this.siteForm.IsSuggestiveEnable
                        ? "True"
                        : "False"
                    : false,
                CustomPdf: selectedCustomPdfDetail,
                NonAflacPdf: selectedNonAflacPdfDetail,
                NonAflacRSPdf: selectedNonAflacRSPdfDetail,
                AflacRSPdf: selectedAflacPdfDetail,
                CustomIndividualPdf: selectedCustomIndvPdfDetail,
                PladsPdf: selectedPladPdfDetail,
                CustomIndividualSpanishPdf: selectedSpanishIndvPdfDetail,
                CustomSpanishPdf: selectedSpanishGroupPdfDetail
            };
            this.siteCreated = true;
            httpClient.post("Microsite/Create", data).then(result => {
                if (btnType == "PREVIEW") {
                    if (this.micrositeId) {
                        let returnURL = "/Create-new-site?micrositeid=" + this.micrositeId;
                        var encodedString = encodeURIComponent(returnURL);
                        document.cookie = "previewReturnURL=" + encodedString;
                    } else {
                        let micrositeId = result.data.Result.split("=")[1];
                        let returnURL = "/Create-New-Site?micrositeid=" + micrositeId;
                        var encodedString = encodeURIComponent(returnURL);
                        document.cookie = "previewReturnURL=" + encodedString;
                    }
                    window.location.href = result.data.Result;
                    return;
                }
                //this.micrositeIdQRcode = "8046328c-0fed-46e5-956d-00b4ef1ba8fe";
                this.micrositeIdQRcode = result.data.Result.split("=")[1];
                this.showPublishModal = true;
                this.siteForm.micrositeUrl = data.PublishUrl;
            });
        },
        validateUser(btnType) {
            this.$v.$touch();
            this.submitted = true;
            if (
                !this.$v.$invalid &&
                !this.isenrollmentDateInValid &&
                !this.accountIdValidationMsg &&
                !this.isLiveDatesInValid
            ) {
                // Code for return to back page start
                // if (btnType == "PREVIEW") {
                //     if (this.micrositeId) {
                //         let returnURL = "/Create-new-site?micrositeid=" + this.micrositeId;
                //         var encodedString = btoa(returnURL);
                //         document.cookie = "previewReturnURL=" + encodedString;
                //     } else {
                //         let returnURL = "/Create-New-Site";
                //         var encodedString = btoa(returnURL);
                //         document.cookie = "previewReturnURL=" + encodedString;
                //     }
                // }
                // Code for return to back page end
                this.createMicrosite(btnType);
            } else {
                this.focusOnValidationError();
            }
        },
        focusOnState() {
            // $(".vs__search").focus(); //Comment by @Anand to fix focus issue in edit mode
        },
        focusOnValidationError() {
            if (
                this.isenrollmentDateInValid ||
                this.isLiveDatesInValid ||
                !this.siteForm.enrollmentDateTo ||
                !this.siteForm.enrollmentDateFrom
            ) {
                setTimeout(function() {
                    let elmntToView = document.getElementById("enrollment-header");
                    elmntToView.scrollIntoView();
                }, 0);
            }
            for (let key in Object.keys(this.$v.siteForm)) {
                const input = Object.keys(this.$v.siteForm)[key];
                if (input.includes("$")) return false;
                if (this.$v.siteForm[input].$error) {
                    if (input == "isProductSelected") {
                        setTimeout(function() {
                            let elmntToView = document.getElementById("productSectionCheckbox");
                            elmntToView.scrollIntoView();
                        }, 0);
                    } else {
                        if (input == "selectedEnrollment") {
                            this.$refs[input][0].focus();
                        } else {
                            this.$refs[input].focus();
                        }
                    }
                    break;
                }
            }
        },
        acceptNumber() {
            this.siteForm.contactNumber = numberFormatting(this.siteForm.contactNumber);
        },
        closeAccordian(val) {
            $("." + val).collapse("hide");
        },
        onPreviewClick(itemId) {
            // if (itemId != this.siteForm.selectedEnrollment.itemId) {
            //     return;
            // }
            let selectedArr = this.enrollmentVideos.filter(video => {
                return video.itemId == itemId;
            });
            if (Array.isArray(selectedArr) && selectedArr.length > 0) {
                this.siteForm.selectedEnrollment = selectedArr[0];
            }
            let date = "";
            if (this.siteForm.enrollmentDateFrom) {
                // if (
                //     Vue.moment(this.siteForm.enrollmentDateFrom).format("MM") ==
                //     Vue.moment(this.siteForm.enrollmentDateTo).format("MM")
                // ) {
                //     date =
                //         Vue.moment(this.siteForm.enrollmentDateFrom).format("MMMM DD") +
                //         "-" +
                //         Vue.moment(this.siteForm.enrollmentDateTo).format("DD,YYYY");
                // } else {
                date =
                    Vue.moment(this.siteForm.enrollmentDateFrom).format("MMMM DD, YYYY") +
                    " - " +
                    Vue.moment(this.siteForm.enrollmentDateTo).format("MMMM DD, YYYY");
                // }
            }

            let formattedPreviewText = this.siteForm.selectedEnrollment.PreviewText.replaceAll(
                "$$$EnrollmentStart$$$",
                date
            )
                .replaceAll(
                    "$$$LoggedInUserName$$$",
                    this.loggedInUserDetail.FirstName + " " + this.loggedInUserDetail.LastName
                )
                .replaceAll("$$$OrganizatioName$$$", this.siteForm.organizationName);
            if (this.siteForm.selectedEnrollment.formattedPreviewText) {
                this.siteForm.selectedEnrollment.formattedPreviewText = formattedPreviewText;
            } else {
                Vue.set(this.siteForm.selectedEnrollment, "formattedPreviewText", formattedPreviewText);
            }

            this.showPreviewModal = true;
            this.hideoverflow();
        },
        showoverflow() {
            let bodyobject = document.getElementsByClassName("bg-aflac");
            bodyobject[0].style.overflow = "auto";
        },
        hideoverflow() {
            let bodyobject = document.getElementsByClassName("bg-aflac");
            bodyobject[0].style.overflow = "hidden";
        },
        copyUrl(str, id) {
            const el = document.createElement("textarea");
            var tootipId = document.getElementById("ttm-" + id);
            el.value = str;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
            tootipId.style.display = "inline-block";

            setTimeout(function() {
                tootipId.style.display = "none";
            }, 2000);
        },
        isMobile() {
            let isMobile = false;
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                isMobile = true;
            }
            return isMobile;
        },
        createCalendar() {
            const self = this;
            this.enrollmentDatePickerStart = flatPickr("#datetimepicker6", {
                allowInput: false,
                altInput: true,
                altFormat: "m/d/Y",
                // minDate: "today",
                showMonths: "1",
                maxDate: new Date().fp_incr(365),
                dateFormat: "m/d/Y",
                defaultDate: [],
                disableMobile: "true",
                onChange: function(selectedDates, dateStr) {
                    self.validateStartEndDate(dateStr, "EnrollmentDate", "start");
                },
                onClose: function(selectedDates, dateStr) {
                    self.validateStartEndDateClose(dateStr, "EnrollmentDate", "start");
                }
            });
            this.enrollmentDatePickerEnd = flatPickr("#datetimepicker7", {
                allowInput: false,
                altInput: true,
                altFormat: "m/d/Y",
                // minDate: "today",
                showMonths: "1",
                maxDate: new Date().fp_incr(365),
                dateFormat: "m/d/Y",
                defaultDate: [],
                disableMobile: "true",
                onChange: function(selectedDates, dateStr) {
                    self.validateStartEndDate(dateStr, "EnrollmentDate", "end");
                },
                onClose: function(selectedDates, dateStr) {
                    self.validateStartEndDateClose(dateStr, "EnrollmentDate", "end");
                }
            });
            this.liveDatePickerStart = flatPickr("#livestartdate", {
                allowInput: false,
                altInput: true,
                altFormat: "m/d/Y",
                // minDate: "today",
                showMonths: "1",
                maxDate: new Date().fp_incr(365),
                dateFormat: "m/d/Y",
                defaultDate: [],
                disableMobile: "true",
                onChange: function(selectedDates, dateStr) {
                    self.validateStartEndDate(dateStr, "LiveDate", "start");
                },
                onClose: function(selectedDates, dateStr) {
                    self.validateStartEndDateClose(dateStr, "LiveDate", "start");
                }
            });
            this.liveDatePickerEnd = flatPickr("#liveenddate", {
                allowInput: false,
                altInput: true,
                altFormat: "m/d/Y",
                // minDate: "today",
                showMonths: "1",
                maxDate: new Date().fp_incr(365),
                dateFormat: "m/d/Y",
                defaultDate: [],
                disableMobile: "true",
                onChange: function(selectedDates, dateStr) {
                    self.validateStartEndDate(dateStr, "LiveDate", "end");
                },
                onClose: function(selectedDates, dateStr) {
                    self.validateStartEndDateClose(dateStr, "LiveDate", "end");
                }
            });
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        onProductResetModalClick(typeButton, type) {
            if (typeButton == "RESET") {
                this.closeAllAcc();
                this.selectedProductSeriesDetail = {
                    lineOfBusiness: {},
                    ProductSeries: {}
                };
                /*this.initDisplayProducts = this.displayProducts = {
                    state: "",
                    lineofBusinesses: []
                };*/
                if (type == this.type.AFLAC_SUPPLEMENTAL) {
                    if (this.siteForm.organizationState) {
                        this.getProduct(this.type.AFLAC_SUPPLEMENTAL).then(p1 => {
                            for (let i = 0; i < this.displayProducts.lineofBusinesses.length; i++) {
                                let lobId = this.displayProducts.lineofBusinesses[i].ItemId;
                                if (
                                    Object.prototype.hasOwnProperty.call(
                                        this.displayProducts.lineofBusinesses[i],
                                        this.type.GROUP_SERIES
                                    ) &&
                                    this.displayProducts.lineofBusinesses[i].productSeriesG.length > 0
                                ) {
                                    let PCount = 0;
                                    let PScount = 0;
                                    for (
                                        let j = 0;
                                        j < this.displayProducts.lineofBusinesses[i].productSeriesG.length;
                                        j++
                                    ) {
                                        PScount++;
                                        if (
                                            this.displayProducts.lineofBusinesses[i].productSeriesG[j].selected == true
                                        ) {
                                            this.displayProducts.lineofBusinesses[i].showDot = true;
                                            PCount++;
                                        }
                                    }
                                    if (
                                        this.displayProducts.lineofBusinesses[i].productSeriesG.length == PScount &&
                                        PCount > 0
                                    ) {
                                        if (this.displayProductsOld.lineofBusinesses[i].showAflacRSPdfSection) {
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].showAflacRSPdfSection = this.displayProductsOld.lineofBusinesses[
                                                i
                                            ].showAflacRSPdfSection;
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].disclaimer = this.displayProductsOld.lineofBusinesses[i].disclaimer;
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].selected = this.displayProductsOld.lineofBusinesses[i].selected;
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].aflacRSPdfName = this.displayProductsOld.lineofBusinesses[
                                                i
                                            ].aflacRSPdfName;
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].aflacRSPdfBase64 = this.displayProductsOld.lineofBusinesses[
                                                i
                                            ].aflacRSPdfBase64;
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].aflacRSPdfPath = this.displayProductsOld.lineofBusinesses[
                                                i
                                            ].aflacRSPdfPath;
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].isCustomPdfPreviewErr = this.displayProductsOld.lineofBusinesses[
                                                i
                                            ].isCustomPdfPreviewErr;
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].isAflacRSPdfUpload = this.displayProductsOld.lineofBusinesses[
                                                i
                                            ].isAflacRSPdfUpload;
                                        }
                                        this.disableOtherLobProducts(
                                            this.type.AFLAC_GROUP,
                                            this.displayProducts,
                                            this.displayProducts.lineofBusinesses[i].ItemId
                                        );
                                    } else {
                                        this.checkAndEnableProductSectionByLobId(lobId, this.type.AFLAC_SUPPLEMENTAL);
                                    }
                                }
                            }
                        });
                    } else {
                        if (!this.siteForm.groupState) {
                            this.initDisplayProducts = this.displayProducts = {
                                state: "",
                                lineofBusinesses: []
                            };
                        }
                        let forNotDeletion = this.initGroupProducts.lineofBusinesses.reduce(function(a, e, i) {
                            a.push(e.ItemId);
                            return a;
                        }, []);
                        let arrayTemp = JSON.parse(JSON.stringify(this.displayProducts.lineofBusinesses));
                        this.displayProducts.lineofBusinesses = arrayTemp.filter((item, index) =>
                            forNotDeletion.includes(item.ItemId)
                        );
                        for (let i = 0; i < this.displayProducts.lineofBusinesses.length; i++) {
                            // this.checkAndEnableProductSectionByLobId(this.displayProducts.lineofBusinesses[i].ItemId, this.type.AFLAC_GROUP);
                            if (
                                Object.prototype.hasOwnProperty.call(
                                    this.displayProducts.lineofBusinesses[i],
                                    this.type.GROUP_SERIES
                                ) &&
                                this.displayProducts.lineofBusinesses[i].productSeriesG.length > 0
                            ) {
                                this.displayProducts.lineofBusinesses[i][this.type.INDIVIDUAL_SERIES] = [];
                                let GPCount = 0;
                                for (
                                    let j = 0;
                                    j < this.displayProducts.lineofBusinesses[i].productSeriesG.length;
                                    j++
                                ) {
                                    this.displayProducts.lineofBusinesses[i].productSeriesG[j].disabled = false;
                                    if (this.displayProducts.lineofBusinesses[i].productSeriesG[j].selected == true) {
                                        GPCount++;
                                    }
                                }
                                if (GPCount > 0) {
                                    this.displayProducts.lineofBusinesses[i].showDot = true;
                                } else {
                                    this.displayProducts.lineofBusinesses[i].showDot = false;
                                }
                            }
                        }
                    }
                } else if (type == this.type.AFLAC_GROUP) {
                    if (this.siteForm.groupState) {
                        this.getProduct(this.type.AFLAC_GROUP).then(p2 => {
                            for (let i = 0; i < this.displayProducts.lineofBusinesses.length; i++) {
                                let lobId = this.displayProducts.lineofBusinesses[i].ItemId;
                                if (this.displayProducts.lineofBusinesses[i].productSeries.length > 0) {
                                    let GPCount = 0;
                                    let GPScount = 0;
                                    for (
                                        let j = 0;
                                        j < this.displayProducts.lineofBusinesses[i].productSeries.length;
                                        j++
                                    ) {
                                        GPScount++;
                                        if (
                                            this.displayProducts.lineofBusinesses[i].productSeries[j].selected == true
                                        ) {
                                            this.displayProducts.lineofBusinesses[i].showDot = true;
                                            GPCount++;
                                        }
                                    }
                                    if (
                                        this.displayProducts.lineofBusinesses[i].productSeries.length == GPScount &&
                                        GPCount > 0
                                    ) {
                                        if (this.displayProductsOld.lineofBusinesses[i].showAflacRSPdfSection) {
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].showAflacRSPdfSection = this.displayProductsOld.lineofBusinesses[
                                                i
                                            ].showAflacRSPdfSection;
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].disclaimer = this.displayProductsOld.lineofBusinesses[i].disclaimer;
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].selected = this.displayProductsOld.lineofBusinesses[i].selected;
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].aflacRSPdfName = this.displayProductsOld.lineofBusinesses[
                                                i
                                            ].aflacRSPdfName;
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].aflacRSPdfBase64 = this.displayProductsOld.lineofBusinesses[
                                                i
                                            ].aflacRSPdfBase64;
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].aflacRSPdfPath = this.displayProductsOld.lineofBusinesses[
                                                i
                                            ].aflacRSPdfPath;
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].isCustomPdfPreviewErr = this.displayProductsOld.lineofBusinesses[
                                                i
                                            ].isCustomPdfPreviewErr;
                                            this.displayProducts.lineofBusinesses[
                                                i
                                            ].isAflacRSPdfUpload = this.displayProductsOld.lineofBusinesses[
                                                i
                                            ].isAflacRSPdfUpload;
                                        }
                                        this.disableOtherLobProducts(
                                            this.type.AFLAC_SUPPLEMENTAL,
                                            this.displayProducts,
                                            this.displayProducts.lineofBusinesses[i].ItemId
                                        );
                                    } else {
                                        this.checkAndEnableProductSectionByLobId(lobId, this.type.AFLAC_GROUP);
                                    }
                                }
                            }
                        });
                    } else {
                        if (!this.siteForm.organizationState) {
                            this.initDisplayProducts = this.displayProducts = {
                                state: "",
                                lineofBusinesses: []
                            };
                        }
                        let forNotDeletion = this.initIndividualProducts.lineofBusinesses.reduce(function(a, e, i) {
                            a.push(e.ItemId);
                            return a;
                        }, []);
                        let arrayTemp = JSON.parse(JSON.stringify(this.displayProducts.lineofBusinesses));
                        this.displayProducts.lineofBusinesses = arrayTemp.filter((item, index) =>
                            forNotDeletion.includes(item.ItemId)
                        );
                        for (let i = 0; i < this.displayProducts.lineofBusinesses.length; i++) {
                            // this.checkAndEnableProductSectionByLobId(this.displayProducts.lineofBusinesses[i].ItemId, this.type.AFLAC_GROUP);
                            if (
                                Object.prototype.hasOwnProperty.call(
                                    this.displayProducts.lineofBusinesses[i],
                                    this.type.INDIVIDUAL_SERIES
                                ) &&
                                this.displayProducts.lineofBusinesses[i].productSeries.length > 0
                            ) {
                                this.displayProducts.lineofBusinesses[i][this.type.GROUP_SERIES] = [];
                                let PCount = 0;
                                for (
                                    let j = 0;
                                    j < this.displayProducts.lineofBusinesses[i].productSeries.length;
                                    j++
                                ) {
                                    this.displayProducts.lineofBusinesses[i].productSeries[j].disabled = false;
                                    if (this.displayProducts.lineofBusinesses[i].productSeries[j].selected == true) {
                                        PCount++;
                                    }
                                }
                                if (PCount > 0) {
                                    this.displayProducts.lineofBusinesses[i].showDot = true;
                                } else {
                                    this.displayProducts.lineofBusinesses[i].showDot = false;
                                }
                            }
                        }
                    }
                }
            } else if (typeButton == "CANCEL") {
                if (type == this.type.AFLAC_SUPPLEMENTAL) {
                    this.siteForm.individualSelection = true;
                    this.siteForm.organizationState = this.productResetPopupData.oldOrganizationState;
                    this.productResetPopupData.resetOrganizationState = true;
                } else if (type == this.type.AFLAC_GROUP) {
                    this.siteForm.groupSelection = true;
                    this.siteForm.groupState = this.productResetPopupData.oldGroupState;
                    this.productResetPopupData.resetGroupState = true;
                }
            }
            setTimeout(() => {
                this.showProductResetPopup = false;
                this.checkDirty = "dirty";
            }, 0);
        },
        continueEditing() {
            this.checkDirty = "dirty";
        },
        undoChanges() {
            this.checkDirty = "navigate";
            var link = document.getElementById("dirtyFlag").getAttribute("data-buttonVal");
            window.location.href = link;
        },
        onDirtyChanged(val) {
            this.checkDirty = val;
        },
        isNumber: function(e) {
            var code = "charCode" in e ? e.charCode : e.keyCode;
            if (
                !(code > 47 && code < 58) && // numeric (0-9)
                !(code > 64 && code < 91) && // upper alpha (A-Z)
                !(code > 96 && code < 123)
            ) {
                // lower alpha (a-z)
                e.preventDefault();
            }
        },
        validateAccountId() {
            this.accountIdValidationMsg = "";
            if (!this.siteForm.accountId) {
                return;
            }
            let data = {
                AccountId: this.siteForm.accountId
            };
            if (this.micrositeId && this.siteForm.accountId == this.initValue.accountId) {
                return;
            } else {
                httpClient.post("Microsite/ValidateAccountID", data).then(result => {
                    if (environment.name == "local") {
                        return;
                    }
                    if (result) {
                        if (result.data.Result.IsAccountIdAvailable) {
                            this.accountIdValidationMsg = result.data.Result.Message;
                        }
                    }
                });
            }
        },
        previewConfirm() {
            this.previewConfirmationPopup = true;
        },
        cancelPreview() {
            this.previewConfirmationPopup = false;
        },
        validateStartEndDateClose(dateStr, type, startOrEnd) {
            if (type == "EnrollmentDate" && !dateStr) {
                if (!this.siteForm.enrollmentDateFrom && !this.siteForm.enrollmentDateTo) {
                    this.isenrollmentDateInValid = false;
                } else {
                    this.isenrollmentDateInValid = true;
                }
            }

            if (type == "LiveDate" && !dateStr) {
                if (!this.siteForm.liveDateFrom && !this.siteForm.liveDateTo) {
                    this.isLiveDatesInValid = false;
                } else {
                    this.isLiveDatesInValid = true;
                }
            }
        },
        validateStartEndDate(dateStr, type, startOrEnd) {
            if (type == "EnrollmentDate" && !dateStr) {
                this.isenrollmentDateInValid = true;
            } else if (type == "EnrollmentDate" && startOrEnd == "start" && dateStr && this.siteForm.enrollmentDateTo) {
                document.getElementById("datetimepicker6").nextElementSibling.classList.remove("invalid-danger");
                document.getElementById("datetimepicker7").nextElementSibling.classList.remove("invalid-danger");
                if (Vue.moment(dateStr).isAfter(this.siteForm.enrollmentDateTo)) {
                    document.getElementById("datetimepicker6").nextElementSibling.classList.add("invalid-danger");
                    this.getValidationClass("enrollmentDateFrom");
                    this.isenrollmentDateInValid = true;
                } else {
                    this.isenrollmentDateInValid = false;
                }
            } else if (type == "EnrollmentDate" && startOrEnd == "end" && dateStr && this.siteForm.enrollmentDateFrom) {
                document.getElementById("datetimepicker6").nextElementSibling.classList.remove("invalid-danger");
                document.getElementById("datetimepicker7").nextElementSibling.classList.remove("invalid-danger");
                if (Vue.moment(dateStr).isBefore(this.siteForm.enrollmentDateFrom)) {
                    document.getElementById("datetimepicker7").nextElementSibling.classList.add("invalid-danger");
                    this.getValidationClass("enrollmentDateTo");
                    this.isenrollmentDateInValid = true;
                } else {
                    this.isenrollmentDateInValid = false;
                }
            }
            if (type == "LiveDate" && !dateStr) {
                this.isLiveDatesInValid = true;
            } else if (type == "LiveDate" && startOrEnd == "start" && dateStr) {
                document.getElementById("liveenddate").nextElementSibling.classList.remove("invalid-danger");
                document.getElementById("livestartdate").nextElementSibling.classList.remove("invalid-danger");
                if (Vue.moment(dateStr).isAfter(this.siteForm.liveDateTo) || !this.siteForm.liveDateTo) {
                    document.getElementById("livestartdate").nextElementSibling.classList.add("invalid-danger");
                    this.getValidationClass("liveDateFrom");
                    this.isLiveDatesInValid = true;
                } else {
                    this.isLiveDatesInValid = false;
                }
            } else if (type == "LiveDate" && startOrEnd == "end" && dateStr) {
                document.getElementById("liveenddate").nextElementSibling.classList.remove("invalid-danger");
                document.getElementById("livestartdate").nextElementSibling.classList.remove("invalid-danger");
                if (Vue.moment(dateStr).isBefore(this.siteForm.liveDateFrom) || !this.siteForm.liveDateFrom) {
                    document.getElementById("liveenddate").nextElementSibling.classList.add("invalid-danger");
                    this.getValidationClass("liveDateTo");
                    this.isLiveDatesInValid = true;
                } else {
                    this.isLiveDatesInValid = false;
                }
            }
        },
        resetDates() {
            if (this.siteForm.liveSite) {
                document.getElementById("liveenddate").nextElementSibling.classList.remove("invalid-danger");
                document.getElementById("livestartdate").nextElementSibling.classList.remove("invalid-danger");
                this.liveDatePickerStart.setDate("", true);
                this.liveDatePickerEnd.setDate("", true);
                this.isLiveDatesInValid = false;
            } /*else {
                this.isLiveDatesInValid = true;
            }*/
        },
        /*byPassSiteLive() {
            if(this.siteForm.liveSite) {
                this.isLiveDatesInValid = false;
            } else {
                this.validateStartEndDate(this.siteForm.liveDateFrom, "LiveDate", "start");
                //this.validateStartEndDate(this.siteForm.liveDateTo, "LiveDate", "end");
            }
        }*/

        clearSchValue(el) {
            this.$refs[el].value = "";
            if (el == "calenderLink") {
                this.siteForm.calenderLink = "";
            } else if (el == "schedulingPhoneNo") {
                this.siteForm.schedulingPhoneNo = "";
            } else {
                this.siteForm.schedulingEnrollUrl = "";
            }
            this.tracklinkfield(el);
            if (this.isPilotMode) {
                this.acceptschedulingPhoneNo(el);
                this.trackEnrollURL(el);
            }
        },
        hideCrossIcon() {
            document.getElementById("data-clear-input1").style.display = "none";
            document.getElementById("data-clear-input2").style.display = "none";
            document.getElementById("data-clear-input3").style.display = "none";
        },

        tracklinkfield(el) {
            const selectedValue =
                (this.micrositeId || this.loggedInUserDetail.SchedulingLink) && this.siteForm.calenderLink
                    ? this.siteForm.calenderLink
                    : this.$refs[el].value;
            document.getElementById("data-clear-input1").style.display = selectedValue ? "block" : "none";
            if (this.isPilotMode) {
                document.getElementById("schedulingPhoneNo").style.display = selectedValue ? true : false;
                document.getElementById("schedulingEnrollUrl").style.display = selectedValue ? true : false;
                if (selectedValue) {
                    document.getElementById("lbllinkdisabled2").classList.add("lbldisabled");
                    document.getElementById("lbllinkdisabled3").classList.add("lbldisabled");
                    document.getElementById("schedulingPhoneNo").setAttribute("tabindex", "-1");
                    document.getElementById("schedulingEnrollUrl").setAttribute("tabindex", "-1");
                } else {
                    document.getElementById("lbllinkdisabled2").classList.remove("lbldisabled");
                    document.getElementById("lbllinkdisabled3").classList.remove("lbldisabled");
                    document.getElementById("schedulingPhoneNo").removeAttribute("tabindex");
                    document.getElementById("schedulingEnrollUrl").removeAttribute("tabindex");
                }
            }
        },
        acceptschedulingPhoneNo(el) {
            const selectedValue =
                this.micrositeId && this.siteForm.schedulingPhoneNo
                    ? this.siteForm.schedulingPhoneNo
                    : this.$refs[el].value;
            document.getElementById("data-clear-input2").style.display = selectedValue ? "block" : "none";
            this.siteForm.schedulingPhoneNo = numberFormatting(this.siteForm.schedulingPhoneNo);
            document.getElementById("calenderLink").style.display = selectedValue ? true : false;
            document.getElementById("schedulingEnrollUrl").style.display = selectedValue ? true : false;
            if (selectedValue) {
                document.getElementById("lbllinkdisabled1").classList.add("lbldisabled");
                document.getElementById("lbllinkdisabled3").classList.add("lbldisabled");
                document.getElementById("calenderLink").setAttribute("tabindex", "-1");
                document.getElementById("schedulingEnrollUrl").setAttribute("tabindex", "-1");
            } else {
                document.getElementById("lbllinkdisabled1").classList.remove("lbldisabled");
                document.getElementById("lbllinkdisabled3").classList.remove("lbldisabled");
                document.getElementById("calenderLink").removeAttribute("tabindex");
                document.getElementById("schedulingEnrollUrl").removeAttribute("tabindex");
            }
        },
        trackEnrollURL(el) {
            const selectedValue =
                this.micrositeId && this.siteForm.schedulingEnrollUrl
                    ? this.siteForm.schedulingEnrollUrl
                    : this.$refs[el].value;
            document.getElementById("data-clear-input3").style.display = selectedValue ? "block" : "none";
            document.getElementById("calenderLink").style.display = selectedValue ? true : false;
            document.getElementById("schedulingPhoneNo").style.display = selectedValue ? true : false;
            if (selectedValue) {
                document.getElementById("lbllinkdisabled1").classList.add("lbldisabled");
                document.getElementById("lbllinkdisabled2").classList.add("lbldisabled");
                document.getElementById("calenderLink").setAttribute("tabindex", "-1");
                document.getElementById("schedulingPhoneNo").setAttribute("tabindex", "-1");
            } else {
                document.getElementById("lbllinkdisabled1").classList.remove("lbldisabled");
                document.getElementById("lbllinkdisabled2").classList.remove("lbldisabled");
                document.getElementById("calenderLink").removeAttribute("tabindex");
                document.getElementById("schedulingPhoneNo").removeAttribute("tabindex");
            }
        }
    },
    mounted: async function() {
        // invalid phone numbers from hidden property
        let invalidPhoneNumbersId = document.getElementById("InvalidPhoneNumbersId");
        if (invalidPhoneNumbersId != null) {
            invalidPhoneNumbersList = invalidPhoneNumbersId.value.split(",").map(String);
        }

        /* handle the cancel event on custom browser file input dailog */
        retryPdfUPloadDom = document.getElementById("retryPdfUPload");
        retryPdfUPloadDom.onclick = initializeCustomPdf;

        retryPdfUPloadDomSPN = document.getElementById("retrySpnBochurePdfUpload");
        retryPdfUPloadDomSPN.onclick = initializeCustomPdfSPN;

        retryPdfUPloadDomPLAD = document.getElementById("retryPladLobPdfUPload");
        retryPdfUPloadDomPLAD.onclick = initializeCustomPdfPLAD;

        this.micrositeId = getQueryStringValue("micrositeid");
        // check pilot mode by scheduling Link
        let schedulingPhoneNo = document.getElementById("schedulingPhoneNo");
        let schedulingEnrollUrl = document.getElementById("schedulingEnrollUrl");
        if (schedulingPhoneNo || schedulingEnrollUrl) {
            this.isPilotMode = true;
            this.hideCrossIcon();
        }

        let pladSelection = document.getElementById("pladSelection");
        let pladPdfSizeId = document.getElementById("pladPdfSizeId");
        let pladPdfTitleId = document.getElementById("pladPdfTitleId");
        if (pladSelection) {
            if (pladPdfSizeId || pladPdfTitleId) {
                this.pladPdfSize = pladPdfSizeId.value;
                this.pladPdfTitle = pladPdfTitleId.value;
            }
        }

        let customPdfSizeId = document.getElementById("customPdfSizeId");
        let customPdfTitleId = document.getElementById("customPdfTitleId");
        let pdfUploadSection = document.getElementById("custom-select-group");

        let customIndividualPdfSizeId = document.getElementById("customIndividualPdfSizeId");
        let customIndividualPdfTitleId = document.getElementById("customIndividualPdfTitleId");
        this.customIndvPdfSize = customIndividualPdfSizeId.value;
        this.customIndvPdfTitle = customIndividualPdfTitleId.value;

        let aflacRSPdfSizeId = document.getElementById("aflacRSPdfSizeId");
        let aflacRSPdfTitleId = document.getElementById("aflacRSPdfTitleId");
        this.aflacRSPdfSize = aflacRSPdfSizeId.value;
        this.aflacRSPdfTitle = aflacRSPdfTitleId.value;

        let nonAflacSection = document.getElementById("nonAflacSection");
        let nonAflacPdfSizeId = document.getElementById("nonAflacPdfSizeId");
        let nonAflacProductPdfTitleId = document.getElementById("nonAflacProductPdfTitleId");
        let nonAflacRSPdfTitleId = document.getElementById("nonAflacRSPdfTitleId");

        let spnBochurePdfSizeId = document.getElementById("spnBochurePdfSizeId");
        let spnBochurePdfTitleId = document.getElementById("spnBochurePdfTitleId");
        this.cutomSpnPdfPdfSize = spnBochurePdfSizeId.value;
        this.cutomSpnPdfPdfTitle = spnBochurePdfTitleId.value;

        if (nonAflacSection) {
            this.nonAflacPdfSize = nonAflacPdfSizeId.value;
            this.nonAflacProductPdfTitle = nonAflacProductPdfTitleId.value;
            this.nonAflacRSPdfTitle = nonAflacRSPdfTitleId.value;
        }

        if (pdfUploadSection != null) {
            this.customPdfSize = customPdfSizeId.value;
            this.customPdfTitle = customPdfTitleId.value;
        }

        let logoimageExtension = document.getElementById("LogoimageExtensionId");
        let logosize = document.getElementById("LogoimageSizeId");
        let ClientLogoSection = document.getElementById("ClientLogoSection");

        if (ClientLogoSection != null) {
            this.clientLogoExtension = logoimageExtension.value;
            this.clientLogoSize = logosize.value;
        }

        this.loggedInUserDetail = await getUser();
        this.loadData();
        this.createCalendar();
        if (this.loggedInUserDetail.SchedulingLink) {
            if (!this.micrositeId) {
                this.siteForm.calenderLink = this.loggedInUserDetail.SchedulingLink;
                this.tracklinkfield("calenderLink");
            }
        }
        this.initValue = { ...this.siteForm };
        this.checkDirty = "pristine";
    }
};

if (document.getElementById(elementId)) {
    vm = new Vue(vueElement);
}

$(document).on("click", "#filePdf", function() {
    $(".pdf-uploadModal").css("display", "none");

    document.querySelector("#filePdf").addEventListener("cancel", evt => {
        vm.onProductModalCancel("pdf");
        //vm.selectedProductSeriesDetail.productSeries.disclaimer=false;
    });
});
