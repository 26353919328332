import Vue from "vue";
import $ from "jquery";
import vSelect from "vue-select";
Vue.component("v-select", vSelect);

const accountsElementId = "empPreview";

export const vueElement = {
    el: `#${accountsElementId}`,
    data() {
        return {
            languageValues: "",
            options: [],
            currentItem: "English",
            theModel: "",
            videoRef: null,
            productType: "",
            videoUrl: "",
            videoId: null,
            accountId: null,
            videoFlag: false,
            Dialog_flag: true,
            nonAflacDialogFlag: true,
            pladDialogFlag: true,
            modal_id: "",
            typeModel: { aflac: "AFLAC", nonAflac: "NONAFLAC", plad: "PLAD" }
        };
    },
    watch: {
        currentItem: {
            handler() {
                if (this.currentItem && typeof this.currentItem == "object") {
                    this.currentItem = this.currentItem;
                    this.currentLang = this.currentItem.label;
                    this.selectLang(this.currentItem);
                }
            },
            deep: true
        }
    },

    methods: {
        selectLang(newVal) {
            let urlArr = window.location.toString().split("/");
            //let urlArr = "https://google.com/Microsite?id=781d60cd-00b0-42ae-9fb9-c31d3138b03f".split("/")
            if (Array.isArray(urlArr)) {
                let language = newVal.id;
                let remainingURL = "";
                for (var i = 4; i < urlArr.length; i++) {
                    remainingURL += urlArr[i] + "/";
                }
                remainingURL = remainingURL.substring(0, remainingURL.length - 1);
                let url =
                    urlArr.indexOf("en") > 0 || urlArr.indexOf("es-es") > 0
                        ? urlArr[0] + "//" + urlArr[2] + "/" + language + "/" + remainingURL
                        : urlArr[0] + "//" + urlArr[2] + "/" + language + "/" + urlArr[3] + "/" + remainingURL;

                const urls = url.replace(/\/+$/, "");

                location.href = urls;
                //alert(urls)
                //location.href = "http://localhost:8080/employeePreview.html";
                //window.localStorage.setItem("selectedValue", newVal.label);
            }
        },
        showDetails(id) {
            //changes for tri-agency notice
            if (id == "hospital" && this.modal_id != "") {
                $(this.modal_id).modal("show");
                if (document.getElementById("triagencyNotice"))
                    document.getElementById("triagencyNotice").style.display = "block";
            }
            var els = document.getElementsByClassName("hideContainer");
            var i;
            for (i = 0; i < els.length; i += 1) {
                els[i].style.display = "none";
            }
            document.getElementById(id).style.display = "block";
        },
        showModal(modal_id, type) {
            if (this.typeModel.aflac == type) {
                if (this.Dialog_flag == true) {
                    this.modal_id = modal_id;
                    $(modal_id).modal("show");
                    this.Dialog_flag = false;
                }
            } else if (this.typeModel.plad == type) {
                if (this.pladDialogFlag == true) {
                    $(modal_id).modal("show");
                    this.pladDialogFlag = false;
                }
            } else {
                if (this.nonAflacDialogFlag == true) {
                    $(modal_id).modal("show");
                    this.nonAflacDialogFlag = false;
                }
            }
        },

        hideDetails(id) {
            document.getElementById(id).style.display = "none";
        },

        onVideoClicked(event, videoId, AccountId) {
            document.getElementById("popup").classList.add("in");
            let videoURL = `https://players.brightcove.net/${AccountId}/default_default/index.html?videoId=${videoId}`;
            this.videoFlag = false;
            this.videoUrl = videoURL;
            this.videoFlag = true;
        },
        onVideoClosed() {
            if (document.getElementById("popup")) {
                document.getElementById("popup").classList.remove("in");
                this.videoFlag = false;
            }
        },
        showHidePreview_Message() {
            var hidden = document.getElementById("IsPublished");
            if (hidden == null) {
                return;
            }
            if (hidden.value.toLowerCase() == "false") {
                document.getElementById("previewMessage").style.display = "block";
            } else {
                document.getElementById("previewMessage").style.display = "none";
            }
        },
        dynamicLoad(accountId) {
            var getVar = `https://players.brightcove.net/${accountId}/default_default/index.min`;
            var addScript = document.createElement("script");
            addScript.src = getVar + ".js";
            (document.getElementsByTagName("body")[0] || document.documentElement).appendChild(addScript);
        }
    },
    mounted() {
        let urlArr = window.location.toString().split("/");
        if (urlArr.indexOf("es-es") > 1) {
            var element = document.getElementById("empPreview");
            element.classList.add("contentSpanish");
        }
        if (urlArr.indexOf("en") > -1) {
            document.getElementById("empPreview").classList.remove("contentSpanish");
        }
        //let urlArr="https://dev-admin.aflacenrollment.com/es-es/Microsite?id=4e2acb9c-ab01-4b21-88f1-62adcc0ca81c".split("/")
        let languageCode = urlArr.indexOf("en") > 0 || urlArr.indexOf("es-es") > 0 ? urlArr[3] : null;
        this.videoRef = this.$refs["videoRef"];
        //this.onVideoClosed();
        this.showHidePreview_Message();
        this.dynamicLoad("1752604059001");
        let languageValuesId = document.getElementById("LanguageValues");
        this.languageValues = languageValuesId.value;
        if (this.languageValues) {
            let languageValuesArr = this.languageValues.split(",");
            if (Array.isArray(languageValuesArr)) {
                languageValuesArr.forEach(element => {
                    let optionArr = element.split("#");
                    this.options.push({ id: optionArr[0], label: optionArr[1] });
                    if (languageCode && languageCode == optionArr[0]) {
                        this.currentItem = optionArr[1];
                    }
                });
            }
        }

        let returnURL = "/Account";
        var encodedString = encodeURIComponent(returnURL);
        document.cookie = "previewReturnURL=" + encodedString;
    }
};
if (document.getElementById(accountsElementId)) {
    new Vue(vueElement);
}
